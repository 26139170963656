import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LoginState from './LoginState';

const ModalCard = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 30px;
  background: linear-gradient(to right, tomato, dodgerblue);
  z-index: 999;
  text-align: center;
`;

const ModalTitle = styled.span`
  color: white;
  font-size: 14px;
  height: 30px;
`;

export default class GlobalMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: '',
      messageID: '',
      lastClosed: localStorage.getItem('LastMessage'),
    };
    this.getLatestMessage();
  }

  componentDidMount() {
    setInterval(() => {
      this.getLatestMessage();
    }, 60000);
  }

  closeMessage() {
    // update loginstate so we know we've already seen this message
    localStorage.setItem('LastMessage', this.state.messageID);
    this.setState({ show: false, lastClosed: this.state.messageID });
  }

  async getLatestMessage() {
    // skip if logged out
    if (!LoginState.loggedIn || !LoginState.userName) return;

    const response = axios.get('/getLatestMessage', {
      params: { pUserName: LoginState.userName },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    this.setState({
      message: resultData ? resultData.message : '',
      messageID: resultData ? resultData.messageID : '',
      show: resultData && this.state.lastClosed !== resultData.messageID,
    });
  }

  render() {
    return this.state.show ? (
      <div style={{ height: '30px' }}>
        <ModalCard>
          <ModalTitle><span style={{fontWeight:'bold', position:'relative', top:'2px'}}>{this.state.message}</span></ModalTitle>
        </ModalCard>
      </div>
    ) : (
      <></>
    );
  }
}
