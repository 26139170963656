import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Modal from '../components/Modal';
import LoginState from '../components/LoginState';
import {
  STATUS_COLORS,
  PIPELINE_STATUSES,
  PIPELINE_MAIN_TABLE_HEADERS,
  PIPELINE_ADVANCED_TABLE_HEADERS,
} from '../helpers/constants';
import axios from 'axios';
import Pagination from '../components/Pagination';
import { getFormattedName } from '../helpers/utils';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

const defaultCheckedStatuses = [
  'Registered',
  'In Submission',
  'Submitted',
  'In Underwriting',
  'Approved',
  'Clear to Close',
  'Doc Out',
  'Doc In',
  'In Review',
  'Reviewed',
  'Clear to Fund',
  'Funded',
  'On Hold',
];
const allStatuses = [
  'AUS Completed',
  'AUS Requested',
  'Approved',
  'Cancelled',
  'Clear to Close',
  'Clear to Fund',
  'Closed',
  'Completed',
  'Declined',
  'Doc In',
  'Doc Out',
  'Funded',
  'In Post-Funding',
  'In Reconciling',
  'In Review',
  'In Shipping',
  'In Submission',
  'In Underwriting',
  'On Hold',
  'Purchased',
  'Registered',
  'Reviewed',
  'Shipped',
  'Submitted',
];
class Pipeline extends Component {
  constructor(props) {
    super(props);

    var checkedList;
    switch (this.props.match.params.sub) {
      case 'ctf':
        checkedList = ['Clear to Fund'];
        break;
      case 'ctc':
        checkedList = ['Clear to Close'];
        break;
      case 'ptf':
        checkedList = ['Doc In', 'Doc Out', 'In Review', 'Reviewed'];
        break;
      case 'ptc':
        checkedList = [
          'Registered',
          'In Submission',
          'Submitted',
          'In Underwriting',
          'Approved',
        ];
        break;
      case 'all':
      default:
        checkedList = defaultCheckedStatuses;
        break;
    }

    this.state = {
      showStatusModal: false,
      checkedStatuses: checkedList,
      advancedView: true,
      queryTerm: '',
      allFiles: null,
      paginatedResults: [],
      pageSize: 50,
      pageSelected: 0,
    };
  }

  componentDidMount() {
    if (!this.state.files) {
      this.getPipelineFiles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      allFiles,
      queryTerm,
      checkedStatuses,
      sortBy,
      order,
      paginatedResults,
      pageSize,
    } = this.state;
    // filter by query term
    if (
      queryTerm !== prevState.queryTerm ||
      checkedStatuses !== prevState.checkedStatuses
    ) {
      let newResults = allFiles;
      newResults = this._filterByQueryTerm(allFiles);
      newResults = this._filterByStatus(newResults);
      // sort
      newResults.sort(this.compareBy(sortBy, order));
      // paginate
      newResults = this._paginateArray(newResults);
      // save results
      this.setState({
        pageSelected: 0,
        paginatedResults: newResults,
      });
    }
    // sort
    if (sortBy !== prevState.sortBy || order !== prevState.order) {
      let newResults = paginatedResults.flat();
      newResults.sort(this.compareBy(sortBy, order));
    }
    // paginate
    if (pageSize !== prevState.pageSize) {
      let newResults = paginatedResults.flat();
      newResults = this._paginateArray(newResults);
      // save results
      this.setState({
        pageSelected: 0,
        paginatedResults: newResults,
      });
    }
  }

  _filterByQueryTerm = files => {
    const { queryTerm } = this.state;
    if (!queryTerm) return files;
    return files.filter(file => {
      return (
        file.borrower.toLowerCase().includes(queryTerm.toLowerCase()) ||
        file.encodedFileID.includes(queryTerm)
      );
    });
  };

  _filterByStatus = files => {
    const { checkedStatuses } = this.state;
    return files.filter(file => {
      return checkedStatuses.includes(file.loanStatus);
    });
  };

  _paginateArray = array => {
    const { pageSize } = this.state;
    return array.reduce((acc, curr, i) => {
      if (i % pageSize === 0) {
        acc.push([curr]);
      } else {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    }, []);
  };

  getPipelineFiles = async () => {
    axios
      .get('../getPipelineFiles', {
        params: {
          pUserID: LoginState.userId,
        },
      })
      .then(res => {
        if (res && res.data) {
          let paginatedResults = res.data.filter(
            row => row.loanStatus !== '---'
          );
          paginatedResults = this._filterByQueryTerm(paginatedResults);
          paginatedResults = this._filterByStatus(paginatedResults);
          paginatedResults.sort(this.compareBy(this.sortBy, this.state.order));
          paginatedResults = this._paginateArray(paginatedResults);
          this.setState({
            allFiles: res.data,
            paginatedResults,
          });
        }
      })
      .catch(e => console.log(e, 'Error fetching pipeline files'));
  };

  compareBy(key, order, sortByStatus) {
    const statusOrder = {
      Registered: 1,
      'In Submission': 2,
      Submitted: 3,
      'In Underwriting': 4,
      Approved: 5,
      'Clear to Close': 6,
      'Doc Out': 7,
      'Doc In': 8,
      'In Review': 9,
      Reviewed: 10,
      'Clear to Fund': 11,
      Funded: 12,
      'In Shipping': 13,
      Shipped: 14,
      'In Post-Funding': 15,
      Closed: 16,
      Purchased: 17,
      'In Reconciling': 18,
      Completed: 19,
      'On Hold': 20,
      Declined: 21,
      Cancelled: 22,
      'AUS Requested': 23,
      'AUS Completed': 24,
    };
    return (a, b) => {
      // to sort currency amounts correctly (we need to remove special chararcters)
      var tempA = sortByStatus ? statusOrder[a[key]] : a[key];
      var tempB = sortByStatus ? statusOrder[b[key]] : b[key];
      if (a[key] && a[key][0] === '$')
        tempA = parseFloat(a[key].replaceAll('$', '').replaceAll(',', ''));
      if (b[key] && b[key][0] === '$')
        tempB = parseFloat(b[key].replaceAll('$', '').replaceAll(',', ''));

      if (tempA < tempB) return this.state[order] ? 1 : -1;
      if (tempA > tempB) return this.state[order] ? -1 : 1;
      return 0;
    };
  }

  sortBy(key, order, sortByStatus) {
    const { allFiles } = this.state;
    let arrayCopy = [];
    arrayCopy = [...allFiles];
    arrayCopy.sort(this.compareBy(key, order, sortByStatus));
    return this.setState({
      allFiles: arrayCopy,
      [order]: !this.state[order],
    });
  }

  handleStatusCheck({ target: { checked, value } }) {
    const { checkedStatuses } = this.state;
    let newList = [];
    // select all and checked
    if (checked && value === 'Select All') {
      newList = allStatuses;
    }
    // if status checked
    if (checked && value !== 'Select All') {
      newList = checkedStatuses.concat([value]);
    }
    // else
    if (!checked && value !== 'Select All') {
      newList = checkedStatuses.filter(item => item !== value);
    }
    this.setState({ checkedStatuses: newList });
  }

  searchLoansByTerm(e) {
    // debounce(() => this.setState({ queryTerm: e.target.value }), 500)();
    this.setState({ queryTerm: e.target.value });
  }

  toggleStatusModal = () => {
    this.setState(prevState => ({
      showStatusModal: !prevState.showStatusModal,
    }));
  };

  _handlePageSizeChange = value => {
    this.setState({ pageSize: value });
  };

  _changePage = value => {
    this.setState({ pageSelected: value });
  };

  formattedDate = date => {
    if (!date || date === '---') return date;
    return moment(date).format('MM/DD/YY');
  };

  highlightQuery = name => {
    const { queryTerm } = this.state;
    if (!queryTerm) return name;
    const parts = name.split(new RegExp(`(${queryTerm})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) =>
          part.toLowerCase() === queryTerm.toLowerCase() ? (
            <span key={i} className="highlight">
              {part}
            </span>
          ) : (
            part
          )
        )}{' '}
      </span>
    );
  };

  toggleAdvancedView = () => {
    this.setState(prevState => ({ advancedView: !prevState.advancedView }));
  };

  renderRows = () => {
    const { allFiles, advancedView, paginatedResults, pageSelected } =
      this.state;

    // no data at all
    if (!allFiles)
      return (
        <tr>
          <td colSpan="100%" className="text-center">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="/images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </td>
        </tr>
      );

    // no data with filters applied
    if (!paginatedResults.length)
      return (
        <tr>
          <td colSpan="100%" className="text-center">
            No current files
          </td>
        </tr>
      );

    // show filtered data
    return paginatedResults[pageSelected].map(file => (
      <tr key={file.fileDataID}>
        <td className="text-nowrap">
          <Link color="primary500" to={`loan-summary/${file.encodedFileID}`}>
            {file.encodedFileID}
          </Link>
          {LoginState.userId === 1000009 && (
            <span style={{ fontSize: '11px' }}>
              <br />
              {file.organization}
            </span>
          )}
        </td>
        <td className="text-start">
          <span
            className="fw-normal px-2 py-1 text-nowrap"
            style={{
              fontSize: '13px',
              color: [
                'clear to close',
                'clear to fund',
                'in post-funding',
                'on hold',
                'funded',
              ].includes(file.loanStatus.toLowerCase())
                ? 'white'
                : 'black',
              backgroundColor: STATUS_COLORS[file.loanStatus.toLowerCase()],
              borderRadius: '20px',
            }}
          >
            {file.loanStatus}
          </span>
        </td>
        <td style={{ textTransform: 'capitalize' }}>
          {this.highlightQuery(getFormattedName(file.borrower))}
        </td>
        <td className="text-end pe-5">{file.loanAmount}</td>
        {advancedView && (
          <>
            <td>{file.programCode}</td>
            <td className="text-center">{file.loanPurpose}</td>
            <td className="text-center">
              {this.formattedDate(file.registeredDate)}
            </td>
            <td className="text-center">
              {this.formattedDate(file.submittedDate)}
            </td>
            <td className="text-center">
              {this.formattedDate(file.approvedDate)}
            </td>
            <td className="text-center">
              {this.formattedDate(file.fundingDate)}
            </td>
            <td className="text-center">
              {this.formattedDate(file.lockExpirationDate)}
            </td>
            <td className="text-center">
              {this.formattedDate(file.epoExpirationDate)}
            </td>
          </>
        )}
      </tr>
    ));
  };

  render() {
    const {
      advancedView,
      showStatusModal,
      checkedStatuses,
      paginatedResults,
      pageSelected,
      pageSize,
    } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 mb-4 text-uppercase">
              Pipeline{' '}
              {this.props.match.params.sub !== 'all' &&
                `(${this.props.match.params.sub.toUpperCase()})`}
            </h1>
          </div>
          <div className="col-12 col-lg-4">
            <div className="input-group mb-2">
              <input
                className="form-control"
                onChange={e => this.searchLoansByTerm(e)}
                value={this.state.queryTerm}
                placeholder="Search by borrower name or loan no."
              />
              <button
                disabled={!this.state.queryTerm}
                className="btn btn-link"
                type="button"
                id="button-addon2"
                onClick={() => this.setState({ queryTerm: '' })}
              >
                <i className="bi bi-x-lg fs-5" />
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-8 d-flex flex-wrap justify-content-between align-items-center">
            <div className="form-check form-switch mb-0">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="detailViewSwitch"
                checked={advancedView}
                onChange={this.toggleAdvancedView}
              />
              <label className="form-check-label" htmlFor="detailViewSwitch">
                Detailed View
              </label>
            </div>
            <div className="d-flex align-items-center gap-4">
              <Pagination
                pages={paginatedResults}
                pageSize={pageSize}
                pageSizeChange={this._handlePageSizeChange}
                pageSelected={pageSelected}
                pageChange={this._changePage}
              />
            </div>
          </div>
          <div className="col-12 mt-2">
            {/* {hasFilters && (
              <button
                className="btn btn-light btn-sm"
                onClick={() => this.handleFilterClear()}
              >
                Filters
                <i className="bi bi-x ms-2" style={{ fontSize: '1rem' }}></i>
              </button>
            )} */}
            <div
              className={advancedView ? 'col-12' : 'col-12 col-lg-8 col-xl-6'}
            >
              <div className="table-responsive rounded border">
                <table className="table bg-white mb-0">
                  <thead>
                    <tr>
                      {PIPELINE_MAIN_TABLE_HEADERS.map(
                        ({ displayText, dataText, state }) =>
                          displayText === 'Status' ? (
                            <th
                              className={
                                advancedView ? 'text-center' : 'text-start'
                              }
                              key={displayText}
                              // style={
                              //   filteredList?.length > 2
                              //     ? { cursor: 'pointer' }
                              //     : {}
                              // }
                              // onClick={() =>
                              //   filteredList?.length > 2 &&
                              //   this.sortBy(dataText, state, true)
                              // }
                            >
                              Status
                              <button
                                onClick={this.toggleStatusModal}
                                type="button"
                                className="btn btn-light btn-sm bg-white ms-2"
                              >
                                <i className="bi bi-filter fs-6" />
                              </button>
                            </th>
                          ) : (
                            <th
                              className={
                                displayText === 'Loan Amount'
                                  ? 'text-end pe-5'
                                  : 'text-start'
                              }
                              key={displayText}
                              // style={
                              //   filteredList?.length > 2
                              //     ? { cursor: 'pointer' }
                              //     : {}
                              // }
                              // onClick={() =>
                              //   filteredList?.length > 2 &&
                              //   this.sortBy(dataText, state, false)
                              // }
                            >
                              {displayText}
                            </th>
                          )
                      )}

                      {advancedView &&
                        PIPELINE_ADVANCED_TABLE_HEADERS.map(
                          ({ displayText, dataText, state }) => {
                            return (
                              <th
                                key={displayText}
                                // style={
                                //   filteredList?.length > 2
                                //     ? { cursor: 'pointer' }
                                //     : {}
                                // }
                                className={
                                  displayText !== 'Program'
                                    ? 'text-center'
                                    : 'text-start'
                                }
                                // onClick={() =>
                                //   filteredList?.length > 2 &&
                                //   this.sortBy(dataText, state, false)
                                // }
                              >
                                {displayText}
                              </th>
                            );
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody>{this.renderRows()}</tbody>
                </table>
              </div>
              <Modal
                title="Select Status"
                width="350px"
                show={showStatusModal}
                hideCancelBtn={true}
                buttonMessage="Close"
                onDecline={this.toggleStatusModal}
                onAccept={this.toggleStatusModal}
              >
                {PIPELINE_STATUSES.map(status => (
                  <div className="form-check" key={status}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={status + 'Checkbox'}
                      value={status}
                      checked={checkedStatuses.includes(status)}
                      onChange={e => this.handleStatusCheck(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={status + 'Checkbox'}
                    >
                      {status}
                    </label>
                  </div>
                ))}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="selectAllCheckbox"
                    value="Select All"
                    onChange={e => this.handleStatusCheck(e)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectAllCheckbox"
                  >
                    Select All
                  </label>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Pipeline);
