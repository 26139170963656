import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import { STATUS_COLORS } from '../helpers/constants';

class AssociatedEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailData: '',
    };

    this.GetEmailData();
  }

  // get data before page loads
  GetEmailData = async () => {
    axios
      .get('/getAssociatedEmail', {
        params: {
          pBorrowerEmail: this.props.match.params.id,
        },
      })
      .then(res => {
        if (res && res.data) {
          this.setState({ emailData: res.data });
        }
      })
      .catch(e => console.log(e, 'Error fetching email data'));
  };

  render() {
    if (!this.state.emailData) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4>Associated Email Files ({this.props.match.params.id})</h4>
          </div>

          {this.state.emailData.others ? (
            <div className="col-12">
              <h3 className="border-bottom-2 border-gray text-capitalize my-4">
                Files (Using Same Email Address)
              </h3>
              <div className="row">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th>File ID</th>
                      <th>Borrowers</th>
                      <th>Name/Email</th>
                      <th>Submission Date</th>
                      <th>Loan Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.emailData.others.map(file => (
                      <tr key={file.fileDataID}>
                        <td className="text-center">
                          <Link
                            color="primary500"
                            to={`../pipeline/loan-summary/${file.fileDataID}`}
                          >
                            {file.fileDataID}
                          </Link>
                        </td>
                        <td>{file.borrowers}</td>
                        <td>
                          {file.fullName} ({this.state.email})
                        </td>
                        <td>{file.submittedDate}</td>
                        <td className="text-center">
                          <span
                            className={`badge text-bg-${
                              STATUS_COLORS[file.loanStatus]
                            }`}
                          >
                            {file.loanStatus}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default AssociatedEmail;
