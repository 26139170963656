import 'react-dropzone-uploader/dist/styles.css';

import React, { Component } from 'react';
import { base64ArrayBuffer, todaysDate } from '../helpers/utils';
import Dropzone from 'react-dropzone-uploader';
import ModalWait from '../components/ModalWait';
import Modal from '../components/Modal';
import Toast from '../components/Toast';
import ToolTip, { TinyTooltip } from '../components/Tooltip';
import axios from 'axios';
import LoginState from './LoginState';
import CommentAnimated from '../animations/CommentAnimated';
import { ReactComponent as Comment } from '../assets/svg/Comment.svg';

const CommentIcon = () => <Comment style={{ width: '24px', height: '24px' }} />;

const MAX_FILE_SIZE = 25000000;

export default class Conditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retrievedData: false,
      conditionList: [],
      isSubmitting: false,
      isUploading: false,
      showUploadModal: false,
      showConfirmModal: false,
      conditionNum: 0,
      conditionDesc: '',
      fileArr: [],
      searchLog: [],
      showResolved: false,
      successMessage: '',
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.PageLoad(this.props.match.params.id.substring(3));
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({ conditionList: [], retrievedData: false });
    this.GetSearchLog(fileID);
    this.FetchConditionsFromServer(fileID);
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async FetchConditionsFromServer(fileID) {
    // fetch loan status first
    axios
      .get('/getloanstatus', {
        params: { pFileID: fileID },
      })
      .then(res => {
        this.setState({ loanStatus: res.data });
      });

    axios
      .get('/getconditions', {
        params: {
          pFileID: fileID,
        },
      })
      .then(res => {
        if (res) {
          this.setState({
            retrievedData: true,
            conditionList: res.data,
          });
        }
      })
      .catch(e => console.log(e, 'Error fetching conditions'));
  }

  toggleTab = tab => {
    this.setState({ activeTab: tab });
  };

  combinePDFBuffers(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  async UploadConditions() {
    // skip if no files uploaded
    if (this.state.fileArr.length === 0) return;

    // check to see that combined file size is not over limit
    var totalSize = 0;
    var i = 0;
    for (i = 0; i < this.state.fileArr.length; i++) {
      totalSize += this.state.fileArr[i].size;
    }
    if (totalSize >= MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'Combined File Size Exceeds 25Mb!' });
      return;
    }
    this.setState({
      isUploading: true,
      showConfirmModal: false,
      showUploadModal: false,
    });

    // first combine all the pdfs into one.
    var combinedFiles = null;
    for (i = 0; i < this.state.fileArr.length; i++) {
      if (i === 0) combinedFiles = this.state.fileArr[i].fileUIntArr;
      else
        combinedFiles = this.combinePDFBuffers(
          combinedFiles,
          this.state.fileArr[i].fileUIntArr
        );
    }

    var docName =
      this.state.conditionNum === 0
        ? this.CheckedConditions()
        : this.state.conditionNum.toString();

    // then submit to server
    const params = {
      docType: 'ConditionUpload',
      docName: docName,
      fileID: this.props.match.params.id.substring(3),
      fileName: this.state.fileArr[0].fileName,
      fileContent: combinedFiles,
    };

    fetch('/fileupload', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(data => {
        // error check
        if (!data.startsWith('Success')) {
          // show error message
          this.setState({
            isUploading: false,
            showUploadModal: false,
            showConfirmModal: false,
            fileArr: [],
            successMessage: '',
            errorMessage: 'Error (Check for Secured PDFs)!',
            // data - will contain other error messages if we ever run into them
          });
          return;
        } else {
          // notify for successful upload
          this.setState({
            isUploading: false,
            showUploadModal: false,
            showConfirmModal: false,
            fileArr: [],
            successMessage:
              ' Files Uploaded For Condition(s):\n[' + docName + ']!',
          });
        }
      });

    // update submitted date on the condition(s) we just uploaded for.
    const conditionSplit = docName.split(' ');
    for (i = 0; i < conditionSplit.length; i++) {
      for (var j = 0; j < this.state.conditionList.length; j++) {
        if (
          this.state.conditionList[j].conditionNumber.toString() ===
          conditionSplit[i].toString()
        ) {
          this.state.conditionList[j].submittedDate = todaysDate();
        }
      }
    }
    this.setState({ showUploadModal: false, fileArr: [] });
  }

  async Comment(conditionID) {
    var comment = this.state['Comment' + conditionID];
    const response = axios.get('/conditioncomment', {
      params: {
        pUserID: LoginState.userId,
        pUserName: LoginState.userName,
        pUserOrg: LoginState.userOrg,
        pConditionID: conditionID,
        pComment: comment,
      },
    });
    var resultData = (await response).data;

    // check for errors
    if (!resultData) console.log('Error Uploading Comment');

    // also insert this comment into list of comments we have and clear textbox
    for (var i = 0; i < this.state.conditionList.length; i++) {
      if (this.state.conditionList[i].conditionID === conditionID) {
        this.state.conditionList[i].conditionComments.push({
          userName: LoginState.userName,
          content: comment,
        });
        break;
      }
    }
    this.setState({ ['Comment' + conditionID]: '' });
  }

  handleChangeStatus({ file, remove }, status) {
    if (file && file.size > MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'File Size Cannot Exceed 25 Mb!' });
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        this.state.fileArr.push({
          fileUIntArr: uintarr,
          fileName: '(' + this.state.conditionNum + ') ' + file.name,
          conditionID: this.state.conditionID,
          size: file.size,
        });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  InputContent(files, extra) {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <div>
          <i className="bi bi-cloud-upload text-primary fs-2"></i>
          <p className="mb-1 text-primary">
            Drag and Drop PDF Files or Click Below to Upload
          </p>
          <small className="text-muted fw-light d-none d-md-block">
            (The System Will Only Accept Up to 25 Mb of Files at a Time)
          </small>
        </div>
      );
    }
  }

  ConditionDetail(conditionID) {
    for (var i = 0; i < this.state.conditionList.length; i++) {
      if (this.state.conditionList[i].conditionID === conditionID) {
        // set state here to show or hide
        var condList = this.state.conditionList;
        condList[i].conditionExpanded = !condList[i].conditionExpanded;
        this.setState({ conditionList: condList });
        break;
      }
    }
  }

  async downloadConditionFiles(fileID, conditionID) {
    this.setState({ successMessage: 'Downloading File\nPlease Wait!' });
    const response = axios.get('/downloadconditionfile', {
      params: {
        pFileID: fileID,
        pConditionID: conditionID,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    // if we couldn't get a file back, abort
    if (!resultData) {
      alert('Error: File Not Found');
      return;
    }

    var binary = atob(resultData.documentData);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = resultData.documentName + '.' + resultData.documentExension;
    a.click();
    URL.revokeObjectURL(url);
    this.setState({ successMessage: '' });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    this.setState({ [name]: checked });
  };

  HighlightClass(condition) {
    var type = this.props.match.params.id.substring(0, 2);
    if (type === 'ac') return false;

    switch (type) {
      case 'uw':
        return (
          condition.conditionClass === 'UW' &&
          condition.submittedDate.slice(0, 5) !== '---' &&
          condition.conditionNumber !== '250'
        );
      case 'cp':
        return (
          condition.conditionClass === 'COMP' &&
          condition.submittedDate.slice(0, 5) !== '---' &&
          condition.conditionNumber !== '250'
        );
      case 'fd':
        return (
          condition.conditionClass === 'FUND' &&
          condition.submittedDate.slice(0, 5) !== '---' &&
          condition.conditionNumber !== '250'
        );
      case 'qc':
        return condition.conditionClass === 'QC';
      case 'di':
        return condition.conditionNumber === '250';
      default:
        return false;
    }
  }

  GenerateConditions(stage) {
    const { showResolved } = this.state;
    let conList = [];
    // if not "Show All" (showResolved), we will show if not (submitted, received, or cleared)
    for (var i = 0; i < this.state.conditionList.length; i++) {
      if (
        this.state.conditionList[i].conditionStage === stage &&
        (this.state.showResolved ||
          this.state.conditionList[i].clearedDate === '---' ||
          (this.state.conditionList[i].submittedDate !== '---' &&
            this.state.conditionList[i].receivedDate === '---'))
      )
        conList.push(this.state.conditionList[i]);
    }

    return (
      <div className="col-12 mb-2">
        <p className="border-bottom my-2 text-uppercase">{stage}</p>
        {conList.length === 0 ? (
          <div className="alert alert-secondary text-center mb-0" role="alert">
            No conditions found
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table bg-white">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th className="text-center">Created</th>
                  <th className="text-center">Duty</th>
                  <th className="text-center">Code</th>
                  <th className="text-center">Class</th>
                  <th></th>
                  <th>Description</th>
                  <th className="text-center">Uploaded</th>
                  <th className="text-center">Received</th>
                  {showResolved && <th className="text-center">Cleared</th>}
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {conList.map(condition => {
                  const newComments =
                    condition.conditionComments.length > 0 &&
                    condition.conditionComments.some(el => !el.reviewed);
                  return (
                    <React.Fragment key={condition.conditionID}>
                      <tr
                        className={
                          condition.conditionExpanded
                            ? 'border-0 border-bottom border-white'
                            : ''
                        }
                        style={{
                          backgroundColor: this.HighlightClass(condition)
                            ? '#fff9e8'
                            : 'white',
                        }}
                      >
                        {/* MULTI-CONDITION CHECKBOX */}
                        <td>
                          {this.state.fileArr.length > 0 &&
                            this.state.conditionNum === 0 && (
                              <input
                                type="checkbox"
                                name={'cb' + condition.conditionNumber}
                                checked={
                                  this.state[
                                    'cb' + condition.conditionNumber
                                  ] || false
                                }
                                onChange={this._toggleCheckbox}
                              />
                            )}
                        </td>
                        {/* ICON */}
                        <td
                          className="text-center"
                          style={{ minWidth: '20px', maxWidth: '20px' }}
                        >
                          {condition.flagColor !== 'none' && (
                            <TinyTooltip tip="Pay special attention to this condition">
                              <i className="bi bi-exclamation-diamond text-warning"></i>
                            </TinyTooltip>
                          )}
                        </td>
                        {/* CREATED */}
                        <td className="text-center">
                          {condition.requestedDate.slice(0, 5)}
                        </td>
                        {/* DUTY */}
                        <td className="text-center">
                          {condition.responsibleParty}
                        </td>
                        {/* CODE */}
                        <td className="text-center">
                          {condition.conditionNumber}
                        </td>
                        {/* CLASS */}
                        <td className="text-center">
                          {condition.conditionClass}
                        </td>
                        {/* COMMENTS */}
                        <td
                          onClick={() =>
                            this.ConditionDetail(condition.conditionID)
                          }
                        >
                          {newComments ? <CommentAnimated /> : <CommentIcon />}
                        </td>
                        {/* DESCRIPTION */}
                        <td style={{ minWidth: '400px', maxWidth: '400px' }}>
                          {condition.description}
                        </td>
                        {/* UPLOADED */}
                        {condition.bulkID ? (
                          <td className="text-center">
                            <ToolTip tip="Bulk Upload">
                              -(
                              {condition.submittedDate.slice(0, 5) === '---' ? (
                                '---'
                              ) : (
                                <span
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    color: 'red',
                                  }}
                                  onClick={() =>
                                    this.downloadConditionFiles(
                                      this.props.match.params.id.substring(3),
                                      condition.conditionID
                                    )
                                  }
                                >
                                  {condition.submittedDate.slice(0, 5)}
                                </span>
                              )}
                              )-
                            </ToolTip>
                          </td>
                        ) : (
                          <td className="text-center">
                            {condition.submittedDate.slice(0, 5) === '---' ? (
                              '---'
                            ) : (
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  color: 'red',
                                }}
                                onClick={() =>
                                  this.downloadConditionFiles(
                                    this.props.match.params.id.substring(3),
                                    condition.conditionID
                                  )
                                }
                              >
                                {condition.submittedDate.slice(0, 5)}
                              </span>
                            )}
                          </td>
                        )}
                        {/* RECEIVED */}
                        <td className="text-center">
                          {condition.receivedDate.slice(0, 5)}
                        </td>
                        {/* CLEARED */}
                        {showResolved && (
                          <td className="text-center">
                            {condition.clearedDate.slice(0, 5)}
                          </td>
                        )}
                        {/* ACTIONS */}
                        <td>
                          <div className="text-center">
                            <TinyTooltip tip="Upload">
                              <button
                                className="btn btn-light btn-sm me-2"
                                disabled={this.state.loanStatus === 'On Hold'}
                                onClick={() =>
                                  this.setState({
                                    fileArr: [],
                                    showUploadModal: true,
                                    conditionID: condition.conditionID,
                                    conditionNum: condition.conditionNumber,
                                    conditionDesc: condition.description,
                                  })
                                }
                              >
                                <i
                                  className="bi bi-upload"
                                  style={{ fontSize: '1rem' }}
                                ></i>
                              </button>
                            </TinyTooltip>
                            <TinyTooltip tip="Download">
                              <button
                                className="btn btn-light btn-sm"
                                disabled={
                                  condition.submittedDate.slice(0, 5) === '---'
                                }
                                onClick={() =>
                                  this.downloadConditionFiles(
                                    this.props.match.params.id.substring(3),
                                    condition.conditionID
                                  )
                                }
                              >
                                <i
                                  className="bi bi-download"
                                  style={{ fontSize: '1rem' }}
                                ></i>
                              </button>
                            </TinyTooltip>
                          </div>
                        </td>
                      </tr>
                      {condition.conditionExpanded && (
                        <tr>
                          <td colSpan="7" className="text-end">
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() =>
                                this.ConditionDetail(condition.conditionID)
                              }
                              aria-label="Close"
                            ></button>
                          </td>
                          <td className="ps-2 text-center">
                            {condition.conditionComments.length > 0 && (
                              <>
                                <div className="border-top border-bottom border-primary-subtle">
                                  <table
                                    width="100%"
                                    style={{
                                      borderCollapse: 'collapse',
                                      padding: '3px',
                                      fontSize: '13px',
                                    }}
                                  >
                                    <tbody>
                                      {condition.conditionComments.map(
                                        comment => (
                                          <tr key={comment.timestamp}>
                                            <td
                                              width="20px"
                                              style={{
                                                paddingTop: '7px',
                                                verticalAlign: 'top',
                                              }}
                                            >
                                              {comment.reviewed && (
                                                <i className="bi bi-check-lg text-success" />
                                              )}
                                            </td>
                                            <td
                                              width="80px"
                                              style={{
                                                textAlign: 'right',
                                                padding: '5px',
                                                verticalAlign: 'top',
                                                cursor: 'pointer',
                                                color: 'gray',
                                              }}
                                            >
                                              <TinyTooltip
                                                tip={comment.timestamp}
                                              >
                                                [{comment.userName}]:
                                              </TinyTooltip>
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                padding: '5px',
                                                verticalAlign: 'top',
                                              }}
                                            >
                                              {comment.content}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}
                            <input
                              className="form-control"
                              type="text"
                              name={'Comment' + condition.conditionID}
                              onChange={this.captureInput}
                              value={
                                this.state['Comment' + condition.conditionID]
                              }
                            />
                          </td>
                          <td colSpan="2">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() =>
                                this.Comment(condition.conditionID)
                              }
                            >
                              Comment
                            </button>
                          </td>
                          <td colSpan="2"></td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

  ShowUploadModalContent() {
    return (
      <div>
        <br />
        Condition #: {this.state.conditionNum}
        <br />
        <br />
        {this.state.conditionDesc}
        <br />
        <br />
        <Dropzone
          maxFiles="10"
          maxSizeBytes="25000000" // 25 Mb
          accept=".pdf"
          onChangeStatus={this.handleChangeStatus.bind(this)}
          inputContent={this.InputContent.bind(this)}
          canCancel={false}
          styles={{
            overflow: 'hidden',
            dropzone: {
              textAlign: 'center',
              border: '2px dashed lightgray',
              height: '200px',
              overflow: 'hidden',
              borderRadius: '0.25rem',
            },
            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
            inputLabel: (files, extra) =>
              extra.reject ? { color: 'red' } : {},
          }}
        />
        <br />
        {this.state.fileArr.length > 0 && (
          <>
            {this.state.fileArr.map(({ fileName }) => (
              <div key={fileName}>
                <i className="bi bi-chevron-right" />
                {fileName}
              </div>
            ))}
            <br />
          </>
        )}
      </div>
    );
  }

  ShowConfirmModalContent() {
    const conditionsChecked = this.CheckedConditions();
    const splitList = conditionsChecked.split(' ');
    var conditionList = [];
    for (var i = 0; i < splitList.length; i++) {
      for (var j = 0; j < this.state.conditionList.length; j++) {
        if (
          this.state.conditionList[j].conditionNumber.toString() ===
          splitList[i].toString()
        ) {
          conditionList.push({
            number: splitList[i],
            description: this.state.conditionList[j].description,
          });
          break;
        }
      }
    }

    return (
      <div>
        <br />
        <div style={{ color: 'red', textAlign: 'center' }}>
          <hr />
          We Encourage You to Upload Files For Each Condition Separately For
          Faster Review
          <hr />
        </div>
        <br />
        <br />

        <b>Condition(s):</b>
        <table>
          <tbody>
            {conditionList.map(condition => (
              <tr key={condition.number}>
                <td width="20px">
                  <i className="bi bi-chevron-right" />
                </td>
                <td>
                  {condition.number} - {condition.description}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <br />

        <b>File(s):</b>
        <br />
        <table>
          <tbody>
            {this.state.fileArr.map(({ fileName }) => (
              <tr key={fileName}>
                <td width="20px">
                  <i className="bi bi-chevron-right" />
                </td>
                <td>{fileName.substring(4)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </div>
    );
  }

  CheckedConditions() {
    const { conditionList } = this.state;
    var conditionStr = '';
    for (var i = 0; i < conditionList.length; i++) {
      if (this.state['cb' + conditionList[i].conditionNumber.toString()])
        conditionStr =
          conditionStr + ' ' + conditionList[i].conditionNumber.toString();
    }
    return conditionStr.trim();
  }

  CancelUpload() {
    // clear checkboxes
    const { conditionList } = this.state;
    for (var i = 0; i < conditionList.length; i++) {
      if (this.state['cb' + conditionList[i].conditionNumber.toString()])
        this.setState({
          ['cb' + conditionList[i].conditionNumber.toString()]: false,
        });
    }
    this.setState({
      showUploadModal: false,
      fileArr: [],
      conditionNum: 0,
      showConfirmModal: false,
    });
  }

  ShowBulkUpload() {
    const conditionsChecked = this.CheckedConditions();
    return (
      <div>
        <h4 className="border-bottom capitalize">Multi-Condition Upload</h4>
        <Dropzone
          maxFiles="10"
          maxSizeBytes="25000000" // 25 Mb
          accept=".pdf"
          onChangeStatus={this.handleChangeStatus.bind(this)}
          inputContent={this.InputContent.bind(this)}
          canCancel={false}
          styles={{
            overflow: 'hidden',
            dropzone: {
              textAlign: 'center',
              border: '1px dashed lightgray',
              height: '180px',
              overflow: 'hidden',
              borderRadius: '0.25rem',
            },
            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
            inputLabel: (files, extra) =>
              extra.reject ? { color: 'red' } : {},
          }}
        />

        {this.state.fileArr.length > 0 && this.state.conditionNum === 0 && (
          <>
            {conditionsChecked === '' ? (
              <div style={{ color: 'red', textAlign: 'center' }}>
                - Use the Checkboxes Below (Left Side of the Conditions) to
                Select Which Conditons To Apply the Uploaded File(s) To -
              </div>
            ) : (
              <div style={{ color: 'green', textAlign: 'center' }}>
                Conditions
                <br />- {conditionsChecked} -
              </div>
            )}
            <br />

            {this.state.fileArr.map(({ fileName }) => (
              <div key={fileName}>
                <i className="bi bi-chevron-right" />
                {fileName.substring(3)}
              </div>
            ))}
            <br />

            <div center>
              <button
                className="btn btn-secondary"
                onClick={() => this.CancelUpload()}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ showConfirmModal: true })}
                disabled={conditionsChecked === ''}
              >
                Upload
              </button>
            </div>
            <br />
          </>
        )}
      </div>
    );
  }

  renderContent = () => {
    const { retrievedData } = this.state;
    if (!retrievedData) {
      return (
        <div className="col-12 text-center">
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    } else if (!this.state.conditionList) {
      return (
        <div className="col-12">
          <div
            className="alert alert-secondary d-flex justify-content-center align-items-center p-2 mt-4"
            role="alert"
          >
            <i className="bi bi-x-square me-2"></i>
            <div>No condition available</div>
          </div>
        </div>
      );
    }
    return (
      <>
        {this.state.loanStatus === 'On Hold' ? (
          <div
            style={{
              color: 'red',
              fontSize: '16px',
              paddingTop: '20px',
              paddingBottom: '20px',
            }}
          >
            * Condition Uploads Disabled For Files{' '}
            <b>
              <u>On Hold</u>
            </b>
          </div>
        ) : (
          <>{this.ShowBulkUpload()}</>
        )}
        {this.GenerateConditions('Prior To Close')}
        {this.GenerateConditions('TRID')}
        {this.GenerateConditions('Prior To Funding')}
        {this.GenerateConditions('Broker Check Hold')}
      </>
    );
  };

  render() {
    if (!this.state.retrievedData) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex mb-2">
            <div className="d-flex align-items-center gap-2 flex-fill">
              <h1 className="fs-4 mb-0">CONDITIONS</h1>
              <button
                className="btn btn-text dropdown-toggle"
                style={{ marginLeft: '5px' }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-list"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <p className="dropdown-item mb-0 disabled">Recent Files</p>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {this.state.searchLog.length > 0 ? (
                  <>
                    {this.state.searchLog.map(file => (
                      <li key={file.encodedFileID}>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            this.props.history.push(
                              '/pipeline/conditions/ac-' + file.encodedFileID
                            );
                            this.PageLoad(file.encodedFileID);
                          }}
                        >
                          {file.encodedFileID} ({file.borrower})
                        </span>
                      </li>
                    ))}
                  </>
                ) : (
                  <li>
                    <p className="dropdown-item mb-0 disabled">
                      No Recent Files
                    </p>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="col-12">
            <ul className="nav nav-tabs nav-fill">
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span
                  className={`nav-link ${
                    !this.state.showResolved ? 'active' : ''
                  }`}
                  onClick={() => this.setState({ showResolved: false })}
                  aria-current="page"
                  href="#"
                >
                  Pending Conditions
                </span>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span
                  className={`nav-link ${
                    this.state.showResolved ? 'active' : ''
                  }`}
                  onClick={() => this.setState({ showResolved: true })}
                  href="#"
                >
                  All Conditions
                </span>
              </li>
            </ul>
          </div>
          {this.renderContent()}
          <Modal
            title="Upload Conditions"
            show={this.state.showUploadModal}
            onAccept={() => this.UploadConditions()}
            onDecline={() => this.CancelUpload()}
            onAcceptDisabled={this.state.isUploading}
            buttonMessage="Upload"
          >
            {this.ShowUploadModalContent()}
          </Modal>
          <Modal
            title="Confirm Multi-Condition Upload"
            show={this.state.showConfirmModal}
            onAccept={() => this.UploadConditions()}
            onDecline={() => this.CancelUpload()}
            buttonMessage="Upload"
          >
            {this.ShowConfirmModalContent()}
          </Modal>
          {this.state.successMessage && (
            <Toast
              type="success"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: '' })}
            />
          )}
          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
          <ModalWait
            title="Uploading Files. Please Wait!"
            show={this.state.isUploading}
          />
        </div>
      </div>
    );
  }
}
