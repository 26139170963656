import {
  Body,
  Verify,
  Task,
  Email,
  AutoCheck,
  SignOff,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS, STATUS_COLORS } from '../../helpers/constants';
import { todaysDateYYYY, getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class FundingChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      borrowers: [],
      isLoading: true,
      errorMessage: '',
      checkList: {
        changeInReview: { checked: false },
        spfuEmail: { checked: false },
        sprEmail: { checked: false },
        ptfuEmail: { checked: false },
        ffEmail: { checked: false },
        fundedEmail: { checked: false },
        wcEmail: { checked: false },
        conditions: { checked: false },
        package: { checked: false },
        requestDocs: { checked: false },
        checkFunds: { checked: false },
        uploadByte: { checked: false },
        fundingFigures: { checked: false },
        letterDateWithin30: { checked: false },
        ctfEmail: { checked: false },
        funding: { checked: false },
        wireConfirm: { checked: false },
        changeReviewed: { checked: false },
        changeCtf: { checked: false },
        changeFunded: { checked: false },
        complete: { checked: false },
      },
      showEmailTemplate: false,
      emailTemplate: [],
      spfuTemplate: [],
      sprTemplate: [],
      ptfuTemplate: [],
      ctfTemplate: [],
      ffTemplate: [],
      fundedTemplate: [],
      wcTemplate: [],
      spfuEmailLog: [],
      sprEmailLog: [],
      ptfuEmailLog: [],
      ctfEmailLog: [],
      ffEmailLog: [],
      fundedEmailLog: [],
      wcEmailLog: [],
      showEmailVerification: false,
      verificationEmail: '',
    };
    this.getFundingData();
  }

  async getFundingData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'FND' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getfundingfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var fundingFields = (await response).data;
    if (fundingFields.daysDifference) {
      var days = parseInt(fundingFields.daysDifference);
      checklistData.letterDateWithin30.checked = days >= 0 && days <= 30;
    }
    this.setState({
      fileData: resultData,
      checkList: checklistData,
      fedRefNum: fundingFields.fedRefNum || '',
      originalFedRefNum: fundingFields.fedRefNum || '',
      wireAmount: fundingFields.wireAmount || '',
      originalWireAmount: fundingFields.wireAmount || '',
      letterDate: fundingFields.letterDate || '',
      scheduledFundingDate: fundingFields.scheduledFundingDate || '',
      showFundDate: fundingFields.voeType === '1',
      daysDifference: fundingFields.daysDifference,
      spfuTemplate: fundingFields.spfuTemplate,
      spfuEmailLog: fundingFields.spfuEmailLog,
      sprTemplate: fundingFields.sprTemplate,
      sprEmailLog: fundingFields.sprEmailLog,
      ptfuTemplate: fundingFields.ptfuTemplate,
      ptfuEmailLog: fundingFields.ptfuEmailLog,
      ctfTemplate: fundingFields.ctfTemplate,
      ctfEmailLog: fundingFields.ctfEmailLog,
      ffTemplate: fundingFields.ffTemplate,
      ffEmailLog: fundingFields.ffEmailLog,
      fundedTemplate: fundingFields.fundedTemplate,
      fundedEmailLog: fundingFields.fundedEmailLog,
      wcTemplate: fundingFields.wcTemplate,
      wcEmailLog: fundingFields.wcEmailLog,
      isLoading: false,
    });
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'FND-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'FND',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2].toString().trim(), // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        if (fieldsToUpdate[i][4]) {
          this.setState({
            ['original' +
            fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
            fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]]
              .toString()
              .trim(),
            [fieldsToUpdate[i][4] + 'Updated']: true,
          });
        }
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  async setLoanStatus(status) {
    this.setState({ changingState: status });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      file.loanStatus = status;
      switch (status) {
        case 'In Review':
          file.inReviewDate = todaysDateYYYY();
          break;
        case 'Reviewed':
          file.reviewedDate = todaysDateYYYY();
          break;
        case 'Clear to Fund':
          file.ctfDate = todaysDateYYYY();
          break;
        case 'Funded':
          file.fundingDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }

  allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') continue;
      if (prop === 'letterDateWithin30' && !this.state.showFundDate) continue;

      visibleCount++;
      if (prop === 'changeInReview') {
        if (this.state.fileData.inReviewDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (prop === 'changeReviewed') {
        if (this.state.fileData.reviewedDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (prop === 'changeCtf') {
        if (this.state.fileData.ctfDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (prop === 'changeFunded') {
        if (this.state.fileData.fundingDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      )
        checkedCount++;
    }
    var completePercent = this.state.fileData.completePercent
      ?.split('FND')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('FND')[0] +
        'FND (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('FND')[1]
          .substring(
            this.state.fileData.completePercent?.split('FND')[1].indexOf(')') +
              1
          );
      this.props.updatePercent(
        'FND',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount) return false;
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('FND', 'complete');
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">Funding Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="h5">Funding Checklist</h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Change Status (In Review) */}
          <ChecklistCard
            checked={this.state.fileData.inReviewDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeInReview"
                  name="changeInReview"
                  disabled={true}
                  checked={this.state.fileData.inReviewDate !== '---'}
                />
                <label className="form-check-label" htmlFor="changeInReview">
                  In Review
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'In Review'" />
                {this.state.fileData.inReviewDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['in review'],
                      }}
                    >
                      In Review
                    </span>
                    {this.state.fileData.inReviewDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.inReviewDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('In Review')}
                    >
                      {this.state.changingState === 'In Review'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.inReviewDate !== '---'}
                tip="In Review Date Not Found"
              />
            }
          />

          {/* Signed Package Follow Up */}
          <ChecklistCard
            checked={checkList.spfuEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="spfuEmail"
                  name="spfuEmail"
                  checked={checkList.spfuEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="spfuEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Signed Package Follow-Up
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.spfuTemplate,
                      emailLog: this.state.spfuEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.spfuEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.spfuEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.spfuEmail.checked && (
                <SignOff
                  user={checkList.spfuEmail.userName}
                  date={checkList.spfuEmail.timestamp}
                />
              )
            }
          />

          {/* Signed Package Reviewed */}
          <ChecklistCard
            checked={checkList.sprEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="sprEmail"
                  name="sprEmail"
                  checked={checkList.sprEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="sprEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Signed Package Reviewed
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.sprTemplate,
                      emailLog: this.state.sprEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.sprEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.sprEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.sprEmail.checked && (
                <SignOff
                  user={checkList.sprEmail.userName}
                  date={checkList.sprEmail.timestamp}
                />
              )
            }
          />

          {/* Change Status (Reviewed) */}
          <ChecklistCard
            checked={this.state.fileData.reviewedDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeReviewed"
                  name="changeReviewed"
                  disabled={true}
                  checked={this.state.fileData.reviewedDate !== '---'}
                />
                <label className="form-check-label" htmlFor="changeReviewed">
                  Reviewed
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Reviewed'" />
                {this.state.fileData.reviewedDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['reviewed'],
                      }}
                    >
                      Reviewed
                    </span>
                    {this.state.fileData.reviewedDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.reviewedDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('Reviewed')}
                    >
                      {this.state.changingState === 'Reviewed'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.reviewedDate !== '---'}
                tip="Reviewed Date Not Found"
              />
            }
          />

          {/* PTF Updates */}
          <ChecklistCard
            checked={checkList.ptfuEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="ptfuEmail"
                  name="ptfuEmail"
                  checked={checkList.ptfuEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="ptfuEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                PTF Updates
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.ptfuTemplate,
                      emailLog: this.state.ptfuEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.ptfuEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.ptfuEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.ptfuEmail.checked && (
                <SignOff
                  user={checkList.ptfuEmail.userName}
                  date={checkList.ptfuEmail.timestamp}
                />
              )
            }
          />

          {/* Change Status (Clear to Fund) */}
          <ChecklistCard
            checked={this.state.fileData.ctfDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeCtf"
                  name="changeCtf"
                  disabled={true}
                  checked={this.state.fileData.ctfDate !== '---'}
                />
                <label className="form-check-label" htmlFor="changeCtf">
                  Clear to Fund
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Clear to Fund'" />
                {this.state.fileData.ctfDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['clear to fund'],
                      }}
                    >
                      Clear to Fund
                    </span>
                    {this.state.fileData.ctfDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.ctfDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('Clear to Fund')}
                    >
                      {this.state.changingState === 'Clear to Fund'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.ctfDate !== '---'}
                tip="Clear to Fund Date Not Found"
              />
            }
          />

          {/* Funding Figures */}
          <ChecklistCard
            checked={checkList.ffEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="ffEmail"
                  name="ffEmail"
                  checked={checkList.ffEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="ffEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Funding Figures
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.ffTemplate,
                      emailLog: this.state.ffEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.ffEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.ffEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.ffEmail.checked && (
                <SignOff
                  user={checkList.ffEmail.userName}
                  date={checkList.ffEmail.timestamp}
                />
              )
            }
          />

          {/* Change Status (Funded) */}
          <ChecklistCard
            checked={this.state.fileData.fundingDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeFunded"
                  name="changeFunded"
                  disabled={true}
                  checked={this.state.fileData.fundingDate !== '---'}
                />
                <label className="form-check-label" htmlFor="changeCtf">
                  Funded
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Funded'" />
                {this.state.fileData.fundingDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['funded'],
                      }}
                    >
                      Funded
                    </span>
                    {this.state.fileData.fundingDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.fundingDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('Funded')}
                    >
                      {this.state.changingState === 'Funded' ? '...' : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.fundingDate !== '---'}
                tip="Funding Date Not Found"
              />
            }
          />

          {/* Funded */}
          <ChecklistCard
            checked={checkList.fundedEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="fundedEmail"
                  name="fundedEmail"
                  checked={checkList.fundedEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="fundedEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Funded
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.fundedTemplate,
                      emailLog: this.state.fundedEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.fundedEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.fundedEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.fundedEmail.checked && (
                <SignOff
                  user={checkList.fundedEmail.userName}
                  date={checkList.fundedEmail.timestamp}
                />
              )
            }
          />

          {/* Conditions */}
          <ChecklistCard
            checked={checkList.conditions.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="conditions"
                  id="conditions"
                  checked={checkList.conditions.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="conditions">
                  Conditions
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                Review Existing and Outstanding Conditions
              </div>
            }
            alert={
              checkList.conditions.checked && (
                <SignOff
                  user={checkList.conditions.userName}
                  date={checkList.conditions.timestamp}
                />
              )
            }
          />

          {/* Package */}
          <ChecklistCard
            checked={checkList.package.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="package"
                  id="package"
                  checked={checkList.package.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="package">
                  Review Package
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  Make Sure All Docs Are Included
                </div>
                <div className="hstack">
                  <Verify />
                  <div>
                    Make Sure All Docs Are Signed
                    <br />
                    Dated + Visible + Clear
                  </div>
                </div>
                <div>
                  <Task />
                  Update File Status
                </div>
                <div className="hstack">
                  <Task />
                  <div>
                    Compare Closing Package Signatures
                    <br />
                    With TRID/ID Package
                  </div>
                </div>
              </div>
            }
            alert={
              checkList.package.checked && (
                <SignOff
                  user={checkList.package.userName}
                  date={checkList.package.timestamp}
                />
              )
            }
          />

          {/* Request Documents */}
          <ChecklistCard
            checked={checkList.requestDocs.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="requestDocs"
                  id="requestDocs"
                  checked={checkList.requestDocs.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="requestDocs">
                  Request Needed Documents
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  Look at Settlement Statement
                </div>
                <div className="hstack">
                  <Verify
                    tip={
                      'Make Sure Amount & Bank Account on Wire Detail Matches\n' +
                      "If Gift Letter Doesn't Match, Request Updated One\n" +
                      "If Bank Account or Amount Doesn't Match, Let Underwriter Know"
                    }
                  />
                  Check Escrow Receipt With Wire Details + Assets
                </div>
                <div>
                  <Verify
                    tip={
                      'Check That They Have the Same Address (Property and Title)\n' +
                      'Make Sure All Docs Have the Same Name + Property Address\n' +
                      '(ex. Note, Settlement Statement) and on Byte\n' +
                      'Make Sure Order # Is Also the Same'
                    }
                  />
                  Prelim, CPL, and Wire Instruction Address
                </div>
              </div>
            }
            alert={
              checkList.requestDocs.checked && (
                <SignOff
                  user={checkList.requestDocs.userName}
                  date={checkList.requestDocs.timestamp}
                />
              )
            }
          />

          {/* Check Funds */}
          <ChecklistCard
            checked={checkList.checkFunds.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="checkFunds"
                  id="checkFunds"
                  checked={checkList.checkFunds.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="checkFunds">
                  Check Funds
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                Check If There's Enough $ In Warehouse
              </div>
            }
            alert={
              checkList.checkFunds.checked && (
                <SignOff
                  user={checkList.checkFunds.userName}
                  date={checkList.checkFunds.timestamp}
                />
              )
            }
          />

          {/* Check Funding Letter Within 30 Days */}
          {this.state.showFundDate && (
            <ChecklistCard
              checked={checkList.letterDateWithin30.checked}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="letterDateWithin30"
                    id="letterDateWithin30"
                    checked={checkList.letterDateWithin30.checked}
                    disabled={true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="letterDateWithin30"
                  >
                    Verification Letter
                  </label>
                </div>
              }
              body={
                <div className="hstack">
                  <Verify
                    tip={
                      '3rd Party Verification Letter Must Be\nWithin 30 Days Before the Funding Date'
                    }
                  />
                  <div className="gap-3">
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        marginBottom: '5px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['in submission'],
                      }}
                    >
                      Letter Sent
                    </span>
                    {this.state.letterDate}
                    <br />
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        marginBottom: '5px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['approved'],
                      }}
                    >
                      Funding Date
                    </span>
                    {this.state.scheduledFundingDate}
                    <br />
                    {this.state.scheduledFundingDate !== '---' &&
                      this.state.letterDate !== '---' && (
                        <small>
                          - Letter Sent&nbsp;
                          <span
                            style={{
                              color: checkList.letterDateWithin30.checked
                                ? 'green'
                                : 'red',
                            }}
                          >
                            ( {this.state.daysDifference.replace('-', '')} )
                          </span>
                          &nbsp;Days{' '}
                          {parseInt(this.state.daysDifference) >= 0 ? (
                            'Before'
                          ) : (
                            <span
                              style={{
                                color: 'red',
                                textDecoration: 'underline',
                              }}
                            >
                              After
                            </span>
                          )}{' '}
                          Funding -
                        </small>
                      )}
                  </div>
                </div>
              }
              alert={
                <AutoCheck
                  passed={checkList.letterDateWithin30.checked}
                  tip={'Letter Not Received Within 30 Days Before Funding'}
                />
              }
            />
          )}

          {/* Byte Uploads */}
          <ChecklistCard
            checked={checkList.uploadByte.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="uploadByte"
                  id="uploadByte"
                  checked={checkList.uploadByte.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="uploadByte">
                  Byte Uploads
                </label>
              </div>
            }
            body={
              <div>
                <Task />
                Upload MERS and Funding Figures Into Byte
              </div>
            }
            alert={
              checkList.uploadByte.checked && (
                <SignOff
                  user={checkList.uploadByte.userName}
                  date={checkList.uploadByte.timestamp}
                />
              )
            }
          />

          {/* Funding Figures */}
          <ChecklistCard
            checked={checkList.fundingFigures.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="fundingFigures"
                  id="fundingFigures"
                  checked={checkList.fundingFigures.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="fundingFigures">
                  Funding Figures
                </label>
              </div>
            }
            body={
              <div>
                <Task />
                Fill Wire Amount / Warehouse Lender on Byte
              </div>
            }
            alert={
              checkList.fundingFigures.checked && (
                <SignOff
                  user={checkList.fundingFigures.userName}
                  date={checkList.fundingFigures.timestamp}
                />
              )
            }
          />

          {/* CTF Email */}
          <ChecklistCard
            checked={checkList.ctfEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="ctfEmail"
                  name="ctfEmail"
                  checked={checkList.ctfEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="ctfEmail">
                  CTF Email
                </label>
              </div>
            }
            body={
              <div>
                <div>
                  <Email tip="Once Everything Ready / Received" />
                  Send CTF Email
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.ctfTemplate,
                        emailLog: this.state.ctfEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.ctfEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.ctfEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.ctfEmail.checked && (
                <SignOff
                  user={checkList.ctfEmail.userName}
                  date={checkList.ctfEmail.timestamp}
                />
              )
            }
          />

          {/* Funding */}
          <ChecklistCard
            checked={checkList.funding.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="funding"
                  id="funding"
                  checked={checkList.funding.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="funding">
                  Funding
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div className="vstack">
                  {fileData.programCode.includes('MTGP1') ? (
                    <React.Fragment>
                      <div className="hstack">
                        <b>Hanmi:</b>&nbsp;(MTGP1)
                      </div>
                      <div className="hstack">
                        <Task />
                        <div>
                          Prepare and Upload Documents <br />
                          Into Funding and Condition Approval Sheet
                        </div>
                      </div>
                      <div>
                        <Task />
                        Combine Files
                      </div>
                      <div>
                        <Task />
                        Send to Hanmi Warehouse
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="hstack">
                        <b>First Horizon:</b>&nbsp;(All QM & MTGP2)
                      </div>
                      <div className="mt-3">
                        <Task />
                        Fill Out Excel Sheet
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.funding.checked && (
                <SignOff
                  user={checkList.funding.userName}
                  date={checkList.funding.timestamp}
                />
              )
            }
          />

          {/* Send Wire Confirmation */}
          <ChecklistCard
            checked={checkList.wireConfirm.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="wireConfirm"
                  id="wireConfirm"
                  checked={checkList.wireConfirm.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="wireConfirm">
                  Wire Confirmation
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <div>
                    <Task tip="Include $ Amount and Reference #" />
                    Send Funding Email and Wire Confirmation
                  </div>
                </div>
                <div className="input-group">
                  <span className="input-group-text">Fed Ref #:</span>
                  <input
                    type="text"
                    name="fedRefNum"
                    className="form-control"
                    value={this.state.fedRefNum || ''}
                    disabled={checkList.wireConfirm.checked}
                    onChange={this.captureInput}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      this.state.originalFedRefNum === this.state.fedRefNum ||
                      checkList.wireConfirm.checked
                    }
                    onClick={() =>
                      this.updateDynamicDataField([
                        [
                          'Fed Ref Num',
                          '',
                          this.state.fedRefNum,
                          '',
                          'fedRefNum',
                        ],
                      ])
                    }
                  >
                    {this.state.fedRefNumUpdated ? '✓' : 'Update'}
                  </button>
                </div>
                <div className="input-group">
                  <span className="input-group-text">Wire Amount:</span>
                  <input
                    type="number"
                    placeholder="$"
                    name="wireAmount"
                    className="form-control"
                    disabled={checkList.wireConfirm.checked}
                    value={this.state.wireAmount || ''}
                    onChange={this.captureInput}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      this.state.originalWireAmount === this.state.wireAmount ||
                      checkList.wireConfirm.checked
                    }
                    onClick={() =>
                      this.updateDynamicDataField([
                        [
                          'Wire Amount',
                          '',
                          this.state.wireAmount,
                          '',
                          'wireAmount',
                        ],
                      ])
                    }
                  >
                    {this.state.wireAmountUpdated ? '✓' : 'Update'}
                  </button>
                </div>
              </div>
            }
            alert={
              checkList.wireConfirm.checked && (
                <SignOff
                  user={checkList.wireConfirm.userName}
                  date={checkList.wireConfirm.timestamp}
                />
              )
            }
          />

          {/* Wire Confirmation Email */}
          <ChecklistCard
            checked={checkList.wcEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="wcEmail"
                  name="wcEmail"
                  checked={checkList.wcEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="wcEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <div>
                  <Email />
                  Wire Confirmation
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.wcTemplate,
                        emailLog: this.state.wcEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.wcEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.wcEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.wcEmail.checked && (
                <SignOff
                  user={checkList.wcEmail.userName}
                  date={checkList.wcEmail.timestamp}
                />
              )
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
