import React, { Component } from 'react';

export default class ConfirmRegistration extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="border rouded rounded-4 px-3 px-md-4 py-4 text-center bg-white">
            <img
              height="200"
              src="/images/register_success.png"
              alt="register success"
              className="my-4"
            />
            <h1 className="h2 text-success">
              Congrats! We'll take it from here.
            </h1>
            <p className="text-muted">
              Registration complete, go to document upload to start adding files
            </p>
            <hr className="mx-5" />
            <div>
              <button
                className="btn btn-light"
                onClick={() => this.props.history.push('/dashboard')}
              >
                Dashboard
              </button>
              <button
                className="btn btn-primary text-white ms-2"
                onClick={() =>
                  this.props.history.push(
                    `/pipeline/submission/${this.props.loan.fileID}`
                  )
                }
              >
                Document upload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
