import { Component } from 'react';

class PageRedirect extends Component {
  constructor(props) {
    super(props);
    this.Redirect();    
  }

  async Redirect() {
    var parID = this.props.match.params.id;

    // if invalid redirect - send to dashboard
    if(!parID || parID.length < 10)
      this.props.history.push('/');

    // get the file id based on the input filename
    var fileID = parID.substring(3).replaceAll("-", "");
    switch(parID.substring(0,2)) {
      case "fd":
        this.props.history.push('/details/sub-' + fileID);
        break;
      case "cc":
        this.props.history.push('/pipeline/change-in-circumstance/' + fileID);
        break;
      case "id":
        this.props.history.push('/pipeline/important-dates/' + fileID);
        break;
      default:
        this.props.history.push('/');
        break;
    }
  }
}
export default PageRedirect;