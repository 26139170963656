import { ACTIONS, Session } from '../../helpers/sessionContext';
import { PRICING_OPTIONS, JWT_SECRET_KEY } from '../../helpers/constants';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import React, { useContext, useState } from 'react';

import { Base64 } from 'js-base64';
import LoginState from '../../components/LoginState';
import axios from 'axios';
import jwt from 'jsonwebtoken';

const Login = ({ history }) => {
  const { dispatch } = useContext(Session);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async values => {
    setSuccessMsg('');
    localStorage.setItem('lastTimeStamp', Date.now());

    // if username or password blank, don't process
    if (!(values.username && values.password)) {
      setErrorMessage('Please enter a username and password');
      return;
    }

    try {
      const response = axios.get('login', {
        params: {
          username: values.username,
          password: Base64.encode(values.password),
        },
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;

      if (resultData) {
        var dispName = resultData.displayName;
        var userName = resultData.userName;
        var userId = resultData.userID;
        var userRole = resultData.userRole;
        var userOrg = resultData.organization;
        var orgType = resultData.orgType;
        var compPlan = resultData.compTier;
        var flatFee = resultData.flatFee;
        var licensed = resultData.licensed === "1";
        var limited = resultData.limited !== "0";

        LoginState.loggedIn = true;
        LoginState.displayName = dispName;
        LoginState.userId = userId;
        LoginState.userRole = userRole;
        LoginState.userOrg = userOrg;
        LoginState.userName = userName;
        LoginState.orgType = orgType;
        LoginState.compPlan = compPlan ? PRICING_OPTIONS.compensationTier[Number(compPlan) - 1].label : '---';
        LoginState.flatFee = flatFee;
        LoginState.licensed = licensed;
        LoginState.limited = limited;

        const token = jwt.sign({ LoginState }, JWT_SECRET_KEY, {
          algorithm: 'HS256',
          expiresIn: '6h',
        });
        dispatch({ type: ACTIONS.SET_USER, value: LoginState });
        localStorage.setItem('user', JSON.stringify(LoginState));
        localStorage.setItem('token', token);
        history.push('/dashboard');
      } else setErrorMessage('Incorrect UserName and/or Password Specified');
    } catch (error) {
      setErrorMessage('An Error Occurred When Attempting To Login');
    }
  };

  return (
    <Form
      onSubmit={handleLogin}
      initialValues={{ username: '', password: '' }}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          style={{ width: '100%', maxWidth: '400px' }}
        >
          <div className="vstack gap-3 text-center mx-auto pt-3">
            <Field
              autoFocus
              className="form-control form-control-lg w-100"
              name="username"
              component="input"
              type="text"
              placeholder="User Name"
            />
            <Field
              className="form-control form-control-lg w-100"
              name="password"
              component="input"
              type="password"
              placeholder="Password"
            />
            <Link to="/forgot-password">Forgot password?</Link>
            <button
              className="btn btn-primary mt-2"
              id="LoginBtn"
              type="submit"
            >
              Sign In
            </button>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}
            <div className="vstack gap-1">
              <Link className="btn btn-outline-primary" to="/escrow">
                Escrow Access
              </Link>
              {/* <Link to="/loan-wizard/purpose">Loan Wizard</Link>
              <Link to="/geoid-validator">GeoID Validator</Link> */}
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default Login;
