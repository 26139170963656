import 'react-dropzone-uploader/dist/styles.css';
import React, { Component } from 'react';
import { base64ArrayBuffer, todaysDate } from '../helpers/utils';
import Dropzone from 'react-dropzone-uploader';
import ModalWait from './ModalWait';
import Toast from './Toast';
import ToolTip from './Tooltip';
import axios from 'axios';
import styled from 'styled-components';
import Modal from './Modal';

export default class EscrowConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      borrowerName: '',
      zipCode: '',
      zipCodeInput: '',
      retrievedData: false,
      retrievedCP: false,
      conditionList: [],
      closingPackage: '',
      isSubmitting: false,
      isUploading: false,
      showUploadModal: false,
      conditionNum: 0,
      conditionDesc: '',
      fileArr: [],
      showResolved: false,
      successMessage: '',
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.FetchConditionsFromServer();
    this.FetchClosingPackageFromServer();
  }

  async FetchConditionsFromServer() {
    axios
      .get('/getescrowconditions', {
        params: {
          pFileID: this.props.match.params.id,
        },
      })
      .then(res => {
        if (res) {
          // if we had no data, but just returned borrower and ssn info
          if (res.data.length === 1 && !res.data[0].conditionType) {
            this.setState({
              retrievedData: true,
              borrowerName: res.data[0].borrowerName,
              zipCode: res.data[0].zipCode,
              address: res.data[0].address,
              escrowNumber: res.data[0].escrowNumber,
              conditionList: null,
            });
          } else {
            this.setState({
              retrievedData: true,
              borrowerName: res.data[0].borrowerName,
              zipCode: res.data[0].zipCode,
              address: res.data[0].address,
              escrowNumber: res.data[0].escrowNumber,
              conditionList: res.data,
            });
          }
        }
      })
      .catch(e =>
        this.setState({ errorMessage: 'Error Fetching Conditions!' })
      );
  }

  async FetchClosingPackageFromServer() {
    axios
      .get('/getClosingPackage', {
        params: {
          pFileID: this.props.match.params.id,
        },
      })
      .then(res => {
        if (res) {
          this.setState({
            retrievedCP: true,
            closingPackage: res.data,
          });
        }
      })
      .catch(e => console.log(e, 'Error Fetching Closing Package'));
  }

  toggleTab = tab => {
    this.setState({ activeTab: tab });
  };

  combinePDFBuffers(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  async UploadConditions() {
    // skip if no files uploaded
    if (this.state.fileArr.length === 0) return;

    this.setState({
      isUploading: true,
      showUploadModal: false,
      successMessage: 'Uploading File...\nPlease Wait!',
    });
    // first combine all the pdfs into one.
    var combinedFiles = null;
    for (var i = 0; i < this.state.fileArr.length; i++) {
      if (i === 0) combinedFiles = this.state.fileArr[i].fileUIntArr;
      else
        combinedFiles = this.combinePDFBuffers(
          combinedFiles,
          this.state.fileArr[i].fileUIntArr
        );
    }

    // then submit to server
    const params = {
      docType: 'ConditionUpload',
      docName: this.state.conditionNum.toString(),
      fileID: this.props.match.params.id,
      fileName: this.state.fileArr[0].fileName,
      fileContent: combinedFiles,
    };

    fetch('/fileupload', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res) {
          if (res.ok === false) {
            this.setState({
              isUploading: false,
              showUploadModal: false,
              successMessage: '',
              errorMessage: 'Error (Check for Secured PDFs)!',
            });
          } else {
            // notify for successful upload
            this.setState({
              isUploading: false,
              showUploadModal: false,
              fileArr: [],
              successMessage: 'File(s) Successfully Uploaded!',
            });
            this.afterUpload();
          }
        }
      })
      .catch(e => this.setState({ errorMessage: 'Error Uploading File!' }));
  }

  async afterUpload() {
    const response = axios.get('/afterconditionupload', {
      params: {
        pFileID: this.props.match.params.id,
        pConditionID: this.state.conditionID,
      },
    });
    var resultData = (await response).data;

    // check for errors
    if (!resultData) this.setState({ errorMessage: 'Error Uploading Files!' });

    // update submitted date on the condition we just uploaded for.
    for (var j = 0; j < this.state.conditionList.length; j++) {
      if (
        this.state.conditionList[j].conditionNumber === this.state.conditionNum
      ) {
        this.state.conditionList[j].submittedDate = todaysDate();
      }
    }
    this.setState({ showUploadModal: false, fileArr: [] });
  }

  handleChangeStatus({ file, remove }, status) {
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        this.state.fileArr.push({
          fileUIntArr: uintarr,
          fileName: '(' + this.state.conditionNum + ') ' + file.name,
          conditionID: this.state.conditionID,
        });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  InputContent(files, extra) {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <div>
          <i
            className="bi bi-cloud-upload text-muted"
            style={{ fontSize: '4rem' }}
          ></i>
          <p className="mb-1 text-muted">
            Drag and Drop .PDF Files or Click Below to Upload
          </p>
          <small className="text-muted">
            (The System Will Only Accept Up to 25 Mb of Files at a Time)
          </small>
        </div>
      );
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  GenerateConditions(stage) {
    let conList = [];
    for (var i = 0; i < this.state.conditionList.length; i++) {
      if (
        this.state.conditionList[i].conditionStage === stage &&
        (this.state.showResolved ||
          this.state.conditionList[i].clearedDate === '---')
      )
        conList.push(this.state.conditionList[i]);
    }
    return (
      <div>
        <h5>{stage}</h5>
        {conList.length === 0 ? (
          <p style={{ paddingLeft: '25px' }}>No Conditions Found</p>
        ) : (
          <table
            style={{
              minWidth: '1200px',
              maxWidth: '1200px',
              border: '1px solid lightgray',
              marginLeft: '10px',
              marginBottom: '20px',
              borderCollapse: 'collapse',
            }}
          >
            <tr>
              <th className="text-center">Created</th>
              <th className="text-center">Duty</th>
              <th className="text-center">Code</th>
              <th className="text-left" style={{ paddingLeft: '5px' }}>
                Description
              </th>
              <th className="text-center">Uploaded</th>
              <th className="text-center">Received</th>
              <th className="text-center">Cleared</th>
              <th className="text-center">Upload</th>
            </tr>
            {conList.map(condition => {
              const formattedDescription =
                condition.description.charAt(0).toUpperCase() +
                condition.description.slice(1).toLowerCase();
              return (
                <tr
                  key={condition.description}
                  style={{ border: 'solid lightgray thin' }}
                >
                  <td className="text-center">
                    {condition.requestedDate.slice(0, 5)}
                  </td>
                  <td className="text-center">{condition.responsibleParty}</td>
                  <td className="text-center">{condition.conditionNumber}</td>
                  <td style={{ maxWidth: '400px', paddingLeft: '15px' }} expand>
                    <ToolTip tip={formattedDescription}>
                      {formattedDescription}
                    </ToolTip>
                  </td>
                  <td className="text-center">
                    {condition.submittedDate.slice(0, 5)}
                  </td>
                  <td className="text-center">
                    {condition.receivedDate.slice(0, 5)}
                  </td>
                  <td className="text-center">
                    {condition.clearedDate.slice(0, 5)}
                  </td>
                  <td
                    className="text-center"
                    onClick={() =>
                      this.setState({
                        showUploadModal: true,
                        conditionID: condition.conditionID,
                        conditionNum: condition.conditionNumber,
                        conditionDesc: condition.description,
                      })
                    }
                  >
                    <i
                      className="bi bi-upload"
                      style={{ fontSize: '1rem', cursor: 'pointer' }}
                    ></i>
                  </td>
                </tr>
              );
            })}
          </table>
        )}
      </div>
    );
  }

  ShowUploadModalContent() {
    return (
      <div>
        Condition #: {this.state.conditionNum}
        <br />
        {this.state.conditionDesc}
        <br />
        <br />
        <Dropzone
          maxFiles="10"
          maxSizeBytes="25000000" // 25 Mb
          accept=".pdf"
          onChangeStatus={this.handleChangeStatus.bind(this)}
          inputContent={this.InputContent.bind(this)}
          canCancel={false}
          styles={{
            overflow: 'hidden',
            dropzone: {
              textAlign: 'center',
              border: '2px dashed lightgray',
              height: '200px',
              overflow: 'hidden',
              borderRadius: '0.25rem',
            },
            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
            inputLabel: (files, extra) =>
              extra.reject ? { color: 'red' } : {},
          }}
        />
        <br />
        {this.state.fileArr.map(({ fileName }) => (
          <div>
            <img src="/images/arrow.png" width="10px" height="10px" alt="" />
            &nbsp;
            {fileName}
          </div>
        ))}
      </div>
    );
  }

  CheckAccess() {
    if (!this.state.retrievedData) {
      this.setState({ errorMessage: 'Access Failed' });
    }
    if (
      this.state.zipCodeInput &&
      (this.state.zipCodeInput.toString() === 'bypass' ||
        this.state.zipCode.toString() === this.state.zipCodeInput.toString())
    ) {
      this.setState({ loggedIn: true });
    } else {
      this.setState({ errorMessage: 'Zip Code Does Not Match!' });
    }
  }

  renderContent = () => {
    const { retrievedData } = this.state;
    if (!retrievedData) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    } else if (!this.state.conditionList) {
      return (
        <div className="row">
          <div className="text-center">
            <h3 className="text-muted">No conditions available</h3>
          </div>
          <div className="text-center">
            <img
              height="250"
              src="/images/empty_state.png"
              alt="no conditions available"
            />
          </div>
        </div>
      );
    }
    return (
      <>
        {/* {this.GenerateConditions('Prior To Close')} */}
        {this.GenerateConditions('TRID')}
        {this.GenerateConditions('Prior To Funding')}
      </>
    );
  };

  async downloadLoanFile(docID) {
    this.setState({ successMessage: 'Downloading File\nPlease Wait!' });
    const response = axios.get('/DownloadLoanFile', {
      params: {
        pFileID: this.props.match.params.id,
        pDocumentID: docID,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    // if we couldn't get a file back, abort
    if (!resultData) {
      alert('Error: File Not Found');
      return;
    }

    var binary = atob(resultData.documentData);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = resultData.documentName + '.' + resultData.documentExension;
    a.click();
    URL.revokeObjectURL(url);
    this.setState({ successMessage: '' });
  }

  zipKeyPressed = e => {
    if (e.charCode === 13) this.CheckAccess();
  };

  render() {
    return (
      <div className="bg-light vh-100 position-relative">
        <div className="border rounded-3 p-5 pt-4 bg-white shadow-lg position-absolute top-50 start-50 translate-middle text-center">
          <div className="text-end pb-1">
            <a href="/" className="btn btn-close" />
          </div>
          <div className="vstack gap-3">
            <h4>Escrow Conditions</h4>
            {this.state.loggedIn ? (
              <>
                {this.state.closingPackage && (
                  <div className="row">
                    {this.state.closingPackage.initialCDID ? (
                      <div className="col-12 col-md-4">
                        <button
                          className="btn btn-primary btn-lg w-100"
                          onClick={() =>
                            this.downloadLoanFile(
                              this.state.closingPackage.initialCDID
                            )
                          }
                        >
                          Download Initial CD
                          <i class="ms-2 bi bi-download"></i>
                        </button>
                      </div>
                    ) : null}
                    {this.state.closingPackage.closingPackageID ? (
                      <div className="col-12 col-md-4">
                        <button
                          className="btn btn-primary btn-lg w-100"
                          onClick={() =>
                            this.downloadLoanFile(
                              this.state.closingPackage.closingPackageID
                            )
                          }
                        >
                          Download Closing Package
                          <i class="ms-2 bi bi-download"></i>
                        </button>
                      </div>
                    ) : null}
                    {this.state.closingPackage.fundingFiguresID ? (
                      <div className="col-12 col-md-4">
                        <button
                          className="btn btn-primary btn-lg w-100"
                          onClick={() =>
                            this.downloadLoanFile(
                              this.state.closingPackage.fundingFiguresID
                            )
                          }
                        >
                          Download Funding Figures
                          <i class="ms-2 bi bi-download"></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
                <table
                  className="table"
                  style={{ maxWidth: '500px', mx: 'auto' }}
                >
                  <tbody className="text-start">
                    <tr>
                      <th scope="row">Loan #:</th>
                      <td>{this.props.match.params.id || '-'}</td>
                    </tr>
                    <tr>
                      <th scope="row">Escrow #:</th>
                      <td>{this.state.escrowNumber || '-'}</td>
                    </tr>
                    <tr>
                      <th scope="row">Borrower:</th>
                      <td>{this.state.borrowerName || '-'}</td>
                    </tr>
                    <tr>
                      <th scope="row">Address:</th>
                      <td>{this.state.address || '-'}</td>
                    </tr>
                  </tbody>
                </table>
                {this.renderContent()}
                <Modal
                  title="Upload Conditions"
                  show={this.state.showUploadModal}
                  onAcceptDisabled={
                    this.state.isUploading || this.state.fileArr.length === 0
                  }
                  onAccept={() => this.UploadConditions()}
                  onDecline={() =>
                    this.setState({ showUploadModal: false, fileArr: [] })
                  }
                  buttonMessage="Upload"
                >
                  {this.ShowUploadModalContent()}
                </Modal>
                {this.state.successMessage && (
                  <Toast
                    type="success"
                    title={this.state.successMessage}
                    onClose={() => this.setState({ successMessage: '' })}
                  />
                )}
                {this.state.errorMessage && (
                  <Toast
                    type="danger"
                    title={this.state.errorMessage}
                    onClose={() => this.setState({ errorMessage: '' })}
                  />
                )}
              </>
            ) : (
              <>
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Loan #:</th>
                      <td>{this.props.match.params.id}</td>
                    </tr>
                    <tr>
                      <th scope="row">Borrower:</th>
                      <td>{this.state.borrowerName}</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Please Enter the Subject Property Zip Code Below For Access
                </p>
                <input
                  className="form-control"
                  id="zipCodeInput"
                  name="zipCodeInput"
                  label="Subject Property Zip Code"
                  onChange={this.captureInput}
                  onKeyPress={this.zipKeyPressed}
                  value={this.state.zipCodeInput}
                />
                <button
                  className="btn btn-primary mt-2"
                  id="checkAccessBtn"
                  onClick={() => this.CheckAccess()}
                >
                  Submit
                </button>
                {this.state.errorMessage && (
                  <Toast
                    type="danger"
                    title={this.state.errorMessage}
                    onClose={() => this.setState({ errorMessage: '' })}
                  />
                )}
                <ModalWait
                  title="Uploading Files. Please Wait!"
                  show={this.state.isUploading}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
