export const DROPDOWN_VALUES = {
  type: {
    1: 'Conventional',
    2: 'VA',
    3: 'FHA',
    4: 'USDA',
    7: 'Other',
  },
  lienPosition: {
    1: '1st',
  },
  amortization: {
    1: 'Adjustable Rate Mortgage',
    5: 'Fixed',
  },
  propertyType: {
    1: 'SFR',
    2: 'Multi-Unit',
    3: 'Condo',
    4: 'PUD',
    5: 'Co-Op',
    7: 'H. Condo',
    8: 'Mfr. Home',
    9: 'D. Condo',
    10: 'Mfr. Home - Condo/PUD/Co-Op',
    11: 'Mfr. Home - MH Advantage',
  },
  occupancyType: {
    1: 'Primary',
    2: 'Second Home',
    D: 'Investment',
  },
  productType: {
    1: 'ARM',
    4: 'GEM',
    5: 'Fixed',
    6: 'GPM',
    13: 'Other',
  },
  loanProduct: {
    1: '30 Yr Fixed',
    2: '20 Yr Fixed',
    3: '15 Yr Fixed',
    4: '10 Yr Fixed',
    5: '3/1 ARM',
    6: '5/1 ARM',
    7: '7/1 ARM',
    8: '10/1 ARM',
  },
};

export const PRICING_OPTIONS = {
  lienPosition: [
    { label: '1st Lien', value: 1 },
    { label: '2nd Lien', value: 2 },
    { label: '1st and 2nd Lien', value: 3 },
  ],
  loanPurpose: [
    { label: 'PUR', value: 1 },
    { label: 'R/T', value: 2 },
    { label: 'C/O (Debt Consolidation)', value: 3 },
    { label: 'C/O (Home Improvement)', value: 4 },
    { label: 'C/O (Other)', value: 5 },
  ],
  propertyType: [
    { label: 'SFR', value: 1 },
    { label: 'Condo (Attached)', value: 2 },
    { label: 'Condo (Detached)', value: 3 },
    { label: 'PUD', value: 4 },
    { label: '2 Unit', value: 5 },
    { label: '3 Unit', value: 6 },
    { label: '4 Unit', value: 7 },
  ],
  occupancyType: [
    { label: 'Primary', value: 1 },
    { label: 'Second Home', value: 2 },
    { label: 'Investment', value: 3 },
  ],
  documentType: [
    { label: 'Full Doc', value: 1 },
    { label: 'Reduced Doc', value: 2 },
  ],
  foreignNational: [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 },
  ],
  incomeVerification: [
    { label: 'Written VOE', value: 1 },
    { label: 'P&L Statement (CPA Prepared)', value: 2 },
    { label: 'P&L Statement (Self Prepared)', value: 3 },
    { label: 'Bank Statements (MTGP2)', value: 4 },
    { label: '1099 Form (MTGP2)', value: 5 },
  ],
  monthsReserve: [
    { label: '2 Months', value: 1 },
    { label: '6 Months', value: 2 },
    { label: '12 Months', value: 3 },
  ],
  states: [{ label: 'California', value: 1 }],
  county: [
    { label: 'Alameda', value: 1 },
    { label: 'Alpine', value: 2 },
    { label: 'Amador', value: 3 },
    { label: 'Butte', value: 4 },
    { label: 'Calaveras', value: 5 },
    { label: 'Colusa', value: 6 },
    { label: 'Contra Costa', value: 7 },
    { label: 'Del Norte', value: 8 },
    { label: 'El Dorado', value: 9 },
    { label: 'Fresno', value: 10 },
    { label: 'Glenn', value: 11 },
    { label: 'Humboldt', value: 12 },
    { label: 'Imperial', value: 13 },
    { label: 'Inyo', value: 14 },
    { label: 'Kern', value: 15 },
    { label: 'Kings', value: 16 },
    { label: 'Lake', value: 17 },
    { label: 'Lassen', value: 18 },
    { label: 'Los Angeles', value: 19 },
    { label: 'Madera', value: 20 },
    { label: 'Marin', value: 21 },
    { label: 'Mariposa', value: 22 },
    { label: 'Mendocino', value: 23 },
    { label: 'Merced', value: 24 },
    { label: 'Modoc', value: 25 },
    { label: 'Mono', value: 26 },
    { label: 'Monterey', value: 27 },
    { label: 'Napa', value: 28 },
    { label: 'Nevada', value: 29 },
    { label: 'Orange', value: 30 },
    { label: 'Placer', value: 31 },
    { label: 'Plumas', value: 32 },
    { label: 'Riverside', value: 33 },
    { label: 'Sacramento', value: 34 },
    { label: 'San Benito', value: 35 },
    { label: 'San Bernardino', value: 36 },
    { label: 'San Diego', value: 37 },
    { label: 'San Francisco', value: 38 },
    { label: 'San Joaquin', value: 39 },
    { label: 'San Luis Obispo', value: 40 },
    { label: 'San Mateo', value: 41 },
    { label: 'Santa Barbara', value: 42 },
    { label: 'Santa Clara', value: 43 },
    { label: 'Santa Cruz', value: 44 },
    { label: 'Shasta', value: 45 },
    { label: 'Sierra', value: 46 },
    { label: 'Siskiyou', value: 47 },
    { label: 'Solano', value: 48 },
    { label: 'Sonoma', value: 49 },
    { label: 'Stanislaus', value: 50 },
    { label: 'Sutter', value: 51 },
    { label: 'Tehama', value: 52 },
    { label: 'Trinity', value: 53 },
    { label: 'Tulare', value: 54 },
    { label: 'Tuolumne', value: 55 },
    { label: 'Ventura', value: 56 },
    { label: 'Yolo', value: 57 },
    { label: 'Yuba', value: 58 },
  ],

  loanProduct: [
    { label: '30 Yr Fixed', value: 1 },
    { label: '20 Yr Fixed', value: 2 },
    { label: '15 Yr Fixed', value: 3 },
    { label: '10 Yr Fixed', value: 4 },
    { label: '3/1 ARM', value: 5 },
    { label: '5/1 ARM', value: 6 },
    { label: '7/1 ARM', value: 7 },
    { label: '10/1 ARM', value: 8 },
  ],
  loanProductPortfolio: [
    { label: '30 Yr Fixed', value: 9 },
    { label: '5/6M ARM', value: 10 },
    { label: '7/6M ARM', value: 11 },
  ],

  impound: [
    { label: 'No Impound', value: 1 },
    { label: 'Tax and Insurance', value: 2 },
    { label: 'Tax', value: 3 },
    { label: 'Insurance', value: 4 },
  ],
  pmi: [
    { label: 'No PMI', value: 1 },
    { label: 'Borrower Paid', value: 2 },
    { label: 'Lender Paid', value: 3 },
  ],
  eDisclosure: [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 },
  ],
  ausOnly: [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 },
  ],
  lockPeriod: [
    { label: '15 Days', value: 1 },
    { label: '30 Days', value: 2 },
  ],
  lockExtension: [
    { label: '3 Day Extension (0.125% Fee)', value: 1 },
    { label: '7 Day Extension (0.250% Fee)', value: 2 },
    { label: '10 Day Extension (0.375% Fee)', value: 3 },
  ],
  compPlan: [
    { label: 'Borrower Paid', value: 1 },
    { label: 'Tier 1: 1.000%', value: 2 },
    { label: 'Tier 2: 1.125%', value: 3 },
    { label: 'Tier 3: 1.250%', value: 4 },
    { label: 'Tier 4: 1.375%', value: 5 },
    { label: 'Tier 5: 1.500%', value: 6 },
    { label: 'Tier 6: 1.625%', value: 7 },
    { label: 'Tier 7: 1.750%', value: 8 },
    { label: 'Tier 8: 1.875%', value: 9 },
    { label: 'Tier 9: 2.000%', value: 10 },
    { label: 'Tier 10: 2.125%', value: 11 },
    { label: 'Tier 11: 2.250%', value: 12 },
    { label: 'Tier 12: 2.375%', value: 13 },
    { label: 'Tier 13: 2.500%', value: 14 },
  ],
  compensationTier: [
    { label: 'Tier 1: 0.500%', value: 1 },
    { label: 'Tier 2: 0.625%', value: 2 },
    { label: 'Tier 3: 0.750%', value: 3 },
    { label: 'Tier 4: 0.875%', value: 4 },
    { label: 'Tier 5: 1.000%', value: 5 },
    { label: 'Tier 6: 1.125%', value: 6 },
    { label: 'Tier 7: 1.250%', value: 7 },
    { label: 'Tier 8: 1.375%', value: 8 },
    { label: 'Tier 9: 1.500%', value: 9 },
    { label: 'Tier 10: 1.625%', value: 10 },
    { label: 'Tier 11: 1.750%', value: 11 },
    { label: 'Tier 12: 1.875%', value: 12 },
    { label: 'Tier 13: 2.000%', value: 13 },
  ],
  requestType: [
    { label: 'New Appraisal Inspection', value: 1 },
    { label: 'Form 2006/1033 Desk Review', value: 5 },
    { label: 'Form 1004D/442 Re-Inspection', value: 2 },
    { label: 'Form 1004D/442 Re-Certification', value: 3 },
    { label: 'Form 2000/1032 Field Review', value: 6 },
    { label: 'Form 2055/2055 Exterior Only', value: 4 },
  ],
  businessChannel: [
    { label: 'Retail', value: 1 },
    { label: 'Wholesale', value: 2 },
  ],
  marital: [
    { label: 'Married', value: 1 },
    { label: 'Separated', value: 2 },
    { label: 'Unmarried', value: 3 },
  ],
  ownership: [
    { label: 'Borrower Owns This Property', value: 1 },
    { label: 'Borrower Does Not Own This Property', value: 2 },
  ],
  cocReasons: [
    { label: 'Settlement', value: 1 },
    { label: 'Eligibility', value: 2 },
    { label: 'Consumer Request', value: 3 },
    { label: 'Rate Lock', value: 4 },
    { label: 'Expiration of LE', value: 5 },
    { label: 'New Construction', value: 6 },
    { label: 'No Reason Needed', value: 7 },
    { label: 'Not a Valid CIC', value: 8 },
  ],

  originationFee: [
    { label: '0.000%', value: 1 },
    { label: '0.250%', value: 2 },
    { label: '0.500%', value: 3 },
    { label: '0.750%', value: 4 },
    { label: '1.000%', value: 5 },
    { label: '1.250%', value: 6 },
    { label: '1.500%', value: 7 },
    { label: '1.750%', value: 8 },
    { label: '2.000%', value: 9 },
  ],

  matrixForms: [
    { label: 'MTG CA LOAN LIMITS', value: 1 },
    { label: 'MTG CONFORMING', value: 2 },
    { label: 'MTG HIGH BALANCE', value: 3 },
    { label: 'MTG PORTFOLIO 1', value: 4 },
    { label: 'MTG PORTFOLIO 1 CRA', value: 5 },
    { label: 'MTG PORTFOLIO 2', value: 6 },
  ],

  formFiles: [
    { label: 'MTG Loss Payee Info', value: 1 },
    { label: 'MTG Condo Cert/Questionnaire - Limited Review', value: 2 },
    { label: 'MTG Condo Cert/Questionnaire - Full Review', value: 3 },
    { label: 'Form 4506-C', value: 4 },
    { label: 'Form SSA-89', value: 5 },
    { label: "Borrower's Certification & Authorization", value: 6 },
    { label: 'Patriot Act Disclosure', value: 7 },
    { label: 'MTGP2 – Occupancy Certification', value: 8 },
    { label: 'MTGP2 – Borrower Contact Consent Form', value: 9 },

  ],

  prepayPenalty: [
    { label: 'No Prepay Penalty', value: 1 },
    { label: '1-Year Prepay Penalty', value: 2 },
    { label: '2-Year Prepay Penalty', value: 3 },
  ],
};

export const FEDERAL_LOAN_LIMIT = 484350;

export const STATUS_COLORS = {
  registered: '#ffe0b2',
  'in submission': '#ffe082',
  submitted: '#ffcc80',
  'in underwriting': '#ffb74d',
  approved: '#c5e1a5',
  'clear to close': '#aed581',
  'doc out': '#fff9c4',
  'doc in': '#fff9c4',
  'in review': '#fce4ec',
  reviewed: '#f8bbd0',
  'clear to fund': '#f48fb1',
  funded: '#f06292',
  'in shipping': '#bbdefb',
  shipped: '#90caf9',
  'in post-funding': '#64b5f6',
  closed: '#fafafa',
  purchased: '#fafafa',
  'in reconciling': '#fafafa',
  completed: '#fafafa',
  'on hold': '#ff7043',
  declined: '#cfd8dc',
  cancelled: '#cfd8dc',
  'aus completed': '#b2dfdb',
  'aus requested': '#80cbc4',
};

export const REVIEW_COLORS = {
  'Not Reviewed': 'warning',
  Approved: 'success',
  'Partial Approval': 'info',
  'Pending Approval': 'info',
  Denied: 'danger',
};

export const JWT_SECRET_KEY = 'mtglab_secret_key';

export const PIPELINE_STATUSES = [
  'Registered',
  'In Submission',
  'Submitted',
  'In Underwriting',
  'Approved',
  'Clear to Close',
  'Doc Out',
  'Doc In',
  'In Review',
  'Reviewed',
  'Clear to Fund',
  'Funded',
  'In Shipping',
  'Shipped',
  'In Post-Funding',
  'Closed',
  'Purchased',
  'In Reconciling',
  'Completed',
  'On Hold',
  'Declined',
  'Cancelled',
  'AUS Requested',
  'AUS Completed',
];

export const PIPELINE_MAIN_TABLE_HEADERS = [
  {
    displayText: 'Loan No.',
    dataText: 'fileDataID',
    state: 'isFileDataIDSortDesc',
  },
  {
    displayText: 'Status',
    dataText: 'loanStatus',
    state: 'isStatusSortDesc',
  },
  {
    displayText: 'Borrower',
    dataText: 'borrower',
    state: 'isBorrowerSortDesc',
  },
  {
    displayText: 'Loan Amount',
    dataText: 'loanAmount',
    state: 'isLoanAmountSortDesc',
  },
];

export const PIPELINE_ADVANCED_TABLE_HEADERS = [
  {
    displayText: 'Program',
    dataText: 'programCode',
    state: 'isProgramSortDesc',
  },
  {
    displayText: 'Purpose',
    dataText: 'loanPurpose',
    state: 'isLoanPurposeSortDesc',
  },
  {
    displayText: 'Registered',
    dataText: 'registeredDate',
    state: 'isRegisteredDateSortDesc',
  },
  {
    displayText: 'Submitted',
    dataText: 'submittedDate',
    state: 'isSubmittedDateSortDesc',
  },
  {
    displayText: 'Approved',
    dataText: 'approvedDate',
    state: 'isApprovedDateSortDesc',
  },
  {
    displayText: 'Funded',
    dataText: 'fundingDate',
    state: 'isFundingDateSortDesc',
  },
  {
    displayText: 'Lock Expires',
    dataText: 'lockExpirationDate',
    state: 'isLockExpirationDateSortDesc',
  },
  {
    displayText: 'EPO Expiration',
    dataText: 'epoExpirationDate',
    state: 'isEpoExpirationDateSortDesc',
  },
];

export const FILE_DETAILS_TABS = [
  {
    prefix: 'sub',
    prefixUpper: 'SUB',
    label: 'Submission',
  },
  {
    prefix: 'uwr',
    prefixUpper: 'UWR',
    label: 'Underwriting',
  },
  {
    prefix: 'qct',
    prefixUpper: 'QC',
    label: 'Quality Control',
  },
  {
    prefix: 'lck',
    prefixUpper: 'LCK',
    label: 'Lock'
  },
  {
    prefix: 'cmp',
    prefixUpper: 'CMP',
    label: 'Compliance',
  },
  {
    prefix: 'ctc',
    prefixUpper: 'CTC',
    label: 'Clear to Close',
  },
  {
    prefix: 'clo',
    prefixUpper: 'CLO',
    label: 'Closing'
  },
  {
    prefix: 'fnd',
    prefixUpper: 'FND',
    label: 'Funding',
  },
  {
    prefix: 'pfd',
    prefixUpper: 'PFD',
    label: 'Post Funding',
  },
  {
    prefix: 'srv',
    prefixUpper: 'SRV',
    label: 'Servicing',
  },
  {
    prefix: 'opr',
    prefixUpper: 'OP',
    label: 'Operations',
  },
  {
    prefix: 'apr',
    prefixUpper: 'APR',
    label: 'Appraisals',
  },
];

export const VALID_GEOCODES = [
  '06065030101',
  '06065030103',
  '06065030104',
  '06065030300',
  '06065030400',
  '06065030501',
  '06065030502',
  '06065030503',
  '06065030700',
  '06065030800',
  '06065030900',
  '06065031001',
  '06065031002',
  '06065031100',
  '06065031200',
  '06065031300',
  '06065031401',
  '06065031402',
  '06065031501',
  '06065031503',
  '06065031504',
  '06065031601',
  '06065031602',
  '06065031701',
  '06065031702',
  '06065031703',
  '06065031704',
  '06065040101',
  '06065040102',
  '06065040201',
  '06065040202',
  '06065040203',
  '06065040204',
  '06065040302',
  '06065040303',
  '06065040304',
  '06065040305',
  '06065040402',
  '06065040403',
  '06065040404',
  '06065040405',
  '06065040501',
  '06065040502',
  '06065040503',
  '06065040603',
  '06065040604',
  '06065040605',
  '06065040606',
  '06065040617',
  '06065040618',
  '06065040620',
  '06065040807',
  '06065040808',
  '06065040809',
  '06065040814',
  '06065040815',
  '06065040821',
  '06065040901',
  '06065040903',
  '06065040904',
  '06065041001',
  '06065041002',
  '06065041003',
  '06065041004',
  '06065041101',
  '06065041102',
  '06065041201',
  '06065041202',
  '06065041203',
  '06065041301',
  '06065041302',
  '06065041403',
  '06065041404',
  '06065041405',
  '06065041406',
  '06065041407',
  '06065041408',
  '06065041410',
  '06065041411',
  '06065041412',
  '06065041500',
  '06065041601',
  '06065041602',
  '06065041702',
  '06065041703',
  '06065041704',
  '06065041805',
  '06065041812',
  '06065041813',
  '06065041904',
  '06065041905',
  '06065041906',
  '06065041909',
  '06065042004',
  '06065042008',
  '06065042009',
  '06065042010',
  '06065042017',
  '06065042209',
  '06065042210',
  '06065042212',
  '06065042300',
  '06065042401',
  '06065042402',
  '06065042403',
  '06065042404',
  '06065042405',
  '06065042406',
  '06065042407',
  '06065042408',
  '06065042409',
  '06065042410',
  '06065042411',
  '06065042412',
  '06065042505',
  '06065042507',
  '06065042508',
  '06065042509',
  '06065042510',
  '06065042511',
  '06065042512',
  '06065042513',
  '06065042514',
  '06065042515',
  '06065042516',
  '06065042517',
  '06065042518',
  '06065042519',
  '06065042520',
  '06065042521',
  '06065042522',
  '06065042523',
  '06065042621',
  '06065042622',
  '06065042623',
  '06065042624',
  '06065042625',
  '06065042626',
  '06065042627',
  '06065042628',
  '06065042629',
  '06065042630',
  '06065042631',
  '06065042632',
  '06065042708',
  '06065042717',
  '06065042719',
  '06065042720',
  '06065042723',
  '06065042726',
  '06065042728',
  '06065042730',
  '06065042731',
  '06065042742',
  '06065042743',
  '06065042744',
  '06065042745',
  '06065042746',
  '06065042747',
  '06065042749',
  '06065042751',
  '06065042801',
  '06065042802',
  '06065042902',
  '06065042903',
  '06065042905',
  '06065042906',
  '06065042907',
  '06065042908',
  '06065043001',
  '06065043003',
  '06065043005',
  '06065043006',
  '06065043008',
  '06065043009',
  '06065043010',
  '06065043217',
  '06065043227',
  '06065043228',
  '06065043266',
  '06065043271',
  '06065043293',
  '06065043306',
  '06065043308',
  '06065043309',
  '06065043310',
  '06065043312',
  '06065043313',
  '06065043318',
  '06065043319',
  '06065043401',
  '06065043403',
  '06065043405',
  '06065043506',
  '06065043509',
  '06065043512',
  '06065043513',
  '06065043517',
  '06065043518',
  '06065043520',
  '06065043521',
  '06065043522',
  '06065043523',
  '06065043601',
  '06065043602',
  '06065043703',
  '06065043807',
  '06065043820',
  '06065043821',
  '06065043823',
  '06065043900',
  '06065044000',
  '06065044101',
  '06065044102',
  '06065044104',
  '06065044200',
  '06065044300',
  '06065044505',
  '06065044507',
  '06065044509',
  '06065044510',
  '06065044515',
  '06065044516',
  '06065044518',
  '06065044520',
  '06065044521',
  '06065044524',
  '06065044605',
  '06065044702',
  '06065044904',
  '06065044907',
  '06065044916',
  '06065044924',
  '06065044925',
  '06065044926',
  '06065044931',
  '06065044932',
  '06065044933',
  '06065044934',
  '06065045109',
  '06065045110',
  '06065045120',
  '06065045126',
  '06065045207',
  '06065045209',
  '06065045213',
  '06065045217',
  '06065045224',
  '06065045226',
  '06065045228',
  '06065045233',
  '06065045302',
  '06065045303',
  '06065045305',
  '06065045306',
  '06065045501',
  '06065045502',
  '06065045610',
  '06065045611',
  '06065045612',
  '06065045615',
  '06065045616',
  '06065045618',
  '06065045619',
  '06065045703',
  '06065045704',
  '06065045706',
  '06065045707',
  '06065045708',
  '06065045709',
  '06065045900',
  '06065046101',
  '06065046102',
  '06065046103',
  '06065046200',
  '06065046401',
  '06065046402',
  '06065046403',
  '06065046404',
  '06065046502',
  '06065046601',
  '06065046700',
  '06065046800',
  '06065046900',
  '06065048200',
  '06065048300',
  '06065048700',
  '06065048800',
  '06065048901',
  '06065048902',
  '06065049001',
  '06065049002',
  '06065049101',
  '06065049400',
  '06065049501',
  '06065049502',
  '06065050401',
  '06065050901',
  '06065051100',
  '06065051301',
  '06065940400',
  '06065941500',
  '06065981000',
  '06071000119',
  '06071000201',
  '06071000203',
  '06071000205',
  '06071000207',
  '06071000208',
  '06071000304',
  '06071000305',
  '06071000306',
  '06071000307',
  '06071000308',
  '06071000401',
  '06071000403',
  '06071000404',
  '06071000501',
  '06071000503',
  '06071000504',
  '06071000603',
  '06071000604',
  '06071000605',
  '06071000606',
  '06071000808',
  '06071000821',
  '06071000823',
  '06071000824',
  '06071000825',
  '06071000826',
  '06071000901',
  '06071000903',
  '06071000904',
  '06071001001',
  '06071001002',
  '06071001101',
  '06071001103',
  '06071001104',
  '06071001200',
  '06071001305',
  '06071001307',
  '06071001308',
  '06071001309',
  '06071001310',
  '06071001312',
  '06071001400',
  '06071001501',
  '06071001503',
  '06071001504',
  '06071001600',
  '06071001702',
  '06071001703',
  '06071001704',
  '06071001706',
  '06071001707',
  '06071001803',
  '06071001804',
  '06071001806',
  '06071001808',
  '06071001809',
  '06071001810',
  '06071001812',
  '06071001813',
  '06071001901',
  '06071001906',
  '06071001910',
  '06071001911',
  '06071002023',
  '06071002025',
  '06071002028',
  '06071002035',
  '06071002037',
  '06071002038',
  '06071002040',
  '06071002044',
  '06071002047',
  '06071002049',
  '06071002050',
  '06071002051',
  '06071002101',
  '06071002103',
  '06071002105',
  '06071002107',
  '06071002109',
  '06071002111',
  '06071002112',
  '06071002204',
  '06071002206',
  '06071002207',
  '06071002306',
  '06071002307',
  '06071002308',
  '06071002309',
  '06071002310',
  '06071002311',
  '06071002312',
  '06071002313',
  '06071002314',
  '06071002315',
  '06071002403',
  '06071002404',
  '06071002405',
  '06071002406',
  '06071002501',
  '06071002503',
  '06071002504',
  '06071002602',
  '06071002604',
  '06071002606',
  '06071002608',
  '06071002609',
  '06071002610',
  '06071002611',
  '06071002703',
  '06071002705',
  '06071002706',
  '06071002707',
  '06071002708',
  '06071002709',
  '06071002801',
  '06071002803',
  '06071002804',
  '06071002901',
  '06071002902',
  '06071003000',
  '06071003101',
  '06071003102',
  '06071003201',
  '06071003202',
  '06071003301',
  '06071003302',
  '06071003401',
  '06071003403',
  '06071003404',
  '06071003405',
  '06071003503',
  '06071003505',
  '06071003506',
  '06071003507',
  '06071003509',
  '06071003510',
  '06071003603',
  '06071003605',
  '06071003606',
  '06071003607',
  '06071003609',
  '06071003611',
  '06071003612',
  '06071003700',
  '06071003801',
  '06071003803',
  '06071003804',
  '06071003900',
  '06071004001',
  '06071004003',
  '06071004004',
  '06071004101',
  '06071004103',
  '06071004104',
  '06071004201',
  '06071004202',
  '06071004301',
  '06071004302',
  '06071004401',
  '06071004403',
  '06071004404',
  '06071004505',
  '06071004507',
  '06071004509',
  '06071004510',
  '06071004511',
  '06071004512',
  '06071004601',
  '06071004603',
  '06071004604',
  '06071004700',
  '06071004800',
  '06071004901',
  '06071004902',
  '06071005101',
  '06071005102',
  '06071005200',
  '06071005300',
  '06071005400',
  '06071005501',
  '06071005502',
  '06071005601',
  '06071005602',
  '06071005701',
  '06071005800',
  '06071006101',
  '06071006102',
  '06071006201',
  '06071006203',
  '06071006204',
  '06071006301',
  '06071006303',
  '06071006304',
  '06071006401',
  '06071006402',
  '06071006501',
  '06071006502',
  '06071006601',
  '06071006603',
  '06071006604',
  '06071006700',
  '06071007001',
  '06071007002',
  '06071007106',
  '06071007107',
  '06071007108',
  '06071007110',
  '06071007111',
  '06071007112',
  '06071007201',
  '06071007202',
  '06071007303',
  '06071007404',
  '06071007407',
  '06071007408',
  '06071007409',
  '06071007410',
  '06071007412',
  '06071007603',
  '06071007604',
  '06071007605',
  '06071007606',
  '06071007904',
  '06071007905',
  '06071008003',
  '06071008004',
  '06071008100',
  '06071008404',
  '06071008405',
  '06071008710',
  '06071008800',
  '06071009119',
  '06071009122',
  '06071009123',
  '06071009124',
  '06071009125',
  '06071009126',
  '06071009127',
  '06071009128',
  '06071009129',
  '06071009130',
  '06071009131',
  '06071009132',
  '06071009133',
  '06071009135',
  '06071009136',
  '06071009137',
  '06071009138',
  '06071009139',
  '06071009400',
  '06071009501',
  '06071009502',
  '06071009709',
  '06071009712',
  '06071009713',
  '06071009714',
  '06071009720',
  '06071009721',
  '06071009727',
  '06071009800',
  '06071009906',
  '06071009908',
  '06071009910',
  '06071009911',
  '06071009912',
  '06071009913',
  '06071009914',
  '06071009915',
  '06071009916',
  '06071009917',
  '06071009918',
  '06071010009',
  '06071010010',
  '06071010011',
  '06071010012',
  '06071010014',
  '06071010015',
  '06071010016',
  '06071010019',
  '06071010022',
  '06071010023',
  '06071010028',
  '06071010029',
  '06071010030',
  '06071010031',
  '06071010032',
  '06071010033',
  '06071010034',
  '06071010035',
  '06071010036',
  '06071010037',
  '06071010038',
  '06071010041',
  '06071010042',
  '06071010433',
  '06071011004',
  '06071011700',
  '06071011801',
  '06071011802',
  '06071012001',
  '06071012002',
  '06071012106',
  '06071012201',
  '06071012202',
  '06071012300',
  '06071012400',
  '06071012500',
  '06071012700',
  '06071980200',
];
