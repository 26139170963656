import React, { Component } from 'react';
import axios from 'axios';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import LoginState from '../LoginState';
import { base64ArrayBuffer } from '../../helpers/utils';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9800;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  z-index: 9900;
  width: ${p => (p.width ? p.width : '700px')};
  min-height: ${p => (p.height ? p.height : '500px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h6`
  margin: 0;
  color: red;
`;

const ModalBody = styled.div`
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

const ExtraModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9990;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ExtraModalCard = styled.div`
  padding: 1rem;
  z-index: 9999;
  width: ${p => (p.width ? p.width : '700px')};
  min-height: ${p => (p.height ? p.height : '200px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const EmailTextField = styled.input`
  width: 200px;
  text-align: left;
  padding-left: 3px;
  border: 1px solid lightgray;
`;

const HiddenSelect = ({
  name,
  label,
  options = [],
  error,
  value,
  onChange,
  ...rest
}) => (
  <select
    id={name}
    name={name}
    value={value || -1}
    {...rest}
    invalid={error}
    onChange={e => onChange(e)}
    style={{border: 'none', maxHeight:'20px', maxWidth: '18px', margin: '0px', padding: '0px', backgroundColor: 'transparent'}}
  >
    <option key="placeholder" disabled="disabled" value="-1"></option>
    {options.map((option, index) => (
      <option
        key={`${option.label}-${option.value}-${index}`}
        value={option.value}
      >
        {option.label}
      </option>
    ))}
  </select>
);

export default class ModalEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExtraWrapper: false,
      extraContentType: '',
      toList: [],
      ccList: [],
      emailFrom: '',
      emailSubject: '',
      emailContent: '',
      emailExtraText: '',
      errorMsg: '',
      errorMessage: '',

      borrowers: [],
      loanOfficer: { name: '---', email: '---' },
      loanProcessor: { name: '---', email: '---' },
      loanProcessor2: { name: '---', email: '---' },
      underwriter1: { name: '---', email: '---' },
      underwriter2: { name: '---', email: '---' },
      accountExecutive: { name: '---', email: '---' },
      settlementCompany: { name: '---', email: '---' },
      titleCompany: { name: '---', email: '---' },
      buyersAgent: { name: '---', email: '---' },
      sellersAgent: { name: '---', email: '---' },
      amc: { name: 'PARK, INSUN', email: 'ipark@1stopamc.com'},
      appraiser: { name: '---', email: '---' },
      lpContacts: [],

      loanOfficerCB: false,
      loanOfficerCCCB: false,
      loanProcessorCB: false,
      loanProcessorCCCB: false,
      loanProcessor2CB: false,
      loanProcessor2CCCB: false,
      underwriter1CB: false,
      underwriter1CCCB: false,
      underwriter2CB: false,
      underwriter2CCCB: false,
      accountExecutiveCB: false,
      accountExecutiveCCCB: false,
      settlementCB: false,
      settlementCCCB: false,
      titleCB: false,
      titleCCCB: false,
      buyerCB: false,
      buyerCCCB: false,
      sellerCB: false,
      sellerCCCB: false,
      amcCB: false,
      amcCCCB: false,
      appraiserCB: false,
      appraiserCCCB: false,
      borrower0CB: false,
      borrower0CCCB: false,
      borrower1CB: false,
      borrower1CCCB: false,
      borrower2CB: false,
      borrower2CCCB: false,
      custom1CB: false,
      custom1CCCB: false,
      custom2CB: false,
      custom2CCCB: false,
      custom3CB: false,
      custom3CCCB: false,
      custom1: '',
      custom2: '',
      custom3: '',
      custom1error: '',
      custom2error: '',
      custom3error: '',
      optionalText: '',
      fileArr: []
    };

    this.getContactInfo(this.props.fileID);
  }

  async getContactInfo(fileID) {
    const response = axios.get('/getContactInfo', {
      params: {
        pFileID: fileID,
      },
    });
    // the response.data will contain the values returned
    var resultData = (await response).data;
    try {
      var formattedContacts = [];
      for (var i = 0; i < resultData.lpContacts.length; i++) {
        formattedContacts.push({
          label:
            resultData.lpContacts[i].email +
            ' (' +
            resultData.lpContacts[i].name +
            ')',
          value: resultData.lpContacts[i].email,
        });
      }

      this.setState({
        searchQuery: fileID + ' (' + resultData.borrowers[0].name + ')',
        borrowers: resultData.borrowers,
        loanOfficer: resultData.lo,
        loanProcessor: resultData.lp,
        loanProcessor2: resultData.lp2,
        loanProcessorCB: resultData.lp.email !== '---',
        loanProcessor2CB: resultData.lp2.email !== '---',
        underwriter1: resultData.uw1,
        underwriter2: resultData.uw2,
        accountExecutive: resultData.ae,
        accountExecutiveCCCB: resultData.ae.email !== '---',
        settlementCompany: resultData.sc,
        titleCompany: resultData.tc,
        buyersAgent: resultData.ba,
        sellersAgent: resultData.sa,
        appraiser: resultData.ap,
        lpContacts: formattedContacts,
        escrowNo: resultData.escrowNo,
        titleNo: resultData.titleNo,
        investorNo: resultData.investorNo,
        fetching: false,
        fileArr: []
      });
    }
    catch {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.template !== this.props.template) {
      // if I don't copy this list like this, the template also gets
      // overwritten with changes I make to add/remove items here.
      var i;
      var toList = [];
      var ccList = [];
      var customTo = [];
      var customCc = [];

      if (this.props.template.emailTo) {
        for (i = 0; i < this.props.template.emailTo.length; i++) {
          if (this.props.template.emailTo[i].indexOf('@') >= 0)
            customTo.push(this.props.template.emailTo[i]);
          else toList.push(this.props.template.emailTo[i]);
        }
      }
      if (this.props.template.emailCc) {
        for (i = 0; i < this.props.template.emailCc.length; i++) {
          if (this.props.template.emailCc[i].indexOf('@') >= 0)
            customCc.push(this.props.template.emailCc[i]);
          else ccList.push(this.props.template.emailCc[i]);
        }
      }

      this.setState({
        toList: toList,
        ccList: ccList,
        emailFrom: this.props.template.fromEmail || '',
        emailSubject: this.props.template.subject || '',
        emailExtraText: '',

        loanOfficerCB:
          toList.indexOf('Loan Officer') >= 0 &&
          this.state.loanOfficer.email !== '---',
        loanOfficerCCCB:
          ccList.indexOf('Loan Officer') >= 0 &&
          this.state.loanOfficer.email !== '---',
        loanProcessorCB:
          toList.indexOf('Loan Processor 1') >= 0 &&
          this.state.loanProcessor.email !== '---',
        loanProcessorCCCB:
          ccList.indexOf('Loan Processor 1') >= 0 &&
          this.state.loanProcessor.email !== '---',
        loanProcessor2CB:
          toList.indexOf('Loan Processor 2') >= 0 &&
          this.state.loanProcessor2.email !== '---',
        loanProcessor2CCCB:
          ccList.indexOf('Loan Processor 2') >= 0 &&
          this.state.loanProcessor2.email !== '---',
        underwriter1CB:
          toList.indexOf('Underwriter 1') >= 0 &&
          this.state.underwriter1.email !== '---',
        underwriter1CCCB:
          ccList.indexOf('Underwriter 1') >= 0 &&
          this.state.underwriter1.email !== '---',
        underwriter2CB:
          toList.indexOf('Underwriter 2') >= 0 &&
          this.state.underwriter2.email !== '---',
        underwriter2CCCB:
          ccList.indexOf('Underwriter 2') >= 0 &&
          this.state.underwriter2.email !== '---',
        accountExecutiveCB:
          toList.indexOf('Account Executive') >= 0 &&
          this.state.accountExecutive.email !== '---',
        accountExecutiveCCCB:
          ccList.indexOf('Account Executive') >= 0 &&
          this.state.accountExecutive.email !== '---',
        settlementCB:
          toList.indexOf('Settlement Company') >= 0 &&
          this.state.settlementCompany.email !== '---',
        settlementCCCB:
          ccList.indexOf('Settlement Company') >= 0 &&
          this.state.settlementCompany.email !== '---',
        titleCB:
          toList.indexOf('Title Company') >= 0 &&
          this.state.titleCompany.email !== '---',
        titleCCCB:
          ccList.indexOf('Title Company') >= 0 &&
          this.state.titleCompany.email !== '---',
        buyerCB:
          toList.indexOf("Buyer's Agent") >= 0 &&
          this.state.buyerAgent.email !== '---',
        buyerCCCB:
          ccList.indexOf("Buyer's Agent") >= 0 &&
          this.state.buyerAgent.email !== '---',
        sellerCB:
          toList.indexOf("Seller's Agent") >= 0 &&
          this.state.sellerAgent.email !== '---',
        sellerCCCB:
          ccList.indexOf("Seller's Agent") >= 0 &&
          this.state.sellerAgent.email !== '---',
        amcCB:
          toList.indexOf('AMC') >= 0 &&
          this.state.appraiser.email !== '---',
        amcCCCB:
          ccList.indexOf('AMC') >= 0 &&
          this.state.appraiser.email !== '---',
        appraiserCB:
          toList.indexOf('Appraiser') >= 0 &&
          this.state.appraiser.email !== '---',
        appraiserCCCB:
          ccList.indexOf('Appraiser') >= 0 &&
          this.state.appraiser.email !== '---',
        borrower0CB:
          toList.indexOf('Borrower #1') >= 0 &&
          this.state.borrowers.length > 0 &&
          this.state.borrowers[0].email !== '---',
        borrower0CCCB:
          ccList.indexOf('Borrower #1') >= 0 &&
          this.state.borrowers.length > 0 &&
          this.state.borrowers[0].email !== '---',
        borrower1CB:
          toList.indexOf('Borrower #2') >= 0 &&
          this.state.borrowers.length > 1 &&
          this.state.borrowers[1].email !== '---',
        borrower1CCCB:
          ccList.indexOf('Borrower #2') >= 0 &&
          this.state.borrowers.length > 1 &&
          this.state.borrowers[1].email !== '---',
        borrower2CB:
          toList.indexOf('Borrower #3') >= 0 &&
          this.state.borrowers.length > 2 &&
          this.state.borrowers[2].email !== '---',
        borrower2CCCB:
          ccList.indexOf('Borrower #3') >= 0 &&
          this.state.borrowers.length > 2 &&
          this.state.borrowers[2].email !== '---',
        custom1CB: customTo.length >= 1,
        custom1CCCB: customTo.length === 0 && customCc.length >= 1,
        custom2CB: customTo.length >= 2,
        custom2CCCB:
          customTo.length < 2 && customTo.length + customCc.length >= 2,
        custom3CB: customTo.length >= 3,
        custom3CCCB:
          customTo.length < 3 && customTo.length + customCc.length >= 3,
        custom1:
          customTo.length >= 1
            ? customTo[0]
            : customCc.length >= 1
            ? customCc[0]
            : '',
        custom2:
          customTo.length + customCc.length >= 2
            ? customTo.length >= 2
              ? customTo[1]
              : customCc[1 - customTo.length]
            : '',
        custom3:
          customTo.length + customCc.length >= 3
            ? customTo.length >= 3
              ? customTo[2]
              : customCc[2 - customTo.length]
            : '',
        custom1error: '',
        custom2error: '',
        custom3error: '',
        optionalText: '',
        contentType: this.props.template.content || '',
        errorMessage: '',
        fileArr: []
      });
      this.getEmailParameters(this.props.template.content || '');
    }

    if(prevProps.fileID !== this.props.fileID) 
      this.getContactInfo(this.props.fileID);
  }

  clearEmails() {
    this.setState({
      loanOfficerCB: false,
      loanOfficerCCCB: false,
      loanProcessorCB: false,
      loanProcessorCCCB: false,
      loanProcessor2CB: false,
      loanProcessor2CCCB: false,
      underwriter1CB: false,
      underwriter1CCCB: false,
      underwriter2CB: false,
      underwriter2CCCB: false,
      accountExecutiveCB: false,
      accountExecutiveCCCB: false,
      settlementCB: false,
      settlementCCCB: false,
      titleCB: false,
      titleCCCB: false,
      buyerCB: false,
      buyerCCCB: false,
      sellerCB: false,
      sellerCCCB: false,
      amcCB: false,
      amcCCCB: false,
      appraiserCB: false,
      appraiserCCCB: false,
      borrower0CB: false,
      borrower0CCCB: false,
      borrower1CB: false,
      borrower1CCCB: false,
      borrower2CB: false,
      borrower2CCCB: false,
      custom1CB: false,
      custom1CCCB: false,
      custom2CB: false,
      custom2CCCB: false,
      custom3CB: false,
      custom3CCCB: false,
      custom1: '',
      custom2: '',
      custom3: '',
      custom1error: '',
      custom2error: '',
      custom3error: ''
    });
  }

  async getEmailParameters(contentType) {
    const response = axios.get('/getEmailParameters', {
      params: {
        pFileID: this.props.fileID,
        pContentType: contentType,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    var optionalParameters = [];
    if (resultData) {
      resultData.forEach(parameter => {
        optionalParameters.push(parameter);
      });
    }
    this.setState({ modalContentParameters: optionalParameters });
  }

  getExtraText() {
    return (
      <span>
        {this.state.emailExtraText && (
          <span style={{ whiteSpace: 'pre-line' }}>
            {this.state.emailExtraText}
          </span>
        )}
        <button
          className="btn"
          type="button"
          style={{ height: '16px', maxWidth: '16px' }}
          onClick={() =>
            this.setState({
              showExtraWrapper: true,
              extraContentType: 'EditExtraText',
              tempExtraText: this.state.emailExtraText,
            })
          }
        >
          <i
            className="bi bi-pencil-square"
            style={{
              color: 'red',
              fontSize: '14px',
              position: 'relative',
              top: '-14px',
              left: '-7px',
            }}
          />
        </button>
      </span>
    );
  }

  getErrorIcon() {
    return (
      <i
        className="bi bi-exclamation-triangle text-red ml-2"
        style={{
          position: 'relative',
          top: '-3px',
        }}
      ></i>
    );
  }

  captureInput = e => {
    if (
      e.target.name === 'custom1' ||
      e.target.name === 'custom2' ||
      e.target.name === 'custom3'
    ) {
      // validate email here
      const emailValidate =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (e.target.name === 'custom1')
        this.setState({
          custom1error:
            e.target.value.length < 5 ||
            emailValidate.test(String(e.target.value).toLowerCase())
              ? ''
              : this.getErrorIcon(),
          custom1CB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom1CB,
          custom1CCCB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom1CCCB,
        });
      else if (e.target.name === 'custom2')
        this.setState({
          custom2error:
            e.target.value.length < 5 ||
            emailValidate.test(String(e.target.value).toLowerCase())
              ? ''
              : this.getErrorIcon(),
          custom2CB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom2CB,
          custom2CCCB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom2CCCB,
        });
      else if (e.target.name === 'custom3')
        this.setState({
          custom3error:
            e.target.value.length < 5 ||
            emailValidate.test(String(e.target.value).toLowerCase())
              ? ''
              : this.getErrorIcon(),
          custom3CB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom3CB,
          custom3CCCB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom3CCCB,
        });
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  checkBoxClicked(targetName) {
    var checkedTest = this.state[targetName] ? this.state[targetName] : false;
    this.setState({ [targetName]: !checkedTest });

    // if we are checking (true), then uncheck the corresponding to/cc field
    if (checkedTest === false) {
      var otherTarget = targetName.endsWith('CCCB')
        ? targetName.substr(0, targetName.length - 4) + 'CB'
        : targetName.substr(0, targetName.length - 2) + 'CCCB';
      this.setState({ [otherTarget]: false });
    }
  }

  updateEmailField = e => {
    var fieldNum = e.target.name.substr(e.target.name.length - 1);
    this.setState({
      ['custom' + fieldNum]: e.target.value,
      ['custom' + fieldNum + 'error']: '',
      ['custom' + fieldNum + 'CB']: false,
      ['custom' + fieldNum + 'CCCB']: false,
    });
  };

  getSendAddresses() {
    var sendTo = '';
    for (var i = 0; i < this.state.borrowers.length; i++) {
      if (
        this.state['borrower' + i + 'CB'] &&
        sendTo.indexOf(this.state.borrowers[i].email) === -1
      )
        sendTo += this.state.borrowers[i].email + '; ';
    }

    if (
      this.state.loanOfficerCB &&
      sendTo.indexOf(this.state.loanOfficer.email) === -1
    )
      sendTo += this.state.loanOfficer.email + '; ';
    if (
      this.state.loanProcessorCB &&
      sendTo.indexOf(this.state.loanProcessor.email) === -1
    )
      sendTo += this.state.loanProcessor.email + '; ';
    if (
      this.state.loanProcessor2CB &&
      sendTo.indexOf(this.state.loanProcessor2.email) === -1
    )
      sendTo += this.state.loanProcessor2.email + '; ';
    if (
      this.state.underwriter1CB &&
      sendTo.indexOf(this.state.underwriter1.email) === -1
    )
      sendTo += this.state.underwriter1.email + '; ';
    if (
      this.state.underwriter2CB &&
      sendTo.indexOf(this.state.underwriter2.email) === -1
    )
      sendTo += this.state.underwriter2.email + '; ';
    if (
      this.state.accountExecutiveCB &&
      sendTo.indexOf(this.state.accountExecutive.email) === -1
    )
      sendTo += this.state.accountExecutive.email + '; ';
    if (
      this.state.settlementCB &&
      sendTo.indexOf(this.state.settlementCompany.email) === -1
    )
      sendTo += this.state.settlementCompany.email + '; ';
    if (
      this.state.titleCB &&
      sendTo.indexOf(this.state.titleCompany.email) === -1
    )
      sendTo += this.state.titleCompany.email + '; ';
    if (
      this.state.buyerCB &&
      sendTo.indexOf(this.state.buyersAgent.email) === -1
    )
      sendTo += this.state.buyersAgent.email + '; ';
    if (
      this.state.sellerCB &&
      sendTo.indexOf(this.state.sellersAgent.email) === -1
    )
      sendTo += this.state.sellersAgent.email + '; ';
    if (
      this.state.amcCB &&
      sendTo.indexOf(this.state.amc.email) === -1
    )
      sendTo += this.state.amc.email + '; ';
    if (
      this.state.appraiserCB &&
      sendTo.indexOf(this.state.appraiser.email) === -1
    )
      sendTo += this.state.appraiser.email + '; ';
    if (this.state.custom1CB && sendTo.indexOf(this.state.custom1.email) === -1)
      sendTo += this.state.custom1 + '; ';
    if (this.state.custom2CB && sendTo.indexOf(this.state.custom2.email) === -1)
      sendTo += this.state.custom2 + '; ';
    if (this.state.custom3CB && sendTo.indexOf(this.state.custom3.email) === -1)
      sendTo += this.state.custom3 + '; ';

    return sendTo.substr(0, sendTo.length - 2);
  }

  getCCAddresses() {
    var sendTo = '';
    for (var i = 0; i < this.state.borrowers.length; i++) {
      if (
        this.state['borrower' + i + 'CCCB'] &&
        sendTo.indexOf(this.state.borrowers[i].email) === -1
      )
        sendTo += this.state.borrowers[i].email + '; ';
    }

    if (
      this.state.loanOfficerCCCB &&
      sendTo.indexOf(this.state.loanOfficer.email) === -1
    )
      sendTo += this.state.loanOfficer.email + '; ';
    if (
      this.state.loanProcessorCCCB &&
      sendTo.indexOf(this.state.loanProcessor.email) === -1
    )
      sendTo += this.state.loanProcessor.email + '; ';
    if (
      this.state.loanProcessor2CCCB &&
      sendTo.indexOf(this.state.loanProcessor2.email) === -1
    )
      sendTo += this.state.loanProcessor2.email + '; ';
    if (
      this.state.underwriter1CCCB &&
      sendTo.indexOf(this.state.underwriter1.email) === -1
    )
      sendTo += this.state.underwriter1.email + '; ';
    if (
      this.state.underwriter2CCCB &&
      sendTo.indexOf(this.state.underwriter2.email) === -1
    )
      sendTo += this.state.underwriter2.email + '; ';
    if (
      this.state.accountExecutiveCCCB &&
      sendTo.indexOf(this.state.accountExecutive.email) === -1
    )
      sendTo += this.state.accountExecutive.email + '; ';
    if (
      this.state.settlementCCCB &&
      sendTo.indexOf(this.state.settlementCompany.email) === -1
    )
      sendTo += this.state.settlementCompany.email + '; ';
    if (
      this.state.titleCCCB &&
      sendTo.indexOf(this.state.titleCompany.email) === -1
    )
      sendTo += this.state.titleCompany.email + '; ';
    if (
      this.state.buyerCCCB &&
      sendTo.indexOf(this.state.buyersAgent.email) === -1
    )
      sendTo += this.state.buyersAgent.email + '; ';
    if (
      this.state.sellerCCCB &&
      sendTo.indexOf(this.state.sellersAgent.email) === -1
    )
      sendTo += this.state.sellersAgent.email + '; ';
    if (
      this.state.amcCCCB &&
      sendTo.indexOf(this.state.amc.email) === -1
    )
      sendTo += this.state.amc.email + '; ';
    if (
      this.state.appraiserCCCB &&
      sendTo.indexOf(this.state.appraiser.email) === -1
    )
      sendTo += this.state.appraiser.email + '; ';
    if (
      this.state.custom1CCCB &&
      sendTo.indexOf(this.state.custom1.email) === -1
    )
      sendTo += this.state.custom1 + '; ';
    if (
      this.state.custom2CCCB &&
      sendTo.indexOf(this.state.custom2.email) === -1
    )
      sendTo += this.state.custom2 + '; ';
    if (
      this.state.custom3CCCB &&
      sendTo.indexOf(this.state.custom3.email) === -1
    )
      sendTo += this.state.custom3 + '; ';

    return sendTo.substr(0, sendTo.length - 2);
  }

  getEmailToList() {
    var emails = this.getSendAddresses();
    return emails.split(';');
  }

  getEmailCcList() {
    var emails = this.getCCAddresses();
    return emails.split(';');
  }

  validateSubject() {
    if (!this.state.tempSubject) {
      this.setState({ errorMsg: '- Email Subject Cannot Be Empty -' });
      return false;
    }
    if (this.state.tempSubject.length < 5) {
      this.setState({ errorMsg: '- Email Subject Is Too Short -' });
      return false;
    }
    this.setState({ errorMsg: '' });
    return true;
  }

  editEmailSubject() {
    if (this.validateSubject()) {
      this.setState({
        showExtraWrapper: false,
        emailSubject: this.state.tempSubject,
        tempSubject: '',
      });
    }
  }

  editExtraText() {
    this.setState({
      showExtraWrapper: false,
      emailExtraText: this.state.tempExtraText,
      tempExtraText: '',
    });
  }

  getExtraContent() {
    switch (this.state.extraContentType) {
      case 'SelectEmailReceipients':
        return (
          <ExtraModalCard>
            <ModalBody>
              <h1 className="h5">Select Email Receipients</h1>
              <hr />
              {this.state.borrowers.map((borrower, index) => (
                <div className="row" key={borrower.name}>
                  <div className="col-3">Borrower #{index + 1}</div>
                  <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state['borrower' + index + 'CB']}
                      onChange={() => this.checkBoxClicked('borrower' + index + 'CB')}
                      id={'borrower' + index + 'CB'}
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state['borrower' + index + 'CCCB']}
                      onChange={() => this.checkBoxClicked('borrower' + index + 'CCCB')}
                      id={'borrower' + index + 'CCCB'}
                    />
                  </div>
                  <div className="col-6">
                    <span className={`text-${(this.state['borrower' + index + 'CB'] || this.state['borrower' + index + 'CCCB']) ? "primary" : 'muted'}`}>
                      {borrower.email + ' (' + borrower.name + ')'}
                    </span>
                  </div>
                </div>
              ))}
              <div className="row mb-1">
                <div className="col-3">Loan Officer</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanOfficerCB}
                      disabled={this.state.loanOfficer.email === '---'}
                      id="loanOfficerCB"
                      onChange={() => this.checkBoxClicked('loanOfficerCB')}
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanOfficerCCCB}
                      disabled={this.state.loanOfficer.email === '---'}
                      onChange={() => this.checkBoxClicked('loanOfficerCCCB')}
                      id="loanOfficerCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.loanOfficerCB || this.state.loanOfficerCCCB) ? "primary" : 'muted'}`}>
                    {this.state.loanOfficer.email + ' (' + this.state.loanOfficer.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Loan Processor 1</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessorCB}
                      disabled={this.state.loanProcessor.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessorCB')}
                      id="loanProcessorCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessorCCCB}
                      disabled={this.state.loanProcessor.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessorCCCB')}
                      id="loanProcessorCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.loanProcessorCB || this.state.loanProcessorCCCB) ? "primary" : 'muted'}`}>
                    {this.state.loanProcessor.email + ' (' + this.state.loanProcessor.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Loan Processor 2</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessor2CB}
                      disabled={this.state.loanProcessor2.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessor2CB')}
                      id="loanProcessor2CB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessor2CCCB}
                      disabled={this.state.loanProcessor2.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessor2CCCB')}
                      id="loanProcessor2CCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.loanProcessor2CB || this.state.loanProcessor2CCCB) ? "primary" : 'muted'}`}>
                    {this.state.loanProcessor2.email + ' (' + this.state.loanProcessor2.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Underwriter 1</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter1CB}
                      disabled={this.state.underwriter1.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter1CB')}
                      id="underwriter1CB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter1CCCB}
                      disabled={this.state.underwriter1.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter1CCCB')}
                      id="underwriter1CCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.underwriter1CB || this.state.underwriter1CCCB) ? "primary" : 'muted'}`}>
                    {this.state.underwriter1.email + ' (' + this.state.underwriter1.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Underwriter 2</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter2CB}
                      disabled={this.state.underwriter2.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter2CB')}
                      id="underwriter2CB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter2CCCB}
                      disabled={this.state.underwriter2.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter2CCCB')}
                      id="underwriter2CCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.underwriter2CB || this.state.underwriter2CCCB) ? "primary" : 'muted'}`}>
                    {this.state.underwriter2.email + ' (' + this.state.underwriter2.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Account Executive</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.accountExecutiveCB}
                      disabled={this.state.accountExecutive.email === '---'}
                      onChange={() => this.checkBoxClicked('accountExecutiveCB')}
                      id="accountExecutiveCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.accountExecutiveCCCB}
                      disabled={this.state.accountExecutive.email === '---'}
                      onChange={() => this.checkBoxClicked('accountExecutiveCCCB')}
                      id="accountExecutiveCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.accountExecutiveCB || this.state.accountExecutiveCCCB) ? "primary" : 'muted'}`}>
                    {this.state.accountExecutive.email + ' (' + this.state.accountExecutive.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Settlement Company</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.settlementCB}
                      disabled={this.state.settlementCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('settlementCB')}
                      id="settlementCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.settlementCCCB}
                      disabled={this.state.settlementCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('settlementCCCB')}
                      id="settlementCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.settlementCB || this.state.settlementCCCB) ? "primary" : 'muted'}`}>
                    {this.state.settlementCompany.email + ' (' + this.state.settlementCompany.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Title Company</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.titleCB}
                      disabled={this.state.titleCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('titleCB')}
                      id="titleCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.titleCCCB}
                      disabled={this.state.titleCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('titleCCCB')}
                      id="titleCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.titleCB || this.state.titleCCCB) ? "primary" : 'muted'}`}>
                    {this.state.titleCompany.email + ' (' + this.state.titleCompany.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Buyer's Agent</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.buyerCB}
                      disabled={this.state.buyersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('buyerCB')}
                      id="buyerCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.buyerCCCB}
                      disabled={this.state.buyersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('buyerCCCB')}
                      id="buyerCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.buyerCB || this.state.buyerCCCB) ? "primary" : 'muted'}`}>
                    {this.state.buyersAgent.email + ' (' + this.state.buyersAgent.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Seller's Agent</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.sellerCB}
                      disabled={this.state.sellersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('sellerCB')}
                      id="sellerCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.sellerCCCB}
                      disabled={this.state.sellersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('sellerCCCB')}
                      id="sellerCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.sellerCB || this.state.sellerCCCB) ? "primary" : 'muted'}`}>
                    {this.state.sellersAgent.email + ' (' + this.state.sellersAgent.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">AMC</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.amcCB}
                      onChange={() => this.checkBoxClicked('amcCB')}
                      id="amcCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.amcCCCB}
                      onChange={() => this.checkBoxClicked('amcCCCB')}
                      id="amcCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.amcCB || this.state.amcCCCB) ? "primary" : 'muted'}`}>
                    {this.state.amc.email + ' (' + this.state.amc.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Appraiser</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.appraiserCB}
                      disabled={this.state.appraiser.email === '---'}
                      onChange={() => this.checkBoxClicked('appraiserCB')}
                      id="appraiserCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.appraiserCCCB}
                      disabled={this.state.appraiser.email === '---'}
                      onChange={() => this.checkBoxClicked('appraiserCCCB')}
                      id="appraiserCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.appraiserCB || this.state.appraiserCCCB) ? "primary" : 'muted'}`}>
                    {this.state.appraiser.email + ' (' + this.state.appraiser.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-12"><hr /></div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Custom Email #1</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom1CB}
                      disabled={this.state.custom1.length < 5 || this.state.custom1error}
                      onChange={() => this.checkBoxClicked('custom1CB')}
                      id="custom1CB"
                      />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom1CCCB}
                      disabled={this.state.custom1.length < 5 || this.state.custom1error}
                      onChange={() => this.checkBoxClicked('custom1CCCB')}
                    />
                </div>
                <div className="col-6">
                  <EmailTextField type="text" name="custom1" value={this.state.custom1}
                    onChange={this.captureInput}
                  />
                  &nbsp;
                  <HiddenSelect
                    name="lpContactSelect1"
                    value={this.state.lpContactSelect1}
                    onChange={this.updateEmailField}
                    options={this.state.lpContacts}
                  />
                  &nbsp;
                  <span style={{color:'red'}}>{this.state.custom1error}</span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Custom Email #2</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom2CB}
                      disabled={this.state.custom2.length < 5 || this.state.custom2error}
                      onChange={() => this.checkBoxClicked('custom2CB')}
                      />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom2CCCB}
                      disabled={this.state.custom2.length < 5 || this.state.custom2error}
                      onChange={() => this.checkBoxClicked('custom2CCCB')}
                    />
                </div>
                <div className="col-6">
                  <EmailTextField type="text" name="custom2" value={this.state.custom2}
                    onChange={this.captureInput}
                  />
                  &nbsp;
                  <HiddenSelect
                    name="lpContactSelect2"
                    value={this.state.lpContactSelect2}
                    onChange={this.updateEmailField}
                    options={this.state.lpContacts}
                  />
                  &nbsp;
                  <span style={{color:'red'}}>{this.state.custom2error}</span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Custom Email #3</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom3CB}
                      disabled={this.state.custom3.length < 5 || this.state.custom3error}
                      onChange={() => this.checkBoxClicked('custom3CB')}
                      id="Checked checkbox"
                      />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom3CCCB}
                      disabled={this.state.custom3.length < 5 || this.state.custom3error}
                      onChange={() => this.checkBoxClicked('custom3CCCB')}
                    />
                </div>
                <div className="col-6">
                  <EmailTextField type="text" name="custom3" value={this.state.custom3}
                    onChange={this.captureInput}
                  />
                  &nbsp;
                  <HiddenSelect
                    name="lpContactSelect3"
                    value={this.state.lpContactSelect3}
                    onChange={this.updateEmailField}
                    options={this.state.lpContacts}
                  />
                  &nbsp;
                  <span style={{color:'red'}}>{this.state.custom3error}</span>
                </div>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => this.clearEmails()}
                style={{ marginRight: '10px' }}
              >
                Clear
              </button>
              <button
                type="button"
                onClick={() => this.setState({ showExtraWrapper: false })}
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </ExtraModalCard>
        );
      case 'EditSubject':
        return (
          <ExtraModalCard>
            <ModalBody>
              <h1 className="h5">Edit Email Subject</h1>
              <hr />
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '100px' }}>Email Title:</td>
                    <td>
                      <input
                        type="text"
                        style={{ width: '500px' }}
                        name="tempSubject"
                        value={this.state.tempSubject || ''}
                        onChange={this.captureInput}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span style={{ color: 'red', fontSize: '13px' }}>
                        {this.state.errorMsg}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() =>
                  this.setState({
                    tempSubject: '',
                    showExtraWrapper: false,
                    errorMsg: '',
                  })
                }
                style={{ marginRight: '10px' }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => this.editEmailSubject()}
                disabled={
                  !this.state.emailSubject || this.state.emailSubject.length < 5
                }
                className="btn btn-primary"
              >
                Change
              </button>
            </div>
          </ExtraModalCard>
        );
      case 'EditExtraText':
        return (
          <ExtraModalCard>
            <ModalBody>
              <h1 className="h5">Add/Edit Email Text</h1>
              <hr />
              <textarea
                className="form-control"
                id="tempExtraText"
                rows="3"
                name="tempExtraText"
                value={this.state.tempExtraText}
                onChange={this.captureInput}
              ></textarea>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() =>
                  this.setState({
                    tempExtraText: '',
                    showExtraWrapper: false,
                    errorMsg: '',
                  })
                }
                style={{ marginRight: '10px' }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => this.editExtraText()}
                className="btn btn-primary"
              >
                Change
              </button>
            </div>
          </ExtraModalCard>
        );
      default:
        return <></>;
    }
  }

  getEmailContent(sending) {
    const { contentType, modalContentParameters } = this.state;

    switch (contentType) {
      case 'Submission-Submitted':
        // parameters: 0 - Underwriter Name, 1 - Underwriter Email
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            File No. {this.props.fileID} has been successfully submitted for
            initial review under{' '}
            <a
              href={
                'mailto:' +
                (modalContentParameters ? modalContentParameters[1] : '')
              }
            >
              {modalContentParameters ? modalContentParameters[0] : ''}
            </a>
            <br />
            <br />
            Please sign-in to the <a href="https://mtgrl.com">
              Broker Portal
            </a>{' '}
            to confirm all loan information has been correctly inputted. If
            anything is incorrect, kindly contact{' '}
            <a href={'mailto:submission@mtgrl.com'}>Submission Team</a> for
            correction(s).
            <br />
            <br />
            We greatly appreciate your business!
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'UW-ConditionalApproval':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            File No. {this.props.fileID} has been conditionally approved.
            <br />
            <br />
            Please sign-in to the <a href="https://mtgrl.com">
              Broker Portal
            </a>{' '}
            to follow up with PTC Conditions.
            <br />
            <br />
            Should you wish to refute any conditions on the list, please click
            the
            <img
              src="https://mtgrl.com/images/Comment.png"
              alt=""
              height="16"
              width="16"
              style={{ marginLeft: '10px', marginRight: '10px' }}
            />
            icon on each condition to start a chat.
            <br />
            <br />
            We greatly appreciate your business!
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'CTC-Almost':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            File No. {this.props.fileID} is almost ready for CTC!
            <br />
            <br />
            Provide the most updated settlement statement and TRID documents in
            preparation for the closing package.
            <br />
            <br />
            Please sign-in to the <a href="https://mtgrl.com">
              Broker Portal
            </a>{' '}
            to Review/Upload pending TRID conditions.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'CTC-CTCed':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            File No. {this.props.fileID} has been Cleared to Close / Doc.
            <br />
            <br />
            Your Closing Package / Loan Docs will be out shortly!
            <br />
            <br />
            In the meantime, please complete the Doc Request Form on our{' '}
            <a href="https://mtgrl.com">Broker Portal</a> if you've not yet done
            so.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Lock-Lock':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            The rate has been locked for File No. {this.props.fileID}
            <br />
            <br />
            Please perform your due diligence accordingly.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Lock-Confirmation':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            The rate has been locked for File No. {this.props.fileID}
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-ID-Brw':
        // parameters: 0 - Borrower Name, 1 - Subject Property
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Dear {modalContentParameters ? modalContentParameters[0] : ''},
            <br />
            <br />
            Good news! Your loan application has been accepted. Your File No. is{' '}
            {this.props.fileID}. Please save this number for your reference.
            <br />
            <br />
            In connection to your mortgage application for{' '}
            {modalContentParameters ? modalContentParameters[1] : ''}, you will
            receive a separate email shortly containing your Initial Loan
            Disclosures. To complete the e-Disclosure process, please make sure
            to electronically sign and submit all disclosures provided. Further
            instructions will also be provided.
            <br />
            <br />
            For any questions or concerns regarding your Loan Disclosures,
            please feel free to contact us.
            <br />
            <br />
            We appreciate your prompt attention to this matter.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-ID-Proc':
        // parameters: 0 - Application Date
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            For File No. {this.props.fileID}, the e-Sign request for the Initial
            Disclosures has been sent to the Borrower(s) emails.
            <br />
            <br />
            Please have your client(s) complete the process in a timely manner.
            <br />
            <br />
            The application date is:{' '}
            {modalContentParameters ? modalContentParameters[0] : ''}
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-LLE-Brw':
        // parameters: 0 - Borrower Name
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Dear {modalContentParameters ? modalContentParameters[0] : ''},
            <br />
            <br />
            We hope this email finds you well!
            <br />
            <br />
            This is to inform you that for your Loan Application "File No.{' '}
            {this.props.fileID}", you will be receiving your Locked Loan
            Estimate (LE) in a separate email shortly. To complete the e-Signing
            process, please ensure to electronically sign and submit the
            document provided.
            <br />
            <br />
            For any questions or concerns regarding your Locked LE, please feel
            free to contact us.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-LLE-Proc':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            For File No. {this.props.fileID}, the e-Sign request for the Locked
            Loan Estimate has been sent to the Borrower(s) emails.
            <br />
            <br />
            Please have your client(s) complete the process in a timely manner.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-RLE-Brw':
        // parameters: 0 - Borrower Name
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Dear {modalContentParameters ? modalContentParameters[0] : ''},
            <br />
            <br />
            Some revisions have been made to your mortgage loan, "File No.{' '}
            {this.props.fileID}". A Revised Locked Loan Estimate (LE) reflecting
            these changes will be provided shortly. To review and complete the
            e-Signing process, please ensure to electronically sign and submit
            the document provided.
            <br />
            <br />
            For any questions or concerns regarding your Revised LE, please feel
            free to contact us.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-RLE-Proc':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            For File No. {this.props.fileID}, the e-Sign request for the Revised
            Loan Estimate has been sent to the Borrower(s) emails.
            <br />
            <br />
            Please have your client(s) complete the process in a timely manner.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-ICD-Brw':
        // parameters: 0 - Borrower Name
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Dear {modalContentParameters ? modalContentParameters[0] : ''},
            <br />
            <br />
            Good news! We're almost done!
            <br />
            <br />
            This is to inform you that for your Loan Application, "File No.{' '}
            {this.props.fileID}", you will receive a separate email shortly
            containing your Initial Closing Disclosure. To complete the
            e-Signing process, please make sure to electronically sign and
            submit all disclosures provided.
            <br />
            <br />
            For any questions or concerns regarding your Initial Closing
            Disclosure, please feel free to contact us.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-ICD-Proc':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            For File No. {this.props.fileID}, the e-Sign request for the Initial
            Closing Disclosure has been sent to the Borrower(s) emails.
            <br />
            <br />
            Please have your client(s) complete the process in a timely manner.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Compliance-ICD-Escr':
        // parameters: 0 - Closing Date
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            Please click the link below to access the Initial Closing Disclosure
            for File No. {this.props.fileID}.
            <br />
            <a href={'http://www.mtgrl.com/escrow-file/' + this.props.fileID}>
              Escrow Access
            </a>
            <br />
            <br />
            Upon completion of your review, please update the estimated
            Settlement Statement and send it to us.
            <br />
            <br />
            The scheduled Closing Date/Signing Date is:{' '}
            {modalContentParameters ? modalContentParameters[0] : ''}.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Funding-Spfu':
        // parameters: 0 - Funding Date
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            For File No. {this.props.fileID}, please advise on the status of the
            fully executed Closing Package.
            <br />
            <br />
            The Funding Date is currently scheduled for:{' '}
            {modalContentParameters ? modalContentParameters[0] : ''}
            <br />
            <br />
            Please sign-in to the{' '}
            <a href={'http://www.mtgrl.com/escrow-file/' + this.props.fileID}>
              Escrow Page
            </a>{' '}
            to upload the signed Closing Package / Loan Doc.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Funding-Spr':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            For File No. {this.props.fileID}, we have reviewed the Executed Closing Package / Loan Docs.
            <br />
            <br />
            Please sign-in to the <a href={"https://mtgrl.com/escrow-file/" + this.props.fileID}>
              Broker Portal
            </a>{' '}
            to view PTF Conditions.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Funding-Ptfu':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            For File No. {this.props.fileID}, PTF Conditions have been updated.
            <br />
            <br />
            Please sign-in to the <a href="https://mtgrl.com">
              Broker Portal
            </a>{' '}
            to review.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Funding-Ctf':
        // parameters: 0 - Funding Date
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            Congratulations!
            <br />
            <br />
            File No. {this.props.fileID} has been Cleared to Fund!
            <br />
            <br />
            The Scheduled Funding Date is:{' '}
            {modalContentParameters ? modalContentParameters[0] : ''}
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Funding-Ff':
        // parameters: 0 - Funding Date
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            File No. {this.props.fileID} Funding Figures are now available.
            <br />
            <br />
            Click the link below to access the Funding Figures.
            <br />
            <a href={'http://www.mtgrl.com/escrow-file/' + this.props.fileID}>
              Escrow Access
            </a>
            <br />
            <br />
            Upon review, please confirm whether we balance with the Funding
            Date.
            <br />
            <br />
            The Scheduled Funding Date is:{' '}
            {modalContentParameters ? modalContentParameters[0] : ''}
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Funding-Funded':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            Hooray! File No. {this.props.fileID} has been funded!
            <br />
            <br />
            We appreciate your business and hope to work together again soon!
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Funding-Wc':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            Please confirm the receipt of the wire transfer for Title Ref. No.{' '}
            {modalContentParameters ? modalContentParameters[0] : ''}
            <br />
            <br />
            <div style={{ marginLeft: '10px'}}>
              <b>Fed Ref #:</b> {modalContentParameters ? modalContentParameters[1] : ''}
              <br />
              <b>Wire Amount:</b> {modalContentParameters ? modalContentParameters[2] : ''}
            </div> 
            <br />
            Upon confirmation, you are clear to record.
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
            <br />
            <br />
            Thank you!
            <br />
          </div>
        );
      case 'PostFunding-Fss':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            Please provide the Final Settlement Statement for For File No.{' '}
            {this.props.fileID}.
            <br />
            <br />
            It was a pleasure working with you.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'PostFunding-Fscd':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            Please provide the <b>Final Settlement Statement</b> and{' '}
            <b>Final Seller's CD</b> for For File No. {this.props.fileID}.
            <br />
            <br />
            We appreciate your business and hope that we can work together again
            in the future!
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      case 'Closing-Cpld':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            Please click the link below to access the{' '}
            <b>Closing Package/Loan Doc</b> for File No.{' '}
            {this.props.fileID}.
            <br />
            <a
              href={
                'http://www.mtgrl.com/escrow-file/' + this.props.fileID
              }
            >
              Escrow Access
            </a>
            <br />
            <br />
            Please note that the closing date is {modalContentParameters ? modalContentParameters[0] : ''}.
            <br />
            Upon completion, please make sure to send the original note to the
            following address:
            <br />
            <br />
            MORTGAGE RESEARCH LABORATORY INC.
            <br />
            ATTN: FUNDING
            <br />
            3435 WILSHIRE BLVD. STE 2880
            <br />
            LOS ANGELES, CA 90010
            <br />
            <br />
            For more information, please refer to the Closing Instruction.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );
      
      case 'Details-OnHold':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            File No. {this.props.fileID} has been placed on hold due to the
            following reason(s):
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
            <br />
            <br />
            Please contact your AE for more details.
            <br />
            <br />
            Thank you!
          </div>
        );

      case 'Details-Canceled':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            We're sending you this email to inform you that your loan (File No.{' '}
            {this.props.fileID}) has been <b>Canceled</b> per your request.
            <br />
            <br />
            We appreciate your business and hope we can work together again
            soon!
            <br />
            <br />
            Best Regards.
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );

      case 'Details-PtcUpdates':
        return (
          <div style={{ fontSize: '14px' }}>
            <br />
            Hello,
            <br />
            <br />
            File No. {this.props.fileID}'s condition list has been updated.
            <br />
            <br />
            Please sign-in to the <a href="https://mtgrl.com">
              Broker Portal
            </a>{' '}
            to follow up.
            <br />
            <br />
            Thank you!
            <br />
            <br />
            {sending ? this.state.emailExtraText : this.getExtraText()}
          </div>
        );

      default:
        return <></>;
    }
  }

  handleChangeStatus({target}) {
    if(target && target.files) {
      var fileList = target.files;
      for(var i=0; i<fileList.length; i++) {
        const file = fileList[i];
        var fileArr = this.state.fileArr;

        const reader = new FileReader();
        var fileToUpload;
        reader.readAsArrayBuffer(file);
        reader.onload = e => {
          fileToUpload = e.target.result;
        };
        reader.onloadend = () => {
          var uintarr = base64ArrayBuffer(fileToUpload);
          var extIndex = file.name.lastIndexOf(".");
          var fileName = file.name.substring(0, extIndex);
          var extension = file.name.substring(extIndex);

          // handle duplicate file names
          var match = false;
          var maxMatch = 0;
          for(var j=0; j<fileArr.length; j++) {
            var arrExtIndex = fileArr[j].fileName.lastIndexOf(".");
            var arrFileName = fileArr[j].fileName.substring(0, arrExtIndex);
            if(arrFileName.indexOf(fileName) === 0) {
              match = true;
              break;
            }
          }
          if(match) {
            for(var k=0; k<fileArr.length; k++) {
              var arrExtIndex2 = fileArr[k].fileName.lastIndexOf(".");
              var arrFileName2 = fileArr[k].fileName.substring(0, arrExtIndex2);
              if(arrFileName2.indexOf(fileName + " (") === 0) {
                var num = arrFileName2.substring(fileName.length + 2, arrFileName2.length - 1);
                if(parseInt(num) > maxMatch) { 
                  maxMatch = num; 
                }
              }
            }
            maxMatch++;
            fileName = fileName + " (" + maxMatch + ")";
          }
          
          // add file to array
          fileArr.push({
            fileUIntArr: uintarr,
            fileName: fileName + extension,
          });
          setTimeout(() => {
            this.setState({ isSubmitting: false });
          }, 1000);
        };
      }
    }
  }

  removeAttachment(fileName) {
    if(this.state.fileArr) {
      var fileArr = this.state.fileArr;
      for(var i=0; i<fileArr.length; i++) {
        if(fileArr[i].fileName === fileName) {
          fileArr.splice(i, 1);
          break;
        }
      }
      this.setState({fileArr}); 
    }
  }

  combineFileBuffers(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  sendEmail() {
    var sendToAddr = this.getSendAddresses();
    var ccAddr = this.getCCAddresses();

    if (sendToAddr.length === 0 && ccAddr.length === 0) {
      this.setState({
        errorMessage: 'Email Has No Recipients',
        isProcessing: false,
      });
      return;
    } else {
      this.setState({ isProcessing: true, errorMessage: '' });

      // setup attachments
      var combinedFiles = null;
      var combinedFileNames = null;
      for (var i = 0; i < this.state.fileArr.length; i++) {
        if (i === 0) { 
          combinedFiles = this.state.fileArr[i].fileUIntArr;
          combinedFileNames = this.state.fileArr[i].fileName;
        }
        else {
          combinedFiles = this.combineFileBuffers(
            combinedFiles,
            this.state.fileArr[i].fileUIntArr
          );
          combinedFileNames = this.combineFileBuffers(
            combinedFileNames,
            this.state.fileArr[i].fileName
          );
        }
      }
      
      const params = {
        fileID: this.props.fileID,
        sendTo: sendToAddr,
        ccEmails: ccAddr,
        fromAddr: this.state.emailFrom,
        title: this.state.emailSubject,
        content: renderToString(this.getEmailContent(true)),
        sender: LoginState.userName,
        category: this.state.contentType,
        fileNames: combinedFileNames,
        attachments: combinedFiles
      };

      fetch('/sendemail', {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.text())
      .then(data => {
        // error check
        if (!data) {
          // show error message
          this.setState({ errorMessage: data, isProcessing: false });
          return;
        } else {
          // add email sent data to log
          this.props.emailLog.push(JSON.parse(data));
          // show success message
          this.setState({ emailType: '', isProcessing: false });
          this.props.onAccept();
        }
      });
    }
  }

  render() {
    if (!this.props.template) {
      return <></>;
    }

    return this.props.show ? (
      <ModalWrapper>
        <ModalCard width={this.props.width} height={this.props.height}>
          <ModalHeader>
            <ModalTitle>[Email Preview]</ModalTitle>
            <button
              type="button"
              className="btn-close"
              onClick={this.props.onDecline}
              aria-label="Close"
            ></button>
          </ModalHeader>
          <ModalBody>
            <div className="w-100 text-start border border-1 p-2">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <b>To:</b>
                      </td>
                      <td>
                        {this.getEmailToList().map((email, index) => (
                          <span
                            key={email + index}
                            className={`fw-normal badge border rounded-pill pill-info`}
                            style={{ marginLeft: '8px' }}
                          >
                            {email.trim()}
                          </span>
                        ))}
                        <button
                          className="btn"
                          type="button"
                          style={{
                            height: '16px',
                            maxWidth: '16px',
                            marginLeft: '10px',
                          }}
                          onClick={() =>
                            this.setState({
                              showExtraWrapper: true,
                              extraContentType: 'SelectEmailReceipients',
                            })
                          }
                        >
                          <i
                            className="bi bi-pencil-square"
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              position: 'relative',
                              top: '-14px',
                              left: '-7px',
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mb-2">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <b>Cc:</b>
                      </td>
                      <td>
                        {this.getEmailCcList().map((email, index) => (
                          <span
                            key={email + index}
                            className={`fw-normal badge border rounded-pill pill-info`}
                            style={{ marginLeft: '8px' }}
                          >
                            {email.trim()}
                          </span>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mb-2">
                <span style={{ marginRight: '8px' }}>
                  <b>Subject:</b>
                </span>
                {this.state.emailSubject}
                <button
                  className="btn"
                  type="button"
                  style={{
                    height: '16px',
                    maxWidth: '16px',
                    marginLeft: '10px',
                  }}
                  onClick={() =>
                    this.setState({
                      showExtraWrapper: true,
                      extraContentType: 'EditSubject',
                      tempSubject: this.state.emailSubject,
                    })
                  }
                >
                  <i
                    className="bi bi-pencil-square"
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      position: 'relative',
                      top: '-14px',
                      left: '-7px',
                    }}
                  />
                </button>
              </div>
              <hr />
              <img
                src="https://mtgrl.com/images/MTG_Logo.png"
                alt=""
                height="32"
                width="240"
              />
              <br />
              <hr />
              <div
                style={{
                  minHeight: '200px',
                  verticalAlign: 'top',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
              >
                {this.getEmailContent(false)}
              </div>
            </div>
            {this.state.showExtraWrapper && (
              <ExtraModalWrapper>{this.getExtraContent()}</ExtraModalWrapper>
            )}
          </ModalBody>

          {/* Attachments */}
          {this.state.fileArr && (
            <div style={{marginBottom:'20px'}}>
              {this.state.fileArr.map(file => (
                <div key={file.fileName}>
                  <span style={{color:'red', fontSize:'12px', paddingRight:'10px', cursor:'pointer'}} onClick={()=>this.removeAttachment(file.fileName)}>[Remove]</span>
                  {file.fileName}
                </div>
              ))}
            </div>
          )}
          
          {/* Error Messages */}
          {this.state.errorMessage && (
            <div style={{ color: 'red', fontSize: '12px' }}>
              {this.state.errorMessage}
            </div>
          )}

          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-6" >
                {/* Add Attachments */}
                <div>
                  <button
                    type="button"
                    className="btn btn-light float-right"
                    onClick={() => document.getElementById('attachFiles').click()}
                    style={{ height:'38px', width:'170px', position:'relative', left:'-12px' }}
                  >
                    <i className={`fs-5 bi bi-paperclip`} /> Attach File(s)
                  </button>
                  <input type="file" multiple="multiple" id="attachFiles" name="attachFiles" onChange={this.handleChangeStatus.bind(this)} style={{display:'none'}} />
                </div>
              </div>
              <div className="col-6 float-right" style={{textAlign:'right'}}>
                {!this.props.hideCancelBtn && (
                  <button
                    type="button"
                    className="btn btn-light float-right"
                    disabled={this.state.isProcessing}
                    onClick={this.props.onDecline}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => this.sendEmail()}
                  disabled={
                    this.props.onAcceptDisabled || this.state.isProcessing
                  }
                  className="btn btn-primary float-right"
                >
                  {this.state.isProcessing ? 'Wait' : 'Send'}
                </button>
              </div>
            </div>
          </div>
        </ModalCard>
      </ModalWrapper>
    ) : (
      <></>
    );
  }
}
