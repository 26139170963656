import React, { Component } from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import LoginState from '../components/LoginState';
import { checkRouteForString } from '../helpers/utils';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.history
        ? this.props.history.location.pathname.split('/')[3]
        : '',
      appraisalID: '',
      lastPage: '',
      borrowerName: '',
    };
  }

  componentDidMount() {
    this.getBorrowerName();
  }

  componentDidUpdate() {
    if (this.state.lastPage === this.props.history.location.pathname) return;
    this.getBorrowerName();
  }

  async getBorrowerName() {
    const splitLength = this.props.history.location.pathname.split('/').length;
    var bName = '';
    if (splitLength >= 3) {
      try {
        var response = axios.get('/getborrowername', {
          params: {
            pFileID: this.props.history.location.pathname.split('/')[3],
          },
        });
        bName = (await response).data;
      } catch {
        bName = '';
      }
    }

    this.setState({
      lastPage: this.props.history.location.pathname,
      key:
        bName.indexOf('*') > 0
          ? bName.split('*')[1]
          : splitLength >= 3
          ? this.props.history.location.pathname.split('/')[3]
          : '',
      appraisalID:
        bName.indexOf('*') > 0
          ? splitLength >= 3
            ? this.props.history.location.pathname.split('/')[3]
            : ''
          : '',
      borrowerName: bName.split('*')[0],
    });
  }

  isActive(path) {
    return this.props.history.location.pathname
      .split('/')
      .includes(path.replace('/', ''))
      ? 'true'
      : '';
  }

  render() {
    var pageKey =
      this.state.key && this.state.key.length > 10
        ? this.state.key.substring(this.state.key.length - 10)
        : this.state.key;
    return (
      <>
        <ul className="nav nav-pills flex-column mb-auto">
          <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
            <Link
              to="/dashboard"
              className={`nav-link ${
                this.isActive('dashboard') ? 'active' : ''
              }`}
            >
              <i className="bi-house me-2"></i>Dashboard
            </Link>
          </li>
          <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
            <Link
              to="/new-loan"
              className={`nav-link ${
                this.isActive('/new-loan') ? 'active' : ''
              }`}
            >
              <i className="bi-plus-square me-2"></i>Register Loan
            </Link>
          </li>
          <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
            <Link
              to="/pipeline/all"
              className={`nav-link ${
                this.isActive('/pipeline') ? 'active' : ''
              }`}
            >
              <i className="bi-table me-2"></i>Pipeline
            </Link>
          </li>
          {this.state.key && (
            <div
              className="alert bg-gray200 text-secondary px-2 py-0 mt-2 mb-0 ms-2 d-flex"
              role="alert"
            >
              <i className="bi-person-fill me-2"></i>
              {this.state.borrowerName && (
                <div>
                  {this.state.borrowerName}
                  <br />
                  <span>{pageKey}</span>
                </div>
              )}
            </div>
          )}
          {this.state.key &&
            (LoginState.userOrg === '1000001' ||
              LoginState.userId === 1000009) &&
            !LoginState.userRole
              .toLowerCase()
              .includes('account executive') && (
              <ul className="btn-toggle-nav list-unstyled fw-normal small">
                <li>
                  <Link
                    className="ms-4 nav-link py-2"
                    to={`/details/sub-${pageKey}`}
                  >
                    File Detail
                  </Link>
                </li>
              </ul>
            )}
          <ul className="btn-toggle-nav list-unstyled fw-normal small">
            {this.state.key &&
              [
                'important-dates',
                'loan-summary',
                'submission',
                'conditions',
                'appraisals',
                'rate-lock',
                'closing',
                'change-in-circumstance',
                'documents',
                'contacts',
              ].map(path => (
                <li
                  data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}
                  key={path}
                >
                  <Link
                    className={`py-2 ms-4 nav-link ${
                      this.isActive(path) ? 'active' : ''
                    }`}
                    to={`/pipeline/${path}/${
                      path.includes('conditions') && !path.includes('ac-')
                        ? 'ac-'
                        : ''
                    }${pageKey}`}
                  >
                    {path === 'change-in-circumstance'
                      ? 'CIC'
                      : path
                          .split('-')
                          .join(' ')
                          .replace(/(\b[a-z])/g, x => x.toUpperCase())}
                  </Link>
                </li>
              ))}
          </ul>
          <li className="mb-1">
            <button
              className="btn btn-toggle d-inline-flex align-items-center rounded border-0 w-100 collapsed nav-link"
              data-bs-toggle="collapse"
              data-bs-target="#resources"
              aria-expanded="true"
            >
              <i className="bi-box me-2"></i>
              Resources
            </button>
            <div className="collapse" id="resources">
              <ul className="btn-toggle-nav list-unstyled fw-normal small ms-4">
                {/* Hide RateSheets From Specific Users */}
                {!LoginState.limited && (
                  <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
                    <Link
                      className={`nav-link ${
                        this.isActive('daily-rates') ? 'active' : ''
                      }`}
                      to="/resources/daily-rates"
                    >
                      Daily Rate Sheets
                    </Link>
                  </li>
                )}
                <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
                  <Link
                    className={`nav-link ${
                      this.isActive('forms') ? 'active' : ''
                    }`}
                    to="/resources/forms"
                  >
                    Forms
                  </Link>
                </li>
                <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
                  <Link
                    className={`nav-link ${
                      this.isActive('product-matrix') ? 'active' : ''
                    }`}
                    to="/resources/product-matrix"
                  >
                    Matrix
                  </Link>
                </li>
                <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
                  <Link
                    className={`nav-link ${
                      this.isActive('appraisal-finder') ? 'active' : ''
                    }`}
                    to="/tools/appraisal-finder"
                  >
                    Appraisal Fee Finder
                  </Link>
                </li>
                <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
                  <Link
                    className={`nav-link ${
                      this.isActive('geoid-validator') ? 'active' : ''
                    }`}
                    to="/resources/geoid-validator"
                  >
                    GeoID Validator
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          {(LoginState.licensed || LoginState.userId === 1000009) && (
              <li className="mb-1">
                <button
                  className="btn btn-toggle d-inline-flex align-items-center rounded border-0 w-100 nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#lender-panel"
                  aria-expanded="true"
                >
                  <i className="bi-wrench me-2"></i>
                  Lender Panel
                </button>
                <div className="collapse show" id="lender-panel">
                  <ul className="btn-toggle-nav list-unstyled fw-normal small ms-4">
                    <li
                      className="m-0 p-0"
                      data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}
                    >
                      <Link
                        className={`nav-link ${
                          this.isActive('details') ? 'active' : ''
                        }`}
                        to="/details/search"
                      >
                        File Details
                      </Link>
                    </li>
                    <li
                      className="m-0 p-0"
                      data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}
                    >
                      <Link
                        className={`nav-link ${
                          this.isActive('status-board') ? 'active' : ''
                        }`}
                        to="/status-board/compliance"
                      >
                        Status Board
                      </Link>
                    </li>
                    <li
                      className="m-0 p-0"
                      data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}
                    >
                      <Link
                        className={`nav-link ${
                          this.isActive('lenderpanel') ? 'active' : ''
                        }`}
                        to="/lenderpanel/newloans"
                      >
                        Notifications
                      </Link>
                    </li>
                    <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
                      <Link
                        className={`nav-link ${
                          this.isActive('email-search') ? 'active' : ''
                        }`}
                        to="/email-search"
                      >
                        Email Search
                      </Link>
                    </li>
                    <li data-bs-dismiss={this.props.dismiss ? 'offcanvas' : ''}>
                      <Link
                        className={`nav-link ${
                          this.isActive('wizard1003') ? 'active' : ''
                        }`}
                        to="/wizard1003"
                      >
                        1003
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            )}
        </ul>
        <ul className="nav nav-pills flex-column">
          <Link
            to="/settings/userinfo"
            className={`nav-link ${this.isActive('/settings') ? 'active' : ''}`}
          >
            <i className="bi-gear me-2"></i>Settings
          </Link>
        </ul>
      </>
    );
  }
}

export default withRouter(Sidebar);
