import React, { Component } from 'react';
import Toast from '../components/Toast';
import axios from 'axios';
import LoginState from './LoginState';

const categories = [
  { label: 'All Categories', value: 1 },
  { label: 'Appraisal', value: 2 },
  { label: 'Escrow', value: 3 },
  { label: 'Title', value: 4 },
  { label: 'Real Estate', value: 5 },
];

export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorySelect: 1,
      companySelect: 0,
      employeeSelect: 0,
      isAddingEmployee: false,
      contacts: [],
      allCompanies: [],
      filteredCompanies: [],
      filteredEmployees: [],
      changesExist: false,
      successMessage: '',
      errorMessage: '',
    };

    // if not admin, redirect
    if (
      !LoginState.userRole ||
      !LoginState.userRole.toLowerCase().includes('admin')
    )
      this.props.history.push('/');

    this.FetchContactInfo();
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, changesExist: true });
  };

  async FetchContactInfo() {
    axios
      .get('/getemailcontacts')
      .then(res => {
        if (res) {
          this.setState({ contacts: res.data });
          this.updateCompanyList(res.data);
        }
      })
      .catch(e => console.log(e, 'Error fetching contacts'));
  }

  updateCompanyList(contactData) {
    var companyList = [];
    var lastCompany = '';
    var companyNumber = 1;
    for (var i = 0; i < contactData.length; i++) {
      if (lastCompany !== contactData[i].company.toLowerCase()) {
        lastCompany = contactData[i].company.toLowerCase();
        companyList.push({
          label: contactData[i].company,
          category: contactData[i].category,
          value: companyNumber++,
        });
      }
    }
    this.setState({
      allCompanies: companyList,
      companySelect: 0,
      employeeSelect: 0,
    });
    this.filterCompanies(this.state.categorySelect);
  }

  changeCompany = e => {
    this.setState({ companySelect: e.target.value, employeeSelect: 0 });
    this.filterEmployees(
      this.state.filteredCompanies[e.target.value - 1].label
    );
  };

  filterEmployees(companyName) {
    var employeeCount = 1;
    var employeeList = [];
    var contactData = this.state.contacts;
    for (var i = 0; i < contactData.length; i++) {
      if (companyName.toLowerCase() === contactData[i].company.toLowerCase()) {
        employeeList.push({
          label:
            (
              contactData[i].firstName +
              ' ' +
              contactData[i].lastName
            ).trim() === ''
              ? '[' + contactData[i].company + ']'
              : contactData[i].firstName + ' ' + contactData[i].lastName,
          id: contactData[i].contactID,
          value: employeeCount++,
        });
      }
    }
    this.setState({ filteredEmployees: employeeList });
  }

  changeCategory = e => {
    this.setState({
      categorySelect: e.target.value,
      companySelect: 0,
      employeeSelect: 0,
    });
    this.filterCompanies(e.target.value);
    this.filterEmployees('');
  };

  filterCompanies(category) {
    var stringCategory = '';
    switch (parseInt(category)) {
      case 1: // all categories
        this.setState({ filteredCompanies: this.state.allCompanies });
        return;
      case 2: // appraisal
        stringCategory = 'Appraisal';
        break;
      case 3: // escrow
        stringCategory = 'Escrow';
        break;
      case 4: // title
        stringCategory = 'Title';
        break;
      case 5: // real estate
        stringCategory = 'Real Estate';
        break;
      default:
        this.setState({ filteredCompanies: this.state.allCompanies });
        return;
    }

    var companyCount = 1;
    var filteredList = [];
    for (var i = 0; i < this.state.allCompanies.length; i++) {
      if (this.state.allCompanies[i].category === stringCategory) {
        filteredList.push({
          label: this.state.allCompanies[i].label,
          category: this.state.allCompanies[i].category,
          value: companyCount++,
        });
      }
    }
    this.setState({ filteredCompanies: filteredList });
  }

  changeEmployee = e => {
    this.setState({
      employeeSelect: e.target.value,
      isAddingEmployee: false,
      changesExist: false,
    });
    this.showEmployeeData(this.state.filteredEmployees[e.target.value - 1].id);
  };

  showEmployeeData(contactID) {
    for (var i = 0; i < this.state.contacts.length; i++) {
      if (this.state.contacts[i].contactID === contactID) {
        var emp = this.state.contacts[i];
        this.setState({
          company: emp.company,
          companyLicenseNo: emp.companyLicenseNo,
          title: emp.title,
          licenseNo: emp.licenseNo,
          firstName: emp.firstName,
          middleName: emp.middleName,
          lastName: emp.lastName,
          email: emp.email,
          phone: emp.phone,
          fax: emp.fax,
          street: emp.street,
          city: emp.city,
          state: emp.state,
          zip: emp.zip,
          contactID: emp.contactID,
        });
        break;
      }
    }
  }

  setupNewContact() {
    var company = '';
    var companyLicenseNo = '';
    var fax = '';
    var street = '';
    var city = '';
    var state = '';
    var zip = '';

    // first find company information
    if (this.state.companySelect > 0) {
      company =
        this.state.filteredCompanies[this.state.companySelect - 1].label;
      for (var i = 0; i < this.state.contacts.length; i++) {
        if (
          company.toLowerCase() === this.state.contacts[i].company.toLowerCase()
        ) {
          if (this.state.contacts[i].companyLicenseNo)
            companyLicenseNo = this.state.contacts[i].companyLicenseNo;
          if (this.state.contacts[i].fax) fax = this.state.contacts[i].fax;
          if (this.state.contacts[i].street)
            street = this.state.contacts[i].street;
          if (this.state.contacts[i].city) city = this.state.contacts[i].city;
          if (this.state.contacts[i].state)
            state = this.state.contacts[i].state;
          if (this.state.contacts[i].zip) zip = this.state.contacts[i].zip;
        }
      }
    }

    this.setState({
      isAddingEmployee: true,
      employeeSelect: 0,
      company: company,
      companyLicenseNo: companyLicenseNo,
      title: '',
      licenseNo: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      fax: fax,
      street: street,
      city: city,
      state: state,
      zip: zip,
    });
  }

  async submitContactInfo() {
    var company = '';
    var categoryName = '';

    // try to determine category
    if (this.state.companySelect > 0) {
      company =
        this.state.filteredCompanies[this.state.companySelect - 1].label;
      for (var i = 0; i < this.state.contacts.length; i++) {
        if (
          company.toLowerCase() === this.state.contacts[i].company.toLowerCase()
        ) {
          if (this.state.contacts[i].category) {
            categoryName = this.state.contacts[i].category;
            break;
          }
        }
      }
    } else {
      var compName = this.state.companyName.toLowerCase();
      if (compName.includes('escrow')) categoryName = 'Escrow';
      else if (compName.includes('title')) categoryName = 'Title';
      else if (compName.includes('real')) categoryName = 'Real Estate';
      else if (compName.includes('appraisal') || compName.includes('amc'))
        categoryName = 'Appraisal';
    }

    axios
      .get('/updatecontactinfo', {
        params: {
          pContactID: this.state.isAddingEmployee ? '' : this.state.contactID,
          pFirstName: this.state.firstName,
          pMiddleName: this.state.middleName,
          pLastName: this.state.lastName,
          pCompany: this.state.company,
          pCompanyLicense: this.state.companyLicenseNo,
          pTitle: this.state.title,
          pLicenseNo: this.state.licenseNo,
          pEmail: this.state.email,
          pPhone: this.state.phone,
          pFax: this.state.fax,
          pStreet: this.state.state,
          pCity: this.state.city,
          pState: this.state.state,
          pZip: this.state.zip,
          pCategory: categoryName,
        },
      })
      .then(res => {
        if (res) {
          this.setState({
            successMessage: 'Contact Information Saved!',
            companySelect: 0,
            employeeSelect: 0,
            filteredEmployees: [],
            isAddingEmployee: false,
          });
          this.FetchContactInfo();
        }
      });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 text-uppercase">admin</h1>
          </div>
          <p className="border-bottom text-muted text-uppercase mt-4">
            FIND CONTACT
          </p>
          <div className="mb-2 col-12 col-md-4 col-lg-2">
            <select
              className="form-select"
              name="categorySelect"
              label="Category"
              value={this.state.categorySelect}
              onChange={this.changeCategory}
            >
              {categories.map(el => (
                <option key={el.value} value={el.value}>
                  {el.label}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2 col-12 col-md-4 col-lg-3">
            <select
              className="form-select"
              name="companySelect"
              label="Company"
              value={this.state.companySelect}
              onChange={this.changeCompany}
            >
              {[{ label: 'Select', value: -1 }]
                .concat(this.state.filteredCompanies)
                .map(el => (
                  <option key={el.value} value={el.value}>
                    {el.label}
                  </option>
                ))}
            </select>
          </div>
          {this.state.filteredEmployees.length > 0 && (
            <div className="mb-2 col-12 col-md-4 col-lg-2">
              <select
                className="form-select"
                name="employeeSelect"
                label="Employee / Representative"
                value={this.state.employeeSelect}
                onChange={this.changeEmployee}
              >
                {[{ label: 'Select', value: -1 }]
                  .concat(this.state.filteredEmployees)
                  .map(el => (
                    <option key={el.value} value={el.value}>
                      {el.label}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <div className="mb-2 col-12 col-md-4 d-flex flex-column flex-md-row align-items-center">
            <span className="me-md-3">OR</span>
            <button
              className="btn btn-primary btn-block"
              disabled={this.state.isAddingEmployee}
              onClick={() => this.setupNewContact()}
            >
              Add New
            </button>
          </div>
          {(this.state.employeeSelect > 0 || this.state.isAddingEmployee) && (
            <div className="col-12 col-md-6 border rounded rounded-4 m-md-2 px-2 py-4 px-md-4 bg-light">
              <h2 className="h4">
                {this.state.isAddingEmployee ? 'Create' : 'Update'} contact
                (employee / representative)
              </h2>
              <div className="row">
                <div className="mb-2 col-12 col-md-6 col-lg-4">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-12 col-md-6 col-lg-4">
                  <label htmlFor="middleName">Middle Name</label>
                  <input
                    className="form-control"
                    id="middleName"
                    name="middleName"
                    value={this.state.middleName}
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-12 col-md-6 col-lg-4">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.captureInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-12 col-md-6">
                  <label htmlFor="company">Company</label>
                  <input
                    className="form-control"
                    id="company"
                    name="company"
                    value={this.state.company}
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-12 col-md-6">
                  <label htmlFor="companyLicenseNo">Company License #</label>
                  <input
                    className="form-control"
                    id="companyLicenseNo"
                    name="companyLicenseNo"
                    value={this.state.companyLicenseNo}
                    onChange={this.captureInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-12 col-md-6">
                  <label htmlFor="title">Title</label>
                  <input
                    className="form-control"
                    id="title"
                    name="title"
                    value={this.state.title}
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-12 col-md-6">
                  <label htmlFor="licenseNo">License #</label>
                  <input
                    className="form-control"
                    id="licenseNo"
                    name="licenseNo"
                    value={this.state.licenseNo}
                    onChange={this.captureInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-12 col-md-6">
                  <label htmlFor="street">Street</label>
                  <input
                    className="form-control"
                    id="street"
                    name="street"
                    value={this.state.street}
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-12 col-md-6">
                  <label htmlFor="city">City</label>
                  <input
                    className="form-control"
                    id="city"
                    name="city"
                    value={this.state.city}
                    onChange={this.captureInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-6 col-md-3">
                  <label htmlFor="state">State</label>
                  <input
                    className="form-control"
                    id="state"
                    name="state"
                    value={this.state.state}
                    maxLength="2"
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-6 col-md-3">
                  <label htmlFor="zip">Zip</label>
                  <input
                    className="form-control"
                    id="zip"
                    name="zip"
                    value={this.state.zip}
                    onChange={this.captureInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-12 col-md-6 col-lg-4">
                  <label htmlFor="email">Email</label>
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-12 col-md-6 col-lg-4">
                  <label htmlFor="phone">Phone</label>
                  <input
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.captureInput}
                  />
                </div>
                <div className="mb-2 col-12 col-md-6 col-lg-4">
                  <label htmlFor="fax">Fax</label>
                  <input
                    className="form-control"
                    id="fax"
                    name="fax"
                    value={this.state.fax}
                    onChange={this.captureInput}
                  />
                </div>
              </div>
              <div className="col-12 mt-2">
                <button
                  className="btn btn-primary"
                  disabled={
                    this.state.isAddingEmployee
                      ? !this.state.company
                      : !this.state.changesExist
                  }
                  onClick={() => this.submitContactInfo()}
                >
                  {this.state.isAddingEmployee
                    ? 'Save Contact'
                    : 'Save Changes'}
                </button>
                {this.state.employeeSelect > 0 &&
                  !this.state.isAddingEmployee && (
                    <button
                      className="ms-2 btn btn-secondary"
                      disabled={this.state.isAddingEmployee}
                      onClick={() => this.setupNewContact()}
                    >
                      Create from contact
                    </button>
                  )}
              </div>
            </div>
          )}
          <div className="col-12">
            {this.state.successMessage && (
              <Toast
                type="success"
                title={this.state.successMessage}
                onClose={() => this.setState({ successMessage: '' })}
              />
            )}
            {this.state.errorMessage && (
              <Toast
                type="danger"
                title={this.state.errorMessage}
                onClose={() => this.setState({ errorMessage: '' })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
