import React, { Component } from 'react';
import Modal from '../components/Modal';
import { Base64 } from 'js-base64';
import LoginState from '../components/LoginState';
import { CurrencyInput } from '../components/CurrencyInput';
import { PRICING_OPTIONS } from '../helpers/constants';
import Toast from '../components/Toast';
import axios from 'axios';
import Dropzone from 'react-dropzone-uploader';
import styled from 'styled-components';
import {
  base64ArrayBuffer,
  todaysDate,
  numbersNoFormat,
  formatCurrency,
} from '../helpers/utils';

const DZInputContainer = styled.div`
  .dzu-inputLabelWithFiles {
    display: none;
  }
`;

const Placeholder = () => (
  <div className="mb-2 d-none d-lg-block">
    <label htmlFor="help-input-placeholder" className="form-label">
      &nbsp;
    </label>
    <input
      type="text"
      disabled
      readOnly
      className="form-control-plaintext"
      id="inputPlaceholder"
      value=""
    ></input>
    <div id="help-input-placeholder" className="form-text invalid-feedback">
      &nbsp;
    </div>
  </div>
);

const Layout = ({ input, dropzoneProps }) => (
  <div>
    <DZInputContainer {...dropzoneProps}>{input}</DZInputContainer>
  </div>
);

const MAX_FILE_SIZE = 25000000;

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();

    let currentTab = 'User Info'; // default value
    switch (this.props.match.params.tab) {
      case 'userinfo':
        currentTab = 'User Info';
        break;
      case 'password':
        currentTab = 'Change Password';
        break;
      case 'autofill':
        currentTab = 'Manage Autofill';
        break;
      case 'matrix':
        currentTab = 'Update Matrix Files';
        break;
      case 'form':
        currentTab = 'Update Form Files';
        break;
      case 'rate':
        currentTab = 'Update Ratesheet Template';
        break;
      default:
        break;
    }

    this.state = {
      retrievedData: false,
      activeTab: currentTab,
      retrievedTab: '',
      successMessage: '',
      notificationData: [],
      currentPass: '',
      newPass: '',
      confirmPass: '',
      errorMessage: '',
      autoFill: null,
      changeExists: false,
      showConfirmModal: false,
      isSubmitting: false,
      fileArr: [],
      fileName: '',
      formFile: '',
      fileSize: 0,
    };

    this.fields = ['currentPass', 'newPass', 'confirmPass'];

    // get options before page loads
    this.getNotifications();
  }

  componentDidUpdate() {
    let currentTab = '';
    switch (this.props.match.params.tab) {
      case 'userinfo':
        currentTab = 'User Info';
        break;
      case 'password':
        currentTab = 'Change Password';
        break;
      case 'autofill':
        currentTab = 'Manage Autofill';
        break;
      case 'matrix':
        currentTab = 'Update Matrix Files';
        break;
      case 'form':
        currentTab = 'Update Form Files';
        break;
      case 'rate':
        currentTab = 'Update Ratesheet Template';
        break;
      default:
        break;
    }
    if (this.state.activeTab !== currentTab) {
      this.setState({ activeTab: currentTab, fileArr: [] });

      if (this.state.activeTab === 'Change Password') {
        this.fields = ['lienPosition', 'loanPurpose', 'propertyType'];
      }
    }
  }

  async getNotifications() {
    var response;

    if (this.state.activeTab === 'User Info') {
      response = axios.get('../userlist');
    }

    if (this.state.activeTab === 'Manage Autofill') {
      response = axios.get('../getautofilloptions', {
        params: { pUserID: LoginState.userId },
      });
    }

    // the response.data will contain the values returned
    try {
      var resultData = (await response).data;

      if (this.state.activeTab === 'Manage Autofill') {
        // reformat, skipping first 2 entries which are default to all users
        var fillList = [];
        for (var i = 2; i < resultData.length; i++) {
          fillList.push({ label: resultData[i], value: i - 2 });
        }
        this.setState({
          retrievedData: true,
          retrievedTab: this.state.activeTab,
          notificationData: fillList,
        });
      } else {
        this.setState({
          retrievedData: true,
          retrievedTab: this.state.activeTab,
          notificationData: resultData,
        });
      }
    } catch {
      this.setState({
        retrievedData: true,
        retrievedTab: this.state.activeTab,
        notificationData: [],
      });
    }
  }

  async autoFill(newOption) {
    const response = await axios.get('/getautofilldata', {
      params: {
        pUserID: LoginState.userId,
        pAutoFillName: newOption,
      },
    });

    const {
      lienPosition,
      loanPurpose,
      propertyType,
      occupancyType,
      documentType,
      creditScore,
      state,
      county,
      propertyValue,
      purchasePrice,
      firstLoan,
      secondLoan,
      ltv,
      cltv,
      loanProduct,
      impound,
      pmi,
      lockPeriod,
      compPlan,
      dti,
    } = response.data;
    this.setState({
      errors: null,
      displayPrograms: false,
      lienPosition,
      loanPurpose,
      propertyType,
      occupancyType,
      documentType,
      creditScore,
      state,
      county,
      propertyValue: formatCurrency(propertyValue),
      purchasePrice: formatCurrency(purchasePrice),
      firstLoan: formatCurrency(firstLoan),
      secondLoan: formatCurrency(secondLoan),
      ltv,
      cltv,
      loanProduct,
      impound,
      pmi,
      lockPeriod,
      compPlan,
      dti,
    });
  }

  /**
   * Capture any inputs value, save it in state
   */
  captureInput = e => {
    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        changeExists: e.target.name === 'autoFill' ? false : true,
        successMessage: null,
        errors,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        changeExists: e.target.name === 'autoFill' ? false : true,
        successMessage: null,
      });
    }

    if (
      e.target.name === 'autoFill' &&
      this.state.notificationData[e.target.value]
    ) {
      this.autoFill(this.state.notificationData[e.target.value].label);
    }
  };

  async saveAutofillChanges() {
    const autoFillName = this.state.notificationData[this.state.autoFill].label;
    const response = axios.get('../updateautofill', {
      params: {
        pAutoFillName: autoFillName,
        pUserID: LoginState.userId,
        pLienPosition: this.state.lienPosition,
        pLoanPurpose: this.state.loanPurpose,
        pPropertyType: this.state.propertyType,
        pOccupancyType: this.state.occupancyType,
        pDocumentType: this.state.documentType,
        pCreditScore: this.state.creditScore,
        pState: this.state.state,
        pCounty: this.state.county,
        pPropertyValue: numbersNoFormat(this.state.propertyValue),
        pPurchasePrice: numbersNoFormat(this.state.purchasePrice),
        pFirstLoan: numbersNoFormat(this.state.firstLoan),
        pSecondLoan: numbersNoFormat(this.state.secondLoan),
        pLtv: this.state.ltv,
        pCltv: this.state.cltv,
        pLoanProduct: this.state.loanProduct,
        pImpound: this.state.impound,
        pPmi: this.state.pmi,
        pLockPeriod: this.state.lockPeriod,
        pCompPlan: this.state.compPlan,
        pDti: this.state.dti,
      },
    });

    const resultMessage = (await response).data.toString();
    if (resultMessage !== 'Autofill Updated') {
      this.setState({
        successMessage: 'Error Updating: ' + autoFillName,
        autoFill: null,
      });
    } else {
      this.setState({
        successMessage: 'Successfully Updated: ' + autoFillName,
        changeExists: false,
      });
    }
  }

  async deleteAutofillOption() {
    const autoFillName = this.state.notificationData[this.state.autoFill].label;
    const response = axios.get('../deleteautofilldata', {
      params: {
        pUserID: LoginState.userId,
        pAutoFillName: autoFillName,
      },
    });

    // the response.data will contain the values returned
    const resultMessage = (await response).data.toString();
    if (resultMessage !== 'Autofill Option Deleted') {
      this.setState({
        showConfirmModal: false,
        successMessage: 'Error Deleting: ' + autoFillName,
        autoFill: null,
      });
    } else {
      // to reset autoFill list with removed index;
      this.getNotifications();

      this.setState({
        showConfirmModal: false,
        successMessage: 'Deleted Option: ' + autoFillName,
        autoFill: null,
      });
    }
  }

  ShowConfirmModal() {
    if (!this.state.autoFill) return;
    return (
      <div>
        Once the AutoFill Option Is Deleted, It Can No Longer Be Retreived
      </div>
    );
  }

  async validatePasswords() {
    // keep track of any existing issues
    let errors = {};
    let errorMsg = '';

    // validate required fields
    if (this.state.newPass.length < 8 || this.state.newPass.length > 25) {
      errors.newPass = 'Password Length';
      errorMsg = 'Password Must Be 8-25 Charaters Long';
    }

    if (
      this.state.confirmPass.length < 8 ||
      this.state.confirmPass.length > 25
    ) {
      errors.confirmPass = 'Password Length';
      errorMsg = 'Password Must Be 8-25 Charaters Long';
    }

    if (this.state.newPass !== this.state.confirmPass) {
      errors.newPass = 'Does Not Match';
      errors.confirmPass = 'Does Not Match';
      errorMsg = 'New/Confirm Passwords Do Not Match';
    }

    this.fields.forEach(field => {
      if (!this.state[field]) {
        errors[field] = 'Required';
        errorMsg = 'All Fields Must Be Filled';
      }
    });

    if (Object.values(errors).length === 0) {
      const response = axios.get('../changepassword', {
        params: {
          userID: LoginState.userId,
          oldPW: Base64.encode(this.state.currentPass),
          newPW: Base64.encode(this.state.newPass),
        },
      });

      // the response.data will contain the values returned
      var resultData = (await response).data;
      if (resultData.toString() !== 'Password Updated') {
        errorMsg = 'Current Password Does Not Match Records';
      } else {
        // clear fields and show toast message
        this.setState({
          successMessage: 'Password Successfully Updated!',
          currentPass: '',
          newPass: '',
          confirmPass: '',
        });
      }
    }
    this.setState({ errors, errorMessage: errorMsg });
  }

  renderContent = () => {
    const { activeTab, retrievedTab } = this.state;

    if (activeTab !== retrievedTab) {
      this.getNotifications();
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    }
    if (activeTab === 'User Info') return this.ShowUserInfo();
    if (activeTab === 'Change Password') return this.ShowChangePassword();
    if (activeTab === 'Manage Autofill') return this.ShowManageAutofill();
    if (activeTab === 'Update Matrix Files') return this.ShowUpdateMatrixFiles();
    if (activeTab === 'Update Form Files') return this.ShowUpdateFormFiles();
    if (activeTab === 'Update Ratesheet Template') return this.ShowUpdateRatesheetTemplate();
  };

  ShowUserInfo() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <>No notification data</>;
    }

    let userData = {};
    for (var i = 0; i < this.state.notificationData.length; i++) {
      userData = this.state.notificationData[i];
      if (userData.userID === LoginState.userId) {
        break;
      }
    }

    return (
      <div className="row">
        <div className="col-6">
          <p align="right">User Name:</p>
        </div>
        <div className="col-6">
          <p>{userData.userName || '---'}</p>
        </div>
        <div className="col-6">
          <p align="right">First Name:</p>
        </div>
        <div className="col-6">
          <p capital>
            {userData.firstName ? userData.firstName.toLowerCase() : '---'}
          </p>
        </div>
        <div className="col-6">
          <p align="right">Last Name:</p>
        </div>
        <div className="col-6">
          <p capital>
            {userData.lastName ? userData.lastName.toLowerCase() : '---'}
          </p>
        </div>
        <div className="col-6">
          <p align="right">Email:</p>
        </div>
        <div className="col-6">
          <p>{userData.email || '---'}</p>
        </div>
      </div>
    );
  }

  ShowChangePassword() {
    return (
      <div className="row">
        <div className="col-6 offset-sm-3">
          <label>Current Password:</label>
          <input
            className="form-control"
            error={this.state.errors && this.state.errors.currentPass}
            id="currentPass"
            name="currentPass"
            label="Current Password"
            type="password"
            value={this.state.currentPass}
            onChange={this.captureInput}
            tabIndex={1}
          />
        </div>
        <div className="col-6 offset-sm-3">
          <label>New Password:</label>
          <input
            className="form-control"
            error={this.state.errors && this.state.errors.newPass}
            id="newPass"
            name="newPass"
            label="New Password"
            type="password"
            value={this.state.newPass}
            onChange={this.captureInput}
            tabIndex={2}
          />
        </div>
        <div className="col-6 offset-sm-3">
          <label>Confirm Password:</label>
          <input
            className="form-control"
            error={this.state.errors && this.state.errors.confirmPass}
            id="confirmPass"
            name="confirmPass"
            label="Confirm Password"
            type="password"
            value={this.state.confirmPass}
            onChange={this.captureInput}
            tabIndex={2}
          />
        </div>
        <div className="col-6 offset-sm-3">
          <br />
          {this.state.errorMessage}
          <br />
          <br />
        </div>
        <div className="col-6 offset-sm-3">
          <div>
            <button
              className="btn btn-primary"
              id="changePwBtn"
              onClick={() => this.validatePasswords()}
            >
              Change Password
            </button>
          </div>
        </div>
        {this.state.successMessage && (
          <Toast
            type="success"
            width="300px"
            height="50px"
            title={this.state.successMessage}
            onClose={() => this.setState({ successMessage: null })}
          />
        )}
      </div>
    );
  }

  ShowManageAutofill() {
    const { errors, documentType, foreignNational, firstLoan } = this.state;
    const isRefinance = this.state.loanPurpose && this.state.loanPurpose > 1;
    const loanProductOptions =
      documentType && documentType.toString() === '1'
        ? PRICING_OPTIONS.loanProduct
        : PRICING_OPTIONS.loanProductPortfolio;
    const lockPeriodOptions =
      documentType && documentType.toString() === '1'
        ? PRICING_OPTIONS.lockPeriod
        : PRICING_OPTIONS.lockPeriod.slice(1);
    const monthOfReserveOptions =
      firstLoan && firstLoan > 1000000
        ? PRICING_OPTIONS.monthsReserve.slice(2)
        : PRICING_OPTIONS.monthsReserve;

    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return (
        <div>
          <p>
            Autofill Data Not Found For User Profile (Create in Pricing Engine)
          </p>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-12 col-md-4">
          <select
            className="form-select"
            id="autoFill"
            name="autoFill"
            label="Autofill Options"
            value={this.state.autoFill}
            onChange={this.captureInput}
          >
            <option key="default" value="0">
              - Select AutoFill Option -
            </option>
            {this.state.notificationData.map(el => (
              <option key={el.value} value={el.value}>
                {el.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-8">
          <div>
            {this.state.autoFill && (
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ showConfirmModal: true })}
              >
                Delete Option
              </button>
            )}
            {this.state.changeExists && (
              <button
                className="btn btn-primary ms-2"
                onClick={() => this.saveAutofillChanges()}
              >
                Save Changes
              </button>
            )}
          </div>
        </div>
        {this.state.autoFill && (
          <div className="row">
            <hr style={{ marginTop: '35px' }} />
            <div className="col-12 col-md-4">
              <h5
                className="border-bottom-2 border-gray text-capitalize my-4"
                style={{ color: 'hsl(358,79%,47%)' }}
              >
                Loan Details
              </h5>
              <div className="row p-0">
                <div className="col-12 col-lg-6">
                  <div className="mb-2">
                    {this.state.lienPosition === 2 ? (
                      <Placeholder />
                    ) : (
                      <CurrencyInput
                        label="First Loan Amount"
                        error={errors?.firstLoan}
                        id="firstLoan"
                        name="firstLoan"
                        value={this.state.firstLoan || 0}
                        onChange={this.captureInput}
                        tabIndex={1}
                      />
                    )}
                  </div>
                  <div className="mb-2">
                    <label htmlFor="ltv" className="form-label">
                      LTV
                    </label>
                    <input
                      className="form-control"
                      id="ltv"
                      name="ltv"
                      placeholder="LTV %"
                      error={errors?.ltv}
                      type="number"
                      value={this.state.ltv}
                      onChange={this.captureInput}
                      tabIndex={3}
                    />
                    <div id="ltvHelp" className="form-text invalid-feedback">
                      {errors?.ltv}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="lienPosition" className="form-label">
                      Lien Position
                    </label>
                    <select
                      className="form-select"
                      id="lienPosition"
                      name="lienPosition"
                      label="Lien Position"
                      value={this.state.lienPosition || 0}
                      onChange={this.captureInput}
                      tabIndex={5}
                      error={errors?.lienPosition}
                    >
                      {PRICING_OPTIONS.lienPosition.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="lienPositionHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.lienPosition}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="dti" className="form-label">
                      Occupancy Type
                    </label>
                    <select
                      className="form-select"
                      label="Occupancy Type"
                      error={errors?.occupancyType}
                      id="occupancyType"
                      name="occupancyType"
                      value={this.state.occupancyType || 0}
                      onChange={this.captureInput}
                      tabIndex={7}
                    >
                      {PRICING_OPTIONS.occupancyType.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="occupancyTypeHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.occupancyType}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="loanProduct" className="form-label">
                      Loan Product
                    </label>
                    <select
                      className="form-select"
                      label="Loan Product"
                      error={errors?.loanProduct}
                      id="loanProduct"
                      name="loanProduct"
                      value={this.state.loanProduct || 0}
                      onChange={this.captureInput}
                      tabIndex={9}
                    >
                      {loanProductOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="loanProductHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.loanProduct}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="impuond" className="form-label">
                      Impound
                    </label>
                    <select
                      className="form-select"
                      label="Escrow Account"
                      error={errors && errors.impound}
                      id="impound"
                      name="impound"
                      value={this.state.impound || 0}
                      onChange={this.captureInput}
                      tabIndex={10}
                    >
                      {PRICING_OPTIONS.impound.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="impoundHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.impound}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="compPlan" className="form-label">
                      Comp. Plan
                    </label>
                    <select
                      className="form-select"
                      label="Comp. Plan"
                      error={errors && errors.compPlan}
                      id="compPlan"
                      name="compPlan"
                      value={this.state.compPlan || 0}
                      onChange={this.captureInput}
                      tabIndex={12}
                    >
                      {PRICING_OPTIONS.compPlan.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="compPlanHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.compPlan}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  {this.state.lienPosition > 1 ? (
                    <CurrencyInput
                      error={errors && errors.secondLoan}
                      id="secondLoan"
                      label="Second Loan Amount"
                      name="secondLoan"
                      value={this.state.secondLoan || 0}
                      onChange={this.captureInput}
                      tabIndex={2}
                    />
                  ) : (
                    <Placeholder />
                  )}
                  <div className="mb-2">
                    <label htmlFor="cltv" className="form-label">
                      CLTV
                    </label>
                    <input
                      className="form-control"
                      placeholder="CLTV %"
                      error={errors?.cltv}
                      id="cltv"
                      name="cltv"
                      type="number"
                      value={this.state.cltv}
                      onChange={this.captureInput}
                      tabIndex={4}
                    />
                    <div id="cltvHelp" className="form-text invalid-feedback">
                      {errors?.cltv}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="loanPurpose" className="form-label">
                      Loan Purpose
                    </label>
                    <select
                      className="form-select"
                      label="Loan Purpose"
                      error={errors && errors.loanPurpose}
                      id="loanPurpose"
                      name="loanPurpose"
                      value={this.state.loanPurpose || 0}
                      onChange={this.captureInput}
                      tabIndex={6}
                    >
                      {PRICING_OPTIONS.loanPurpose.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="loanPurposeHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.loanPurpose}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="creditScore" className="form-label">
                      Documentation Type
                    </label>
                    <select
                      className="form-select"
                      label="Document Type"
                      error={errors && errors.documentType}
                      id="documentType"
                      name="documentType"
                      value={documentType || 0}
                      onChange={this.captureInput}
                      tabIndex={8}
                    >
                      {PRICING_OPTIONS.documentType.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="documentTypeHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.documentType}
                    </div>
                  </div>
                  <Placeholder />
                  {/* PMI Only for Full Doc */}
                  {documentType && documentType.toString() === '1' ? (
                    <div className="mb-2">
                      <label htmlFor="pmi" className="form-label">
                        PMI
                      </label>
                      <select
                        className="form-select"
                        label="PMI"
                        error={errors && errors.pmi}
                        id="pmi"
                        name="pmi"
                        value={this.state.pmi || 0}
                        onChange={this.captureInput}
                        tabIndex={11}
                      >
                        {PRICING_OPTIONS.pmi.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div id="pmiHelp" className="form-text invalid-feedback">
                        {errors?.pmi}
                      </div>
                    </div>
                  ) : (
                    <Placeholder />
                  )}
                  <div className="mb-2">
                    <label htmlFor="lockPeriod" className="form-label">
                      Lock Period
                    </label>
                    <select
                      className="form-select"
                      label="Lock Period"
                      error={errors && errors.lockPeriod}
                      id="lockPeriod"
                      name="lockPeriod"
                      value={this.state.lockPeriod || 0}
                      onChange={this.captureInput}
                      tabIndex={13}
                    >
                      {lockPeriodOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="lockPeriodHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.lockPeriod}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                  <h5
                    className="border-bottom-2 border-gray text-capitalize my-4"
                    style={{ color: 'hsl(358,79%,47%)' }}
                  >
                    Property Details
                  </h5>
                  <div className="mb-2">
                    <CurrencyInput
                      error={errors?.propertyValue}
                      id="propertyValue"
                      name="propertyValue"
                      label="Property Value"
                      value={this.state.propertyValue || 0}
                      onChange={this.captureInput}
                      tabIndex={14}
                    />
                  </div>
                  <div className="mb-2">
                    <CurrencyInput
                      error={errors?.purchasePrice}
                      name="purchasePrice"
                      label={isRefinance ? 'Estimated Value' : 'Purchase Price'}
                      value={this.state.purchasePrice || 0}
                      onChange={this.captureInput}
                      tabIndex={15}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="propertyType" className="form-label">
                      Property Type
                    </label>
                    <select
                      className="form-select"
                      error={errors?.propertyType}
                      label="Property Type"
                      id="propertyType"
                      name="propertyType"
                      value={this.state.propertyType || 0}
                      onChange={this.captureInput}
                      tabIndex={16}
                    >
                      {PRICING_OPTIONS.propertyType.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      id="propertyTypeHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.propertyType}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="county" className="form-label">
                      County
                    </label>
                    <select
                      className="form-select"
                      error={errors?.county}
                      label="County"
                      id="county"
                      name="county"
                      value={this.state.county || 0}
                      onChange={this.captureInput}
                      tabIndex={17}
                    >
                      {PRICING_OPTIONS.county.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div id="countyHelp" className="form-text invalid-feedback">
                      {errors?.county}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select
                      className="form-select"
                      error={errors?.state}
                      label="State"
                      id="state"
                      name="state"
                      value={this.state.state || 0}
                      onChange={this.captureInput}
                      tabIndex={18}
                    >
                      {PRICING_OPTIONS.states.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div id="stateHelp" className="form-text invalid-feedback">
                      {errors?.state}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 offset-lg-3">
                  <h5
                    className="border-bottom-2 border-gray text-capitalize my-4"
                    style={{ color: 'hsl(358,79%,47%)' }}
                  >
                    Borrower Details
                  </h5>
                  {documentType && documentType.toString() === '2' && (
                    <div className="row p-0">
                      <div className="col-12 col-md-6 mb-2">
                        <label htmlFor="foreignNational" className="form-label">
                          Foreign National
                        </label>
                        <select
                          className="form-select"
                          label="Foreign National"
                          error={errors && errors.foreignNational}
                          id="foreignNational"
                          name="foreignNational"
                          value={foreignNational || 0}
                          onChange={this.captureInput}
                          tabIndex={19}
                        >
                          {PRICING_OPTIONS.foreignNational.map(
                            (option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            )
                          )}
                        </select>
                        <div
                          id="foreignNationalHelp"
                          className="form-text invalid-feedback"
                        >
                          {errors?.foreignNational}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row p-0">
                    {/* FICO Not Applicable for Foreign Nationals */}
                    {(documentType && documentType.toString() === '1') ||
                    (foreignNational && foreignNational.toString() === '2') ? (
                      <div className="col-12 col-lg-6 mb-2">
                        <label htmlFor="creditScore" className="form-label">
                          Credit Score
                        </label>
                        <input
                          className="form-control"
                          placeholder="FICO"
                          error={errors?.creditScore}
                          id="creditScore"
                          name="creditScore"
                          type="number"
                          value={this.state.creditScore}
                          onChange={this.captureInput}
                          tabIndex={20}
                        />
                        <div
                          id="creditScoreHelp"
                          className="form-text invalid-feedback"
                        >
                          {errors?.creditScore}
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 col-lg-6 mb-2">
                        <label htmlFor="fico" className="form-label">
                          FICO
                        </label>
                        <input
                          id="fico"
                          className="form-control"
                          placeholder="FICO"
                          value="Not Applicable"
                          disabled={true}
                        />
                        <div
                          id="helpFico"
                          className="form-text invalid-feedback"
                        >
                          {errors?.fico}
                        </div>
                      </div>
                    )}
                    <div className="col-12 col-lg-6 mb-2">
                      <label htmlFor="dti" className="form-label">
                        DTI
                      </label>
                      <input
                        className="form-control"
                        placeholder="DTI"
                        error={errors?.dti}
                        id="dti"
                        name="dti"
                        value={this.state.dti}
                        onChange={this.captureInput}
                        tabIndex={21}
                      />
                      <div id="helpDti" className="form-text invalid-feedback">
                        {errors?.dti}
                      </div>
                    </div>
                  </div>
                  {documentType && documentType.toString() === '2' && (
                    <div className="row p-0">
                      <div className="col-6 mb-2">
                        <label
                          htmlFor="incomeVerification"
                          className="form-label"
                        >
                          Income Verification
                        </label>
                        <select
                          className="form-select"
                          label="Income Verification"
                          error={errors?.incomeVerification}
                          id="incomeVerification"
                          name="incomeVerification"
                          value={this.state.incomeVerification || 0}
                          onChange={this.captureInput}
                          tabIndex={22}
                        >
                          {PRICING_OPTIONS.incomeVerification.map(
                            (option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            )
                          )}
                        </select>
                        <div
                          id="incomeVerificationHelp"
                          className="form-text invalid-feedback"
                        >
                          {errors?.incomeVerification}
                        </div>
                      </div>
                      <div className="col-6 mb-2">
                        <label htmlFor="monthsReserve" className="form-label">
                          Months of Reserve
                        </label>
                        <select
                          className="form-select"
                          label="Months of Reserve"
                          error={errors?.monthsReserve}
                          id="monthsReserve"
                          name="monthsReserve"
                          value={this.state.monthsReserve || 0}
                          onChange={this.captureInput}
                          tabIndex={23}
                        >
                          {monthOfReserveOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div
                          id="monthsReserveHelp"
                          className="form-text invalid-feedback"
                        >
                          {errors?.monthsReserve}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Investment and Portfolio */}
                  {documentType &&
                    documentType.toString() === '2' &&
                    this.state.occupancyType === '3' && (
                      <div className="row p-0">
                        <div className="col-6 mb-2">
                          <label htmlFor="prepayPenalty" className="form-label">
                            Prepay Penalty
                          </label>
                          <select
                            className="form-select"
                            label="Prepay Penalty"
                            error={errors?.prepayPenalty}
                            id="prepayPenalty"
                            name="prepayPenalty"
                            value={this.state.prepayPenalty || 1}
                            onChange={this.captureInput}
                            tabIndex={24}
                          >
                            {PRICING_OPTIONS.prepayPenalty.map(
                              (option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              )
                            )}
                          </select>
                          <div
                            id="prepayPenaltyHelp"
                            className="form-text invalid-feedback"
                          >
                            {errors?.prepayPenalty}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          title="Delete AutoFill Data?"
          show={this.state.showConfirmModal}
          onAccept={() => this.deleteAutofillOption()}
          onDecline={() => this.setState({ showConfirmModal: false })}
          buttonMessage="Delete"
        >
          {this.ShowConfirmModal()}
        </Modal>
      </div>
    );
  }

  ShowUpdateMatrixFiles() {
    return (
      <div className="row">
        <h5>Update Matrix File</h5>
        <div className="mb-3">
          <select
            id="matrixForm"
            name="matrixForm"
            label="Matrix Form To Replace"
            className="form-select"
            value={this.state.matrixForm}
            onChange={this.captureInput}
          >
            <option value="0">Select..</option>
            {PRICING_OPTIONS.matrixForms.map(el => (
              <option key={el.value} value={el.value}>
                {el.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 text-center">
          <Dropzone
            maxFiles="1"
            maxSizeBytes="25000000" // 25 Mb
            accept=".pdf"
            onChangeStatus={this.handleChangeStatus.bind(this)}
            LayoutComponent={Layout}
            inputContent={this.InputContent.bind(this)}
            styles={{
              dropzone: {
                overflow: 'hidden',
                border: '2px dashed lightgray',
                height: '200px',
                borderRadius: '0.25rem',
              },
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (_, extra) => (extra.reject ? { color: 'red' } : {}),
            }}
          />
          <br />

          {this.state.fileArr.length > 0 && (
            <div className="mb-3">
              <h5>{this.state.fileName}</h5>
              <span>{todaysDate()}</span>
              <span style={{ marginLeft: '5px' }}>
                ({this.state.fileSize} kB)
              </span>
            </div>
          )}

          {this.state.fileArr.length > 0 && (
            <div className="text-center">
              <button
                className="btn btn-secondary"
                style={{ marginRight: '10px' }}
                onClick={() => this.setState({ fileArr: [] })}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                id="findFileBtn"
                disabled={this.state.isUploading || this.state.matrixForm < 0}
                onClick={() => this.submitFileToServer('matrix')}
              >
                Submit File
              </button>
            </div>
          )}

          {this.state.successMessage && (
            <Toast
              type="success"
              width="300px"
              height="50px"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: '' })}
            />
          )}
          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
        </div>
      </div>
    );
  }

  ShowUpdateFormFiles() {
    return (
      <div className="row">
        <h5>Update Forms</h5>
        <div className="mb-3">
          <select
            id="formFile"
            name="formFile"
            label="Form File To Replace"
            className="form-select"
            value={this.state.formFile}
            onChange={this.captureInput}
          >
            <option value="0">Select..</option>
            {PRICING_OPTIONS.formFiles.map(el => (
              <option key={el.value} value={el.value}>
                {el.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 text-center">
          <Dropzone
            maxFiles="1"
            maxSizeBytes="25000000" // 25 Mb
            accept=".pdf"
            onChangeStatus={this.handleChangeStatus.bind(this)}
            LayoutComponent={Layout}
            inputContent={this.InputContent.bind(this)}
            styles={{
              dropzone: {
                overflow: 'hidden',
                border: '2px dashed lightgray',
                height: '200px',
                borderRadius: '0.25rem',
              },
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (_, extra) => (extra.reject ? { color: 'red' } : {}),
            }}
          />
          <br />

          {this.state.fileArr.length > 0 && (
            <div className="mb-3">
              <h5>{this.state.fileName}</h5>
              <span>{todaysDate()}</span>
              <span style={{ marginLeft: '5px' }}>
                ({this.state.fileSize} kB)
              </span>
            </div>
          )}

          {this.state.fileArr.length > 0 && (
            <div className="text-center">
              <button
                className="btn btn-secondary"
                style={{ marginRight: '10px' }}
                onClick={() => this.setState({ fileArr: [] })}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                id="findFileBtn"
                disabled={this.state.isUploading || this.state.matrixForm < 0}
                onClick={() => this.submitFileToServer('form')}
              >
                Submit File
              </button>
            </div>
          )}

          {this.state.successMessage && (
            <Toast
              type="success"
              width="300px"
              height="50px"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: '' })}
            />
          )}
          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
        </div>
      </div>
    );
  }

  ShowUpdateRatesheetTemplate() {
    return (
      <div className="row">
        <h5>Update Ratesheet Template</h5>
        <div className="col-12 text-center">
          <Dropzone
            maxFiles="1"
            maxSizeBytes="25000000" // 25 Mb
            accept=".csv,.xlsm,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChangeStatus={this.handleChangeStatus.bind(this)}
            LayoutComponent={Layout}
            inputContent={this.InputContent.bind(this)}
            styles={{
              dropzone: {
                overflow: 'hidden',
                border: '2px dashed lightgray',
                height: '200px',
                borderRadius: '0.25rem',
              },
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (_, extra) => (extra.reject ? { color: 'red' } : {}),
            }}
          />
          <br />

          {this.state.fileArr.length > 0 && (
            <div className="mb-3">
              <h5>{this.state.fileName}</h5>
              <span>{todaysDate()}</span>
              <span style={{ marginLeft: '5px' }}>
                ({this.state.fileSize} kB)
              </span>
            </div>
          )}

          {this.state.fileArr.length > 0 && (
            <div className="text-center">
              <button
                className="btn btn-secondary"
                style={{ marginRight: '10px' }}
                onClick={() => this.setState({ fileArr: [] })}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                id="findFileBtn"
                disabled={this.state.isUploading || this.state.matrixForm < 0}
                onClick={() => this.submitFileToServer('rate')}
              >
                Submit File
              </button>
            </div>
          )}

          {this.state.successMessage && (
            <Toast
              type="success"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: '' })}
            />
          )}
          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
        </div>
      </div>
    );
  }

  async submitFileToServer(fileType) {
    // skip if no files uploaded
    if (this.state.fileArr.length === 0) return;

    // check to see that combined file size is not over limit
    var totalSize = 0;
    for (var i = 0; i < this.state.fileArr.length; i++) {
      totalSize += this.state.fileArr[i].size;
    }
    if (totalSize >= MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'File Size Exceeds 25Mb!' });
      return;
    }

    // check to see that we selected a form type
    switch(fileType) {
      case 'form':
        if(!this.state.formFile) {
          this.setState({ errorMessage: 'Form Type Not Selected!' });
          return;
        }
        break;
      case 'matrix':
        if(!this.state.matrixForm) {
          this.setState({ errorMessage: 'Form Type Not Selected!' });
          return;
        }
        break;
      default:
        break;
    }

    this.setState({ isUploading: true });

    // first combine all the pdfs into one.
    var combinedFiles = null;
    for (var j = 0; j < this.state.fileArr.length; j++) {
      if (j === 0) combinedFiles = this.state.fileArr[j].fileUIntArr;
      else
        combinedFiles = this.combinePDFBuffers(
          combinedFiles,
          this.state.fileArr[j].fileUIntArr
        );
    }

    var dName;
    switch(fileType) {
      case 'form':
        dName = PRICING_OPTIONS.formFiles[parseInt(this.state.formFile) - 1].label;
        break;
      case 'matrix':
        dName = PRICING_OPTIONS.matrixForms[parseInt(this.state.matrixForm) - 1].label;
        break;
      case 'rate':
        dName = 'MTG-RateSheetTemplate.xlsx';
        break;
      default:
        break;
    }

    // then submit to server
    const params = {
      docName: dName,
      fileName: this.state.fileName,
      fileContent: combinedFiles,
      fileID: this.state.fileSize.toString(), // just repurposing this variable
    };

    var uploadPath;
    switch(fileType) {
      case 'form':
        uploadPath = '/UploadFormFile';
        break;
      case 'matrix':
        uploadPath = '/UploadMatrixFile';
        break;
      case 'rate':
        uploadPath = '/UploadRateTemplate';
        break;
      default:
        break;
    }

    fetch(uploadPath, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(data => {
        // error check
        if (!data.startsWith('Success')) {
          // show error message
          this.setState({
            isUploading: false,
            successMessage: '',
            errorMessage: data,
          });
        } else {
          // notify for successful upload
          this.setState({
            isUploading: false,
            fileArr: [],
            successMessage: 'File Successfully Uploaded!',
            errorMessage: ''
          });
        }
      });
  }

  combinePDFBuffers(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  InputContent() {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <div>
          <i
            className="bi bi-cloud-upload text-muted"
            style={{ fontSize: '4rem' }}
          ></i>
          <p className="mb-1 text-muted">
            Drag and Drop .PDF Files or Click Below to Upload
          </p>
          <small className="text-muted">
            (The System Will Only Accept Up to 25 Mb of Files at a Time)
          </small>
        </div>
      );
    }
  }

  handleChangeStatus({ file, remove }, status) {
    this.setState({
      fileName: file.name,
      fileSize: parseInt(file.size / 1000),
    });
    if (file && file.size > MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'File Size Cannot Exceed 25 Mb!' });
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        var file = [];
        file.push({
          fileUIntArr: uintarr,
          fileName: file.name,
          conditionID: this.state.conditionID,
          size: file.size,
        });
        this.setState({ fileArr: file });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 text-uppercase mb-2">settings</h1>
            <ul className="nav nav-tabs">
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <button
                  className={`nav-link ${
                    this.state.activeTab === 'User Info' && 'active'
                  }`}
                  onClick={() => this.props.history.push('/settings/userinfo')}
                >
                  User Info
                </button>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <button
                  className={`nav-link ${
                    this.state.activeTab === 'Change Password' && 'active'
                  }`}
                  onClick={() => this.props.history.push('/settings/password')}
                >
                  Change Password
                </button>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <button
                  className={`nav-link ${
                    this.state.activeTab === 'Manage Autofill' && 'active'
                  }`}
                  onClick={() => this.props.history.push('/settings/autofill')}
                >
                  Manage AutoFill
                </button>
              </li>
              {LoginState.userRole.includes('Admin') && (
                <li className="nav-item" style={{ cursor: 'pointer' }}>
                  <button
                    className={`nav-link ${
                      this.state.activeTab === 'Update Matrix Files' && 'active'
                    }`}
                    onClick={() => this.props.history.push('/settings/matrix')}
                  >
                    Update Matrix Files
                  </button>
                </li>
              )}
              {LoginState.userRole.includes('Admin') && (
                <li className="nav-item" style={{ cursor: 'pointer' }}>
                  <button
                    className={`nav-link ${
                      this.state.activeTab === 'Update Form Files' && 'active'
                    }`}
                    onClick={() => this.props.history.push('/settings/form')}
                  >
                    Update Form Files
                  </button>
                </li>
              )}
              {LoginState.userRole.includes('Admin') && (
                <li className="nav-item" style={{ cursor: 'pointer' }}>
                  <button
                    className={`nav-link ${
                      this.state.activeTab === 'Update Ratesheet Template' && 'active'
                    }`}
                    onClick={() => this.props.history.push('/settings/rate')}
                  >
                    Update Ratesheet Template
                  </button>
                </li>
              )}
            </ul>
            <div className="p-4 bg-white">{this.renderContent()}</div>
          </div>
        </div>
      </div>
    );
  }
}
