import React, { Component } from 'react';
import axios from 'axios';
import LoginState from './LoginState';

export default class AdminMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageID: '',
      lastMessage: '',
      lastMessageExpires: '',
      startTimeNow: true,
      invalidStartTime: false,
      startTimeCustom: '',
      endTimeUndetermined: true,
      invalidEndTime: false,
      endTimeCustom: '',
      successMessage: '',
      errorMessage: '',
      submitting: false,
    };

    // if not admin, redirect
    if (
      !LoginState.userRole ||
      !LoginState.userRole.toLowerCase().includes('admin')
    )
      this.props.history.push('/');

    this.getLatestMessage();
  }

  async getLatestMessage() {
    const response = axios.get('/getLatestMessage');
    var resultData = (await response).data;
    if (resultData) {
      this.setState({
        lastMessage: resultData.message,
        lastMessageExpires: resultData.expiration,
      });
    } else {
      this.setState({ lastMessage: '', lastMessageExpires: '' });
    }
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async endMessage() {
    this.setState({ submitting: true });
    const response = axios.get('/endMessage');
    var resultData = (await response).data;
    if (!resultData) {
      this.setState({ errorMessage: 'Error' });
    }
    this.setState({
      submitting: false,
      lastMessage: '',
      lastMessageExpires: '',
    });
  }

  async validateAndSubmit() {
    var datesValid = true;
    var now = new Date();
    var dateTimeStart = this.convertDateToUTC(
      new Date(this.state.startTimeCustom)
    );
    var dateTimeEnd = this.convertDateToUTC(new Date(this.state.endTimeCustom));

    if (
      !this.state.startTimeNow &&
      (isNaN(dateTimeStart.getTime()) || dateTimeStart <= now)
    ) {
      this.setState({ invalidStartTime: true });
      datesValid = false;
    } else {
      this.setState({ invalidStartTime: false });
    }

    if (
      !this.state.endTimeUndetermined &&
      (isNaN(dateTimeEnd.getTime()) ||
        dateTimeEnd <= (this.state.startTimeNow ? now : dateTimeStart))
    ) {
      this.setState({ invalidEndTime: true });
      datesValid = false;
    } else {
      this.setState({ invalidEndTime: false });
    }

    if (datesValid) {
      this.setState({ submitting: true });
      const response = axios.get('/submitGlobalMessage', {
        params: {
          pUserName: LoginState.userName,
          pMessage: this.state.displayMessage,
          pTimeStart: this.state.startTimeNow
            ? now
            : this.state.startTimeCustom,
          pTimeEnd: this.state.endTimeUndetermined
            ? ''
            : this.state.endTimeCustom,
        },
      });
      var resultData = (await response).data;
      if (resultData) {
        this.setState({
          messageID: resultData,
          lastMessage: this.state.displayMessage,
          lastMessageStart: this.state.startTimeNow
            ? now
            : this.state.startTimeCustom,
          lastMessageExpires: this.state.endTimeUndetermined
            ? 'Undetermined'
            : this.state.endTimeCustom,

          minuteMessage:
            "(Message Will Be Displayed On Everyone's Screen Within 1 Minute)",
          displayMessage: '',
          startTimeNow: true,
          invalidStartTime: false,
          startTimeCustom: '',
          endTimeUndetermined: true,
          invalidEndTime: false,
          endTimeCustom: '',
        });

        setInterval(() => {
          this.setState({ minuteMessage: '' });
        }, 60000);
      }
      this.setState({ submitting: false });
    }
  }

  convertDateToUTC(date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 text-uppercase">global message</h1>
          </div>
          <div className="col-12 col-md-6 m-md-2">
            <div className="row">
              <div className="mb-2 col-12 col-md-12 col-lg-12">
                <label htmlFor="displayMessage">Display Message</label>
                <input
                  className="form-control"
                  id="displayMessage"
                  name="displayMessage"
                  value={this.state.displayMessage}
                  onChange={this.captureInput}
                />
              </div>
            </div>
            <div className="row">
              <label htmlFor="startTime">Start Message</label>
              <div
                className="mb-2 col-12 col-md-6"
                style={{ marginLeft: '20px' }}
              >
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="now"
                    name="startTime"
                    checked={this.state.startTimeNow}
                    onChange={() => void 0}
                    onClick={() => {
                      this.setState({ startTimeNow: true });
                    }}
                  />
                  <label htmlFor="now" className="form-check-label">
                    Now
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="later"
                    name="startTime"
                    checked={!this.state.startTimeNow}
                    onChange={() => void 0}
                    onClick={() => {
                      this.setState({ startTimeNow: false });
                    }}
                  />
                  <label htmlFor="later" className="form-check-label">
                    Later
                  </label>
                </div>
                <div>
                  <input
                    value={this.state.startTimeCustom}
                    type="datetime-local"
                    onChange={this.captureInput}
                    name="startTimeCustom"
                    style={{ marginLeft: '25px' }}
                  />
                  <div
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginLeft: '25px',
                    }}
                  >
                    {this.state.invalidStartTime &&
                      "Invalid Starting Time (Can't Be A Past Date/Time)"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <label htmlFor="startTime">End Message</label>
              <div
                className="mb-2 col-12 col-md-6"
                style={{ marginLeft: '20px' }}
              >
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="undetermined"
                    name="endTime"
                    checked={this.state.endTimeUndetermined}
                    onChange={() => void 0}
                    onClick={() => {
                      this.setState({ endTimeUndetermined: true });
                    }}
                  />
                  <label htmlFor="undetermined" className="form-check-label">
                    Undetermined
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="specified"
                    name="endTime"
                    checked={!this.state.endTimeUndetermined}
                    onChange={() => void 0}
                    onClick={() => {
                      this.setState({ endTimeUndetermined: false });
                    }}
                  />
                  <label htmlFor="specified" className="form-check-label">
                    Specified Time
                  </label>
                </div>
                <div>
                  <input
                    value={this.state.endTimeCustom}
                    type="datetime-local"
                    onChange={this.captureInput}
                    name="endTimeCustom"
                    style={{ marginLeft: '25px' }}
                  />
                  <div
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginLeft: '25px',
                    }}
                  >
                    {this.state.invalidEndTime &&
                      'Invalid End Time (Needs To End After Start Time)'}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-2">
              <button
                className="btn btn-primary"
                disabled={!this.state.displayMessage || this.state.submitting}
                onClick={() => {
                  this.validateAndSubmit();
                }}
              >
                {this.state.submitting ? 'Submitting...' : 'Submit Message'}
              </button>
              <span
                style={{ color: 'red', fontSize: '13px', marginLeft: '15px' }}
              >
                {this.state.minuteMessage}
              </span>
            </div>
          </div>

          {this.state.lastMessage && (
            <div style={{ marginTop: '20px' }}>
              <hr />
              <label>Currently Displayed Message</label>
              <div
                style={{ color: 'blue', fontSize: '13px', marginLeft: '25px' }}
              >
                {this.state.lastMessage}
              </div>
              <label>Message Expires</label>
              <div
                style={{ color: 'red', fontSize: '13px', marginLeft: '25px' }}
              >
                {this.state.lastMessageExpires}
              </div>

              <button
                className="btn btn-primary"
                style={{ marginTop: '20px' }}
                disabled={this.state.submitting}
                onClick={() => {
                  this.endMessage();
                }}
              >
                {this.state.submitting ? 'Processing...' : 'End Message'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
