import React, { Component } from 'react';
import axios from 'axios';
import LoginState from './LoginState';
import Toast from '../components/Toast';
export default class ImportantDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importantDates: '',
      sendingRequest: false,
      searchLog: [],
      changeHistory: [],
      errors: {},
    };
  }

  componentDidMount() {
    this.PageLoad(this.props.match.params.id);
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({ importantDates: '' });
    this.GetSearchLog(fileID);
    this.GetDates(fileID);
  }

  captureInput = e => {
    let newVal = e.target.value;
    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.id];
      this.setState({
        [e.target.id]: newVal,
        errors,
      });
    } else {
      this.setState({
        [e.target.id]: newVal,
      });
    }
  };

  async GetDates(fileID) {
    var response = axios.get('/getimportantdates', {
      params: {
        pFileID: fileID,
      },
    });
    var resultData = (await response).data;
    if (resultData) {
      this.setState({
        importantDates: resultData,
        changeHistory: resultData.changes,
      });
    }
  }

  async requestChange(fieldID) {
    const errors = {};
    if (!this.state[fieldID]) {
      errors[fieldID + 'Error'] = 'Date Not Selected';
      this.setState({ errors });
      return;
    }

    this.setState({
      sendingRequest: true,
      successMessage: 'Sending Request... Please Wait!',
    });

    var fieldName;
    var oldValue;
    switch (fieldID) {
      case 'signingDate':
        fieldName = 'Anticipated Signing';
        oldValue = this.state.importantDates.signingDate;
        break;
      case 'fundingDate':
        fieldName = 'Scheduled Funding';
        oldValue = this.state.importantDates.fundingDate;
        break;
      default:
        fieldName = '';
        oldValue = '';
        break;
    }

    var response = axios.get('/requestdatechange', {
      params: {
        pFileID: this.props.match.params.id,
        pDateField: fieldName,
        pOldDate: oldValue,
        pRequestedDate: this.state[fieldID],
        pUserName: LoginState.userName,
      },
    });

    try {
      var resultData = (await response).data;
      // clear fields, show message, update history
      if (resultData) {
        this.setState({
          sendingRequest: false,
          successMessage: 'Date Change Request Successfully Sent!',
          signingDate: '',
          fundingDate: '',
        });
        this.GetDates(this.props.match.params.id);
      }
    } catch {
      this.setState({});
    }
  }

  ShowChangeHistory() {
    if (this.state.changeHistory.length === 0) {
      return <></>;
    }
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th key="RequestDate">Request Date</th>
              <th style={{ textAlign: 'center' }} key="RequestedBy">
                Requested By
              </th>
              <th style={{ textAlign: 'center' }} key="Field">
                Date To Change
              </th>
              <th style={{ textAlign: 'center' }} key="OldValue">
                Previous Date
              </th>
              <th style={{ textAlign: 'center' }} key="NewValue">
                Requested Date
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.changeHistory.map(change =>
              this.GetChangeRecord(
                change.requestDate,
                change.changeRequested,
                change.requestedBy
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }

  GetChangeRecord(requestDate, changeRequested, requestedBy) {
    var changes = changeRequested.split('$$$$$');

    return (
      <tr key={requestDate}>
        <td>{requestDate}</td>
        <td style={{ textAlign: 'center' }}>{requestedBy}</td>
        <td style={{ textAlign: 'center' }}>{changes[0]}</td>
        <td style={{ textAlign: 'center' }}>{changes[1]}</td>
        <td style={{ textAlign: 'center' }}>{changes[2]}</td>
      </tr>
    );
  }

  render() {
    const { errors } = this.state;
    const loanId = this.props.match.params.id;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex gap-1 align-items-center">
            <h1 className="h4 mb-0 text-uppercase">important dates</h1>
            <button
              className="btn btn btn-text dropdown-toggle"
              style={{ marginLeft: '5px' }}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-list"></i>
            </button>
            <ul className="dropdown-menu">
              <li>
                <p className="dropdown-item mb-0 disabled">Recent Files</p>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {this.state.searchLog.length > 0 ? (
                <>
                  {this.state.searchLog.map(file => (
                    <li key={file.encodedFileID}>
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          this.props.history.push(
                            '/pipeline/important-dates/' + file.encodedFileID
                          );
                          this.PageLoad(file.encodedFileID);
                        }}
                      >
                        {file.encodedFileID} ({file.borrower})
                      </span>
                    </li>
                  ))}
                </>
              ) : (
                <li>
                  <p className="dropdown-item mb-0 disabled">No Recent Files</p>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="bg-white p-4 rounded">
          <h2 className="h5 text-muted mb-3">Loan Number: {loanId}</h2>
          <div className="row">
            <div className="col-12 mb-3">
              <span className="fw-bold">Application Date</span>
              <p className="mb-0">
                {this.state.importantDates.applicationDate}
              </p>
            </div>
            <hr className="border-secondary" />
            <div className="col-12 col-md-6 col-xl-2 mb-3">
              <span className="fw-bold">Anticipated Signing Date</span>
              <p className="mb-0">{this.state.importantDates.signingDate}</p>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb-3">
              <label for="fundingDate" className="form-label fw-bold">
                Request Signing Date Change
              </label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="date"
                  onChange={this.captureInput}
                  id="signingDate"
                  value={this.state.signingDate}
                  error={errors && errors.signingDateError}
                />
                <button
                  className="btn btn-primary"
                  disabled={this.state.sendingRequest}
                  onClick={() => this.requestChange('signingDate')}
                >
                  Request Change
                </button>
              </div>
            </div>
            <div className="col-0 col-lg-6"></div>
            <hr className="border-secondary" />
            <div className="col-12 col-md-6 col-xl-2 mb-3">
              <span className="fw-bold">Scheduled Funding Date</span>
              <p className="mb-0">{this.state.importantDates.fundingDate}</p>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb-3">
              <label for="fundingDate" className="form-label fw-bold">
                Request Funding Date Change
              </label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="date"
                  onChange={this.captureInput}
                  id="fundingDate"
                  value={this.state.fundingDate}
                  error={errors && errors.fundingDateError}
                />
                <button
                  className="btn btn-primary"
                  disabled={this.state.sendingRequest}
                  onClick={() => this.requestChange('fundingDate')}
                >
                  Request Change
                </button>
              </div>
            </div>
            <hr className="border-secondary" />
            <div className="col-12">
              <span className="fw-bold">Lock Expiration Date</span>
              <p>{this.state.importantDates.lockExpDate}</p>
            </div>

            {/* show change history */}
            {this.ShowChangeHistory()}

            {this.state.successMessage && (
              <Toast
                type="success"
                title={this.state.successMessage}
                onClose={() => this.setState({ successMessage: '' })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
